import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import App from './App';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <App/>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, {SetStateAction, useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import Eye from "../icons/Eye";
import EyeSlash from "../icons/EyeSlash";

const PassInput = (props: {
    label: string,
    value: string,
    setValue: (value: SetStateAction<string>) => void,
    disabled: boolean,
    errorText?: string,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <TextField
            label={props.label}
            variant="standard"
            value={props.value}
            disabled={props.disabled}
            fullWidth
            type={showPassword ? "text" : "password"}
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => props.setValue(e.target.value)}
            error={props.errorText !== undefined && props.errorText.length !== 0}
            helperText={props.errorText}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                        >
                            {showPassword ? <EyeSlash /> : <Eye />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}

export default PassInput;
import moment from "moment";
import {t} from "i18next"

export const priceFormatter = (price: number) => price.toLocaleString("en") + "$"

export const dateFormatter = (ts: number|string) => moment(ts).locale("en").format("ll")

export const sleep = (ts = 5000) => {
    return new Promise((resolve) => setTimeout(resolve, ts));
}

export const isDev = process.env.REACT_APP_IS_DEV === "true";
export const baseURL = process.env.REACT_APP_API_HOST || "";
export const tgBot = process.env.REACT_APP_TG_BOT || "";
export const domain = process.env.REACT_APP_DOMAIN || "";

export const getSocialLinkNameByKey = (key: string) => {
    const valueMap = {
        facebook: "Facebook",
        instagram: "Instagram",
    }

    return key in valueMap ? valueMap[key as keyof typeof valueMap] : "";
}

export const percentFormatter = (num: number) => parseFloat((num * 100).toFixed(2))

export const getAdditionalSymptomTitleByCode = (code: string) => {
    const valueMap = {
        lethargy: t("AdditionalSymptoms.lethargy"),
        depression: t("AdditionalSymptoms.depression"),
        dizziness: t("AdditionalSymptoms.dizziness"),
        nausea: t("AdditionalSymptoms.nausea"),
        difficultyBreathing: t("AdditionalSymptoms.difficultyBreathing"),
        anxiety: t("AdditionalSymptoms.anxiety"),
    };

    return code in valueMap ? valueMap[code as keyof typeof valueMap] : '';
}

export const getCausesAndEffectTitleByCode = (code: string) => {
    const valueMap = {
        insomnia: t("CausesAndEffects.insomnia"),
        stress: t("CausesAndEffects.stress"),
        weather: t("CausesAndEffects.weather"),
        standing: t("CausesAndEffects.standing"),
        sitting: t("CausesAndEffects.sitting"),
        walking: t("CausesAndEffects.walking"),
        training: t("CausesAndEffects.training"),
        food: t("CausesAndEffects.food"),
        toilet: t("CausesAndEffects.toilet"),
    };

    return code in valueMap ? valueMap[code as keyof typeof valueMap] : '';
}

export const getHelpersTitleByCode = (code: string) => {
    const valueMap = {
        conversation: t("Helpers.conversation"),
        exerciseStress: t("Helpers.exerciseStress"),
        coldWarm: t("Helpers.coldWarm"),
        rest: t("Helpers.rest"),
        meditationYoga: t("Helpers.meditationYoga"),
        massage: t("Helpers.massage"),
        acupuncture: t("Helpers.acupuncture"),
        alcohol: t("Helpers.alcohol"),
        music: t("Helpers.music"),
    };

    return code in valueMap ? valueMap[code as keyof typeof valueMap] : '';
}

export const getNatureTitleByCode = (code: string) => {
    const valueMap = {
        shooting: t("Natures.shooting"),
        cutting: t("Natures.cutting"),
        aching: t("Natures.aching"),
        sharp: t("Natures.sharp"),
        throbbing: t("Natures.throbbing"),
        pressing: t("Natures.pressing"),
        dull: t("Natures.dull"),
        tingling: t("Natures.tingling"),
        burning: t("Natures.burning"),
    };

    return code in valueMap ? valueMap[code as keyof typeof valueMap] : '';
}

const states = {
  true: "true",
  indeterminate: "indeterminate",
  false: "false",
};

const setInStorage = (key: string, value: string): void => {
    localStorage.setItem(key, value)
};

const getFromStorage = (key: string): string|null => {
    return localStorage.getItem(key)
};

const hasInStorage = (key: string): boolean => {
    return getFromStorage(key) !== null
};

const removeFromStorage = (key: string): void => {
    hasInStorage(key) && localStorage.removeItem(key)
};

const getBooleanFromStorage = (key: string): boolean|null => {
    return hasInStorage(key) &&
    // @ts-ignore
    Object.values(states).includes(getFromStorage(key))
        ? getFromStorage(key) === states.true
        : null;
};

export {
    states,
    setInStorage,
    getFromStorage,
    hasInStorage,
    removeFromStorage,
    getBooleanFromStorage,
}
import React, {SetStateAction} from "react";
import {TextField} from "@mui/material";
import ReactPhoneInput from 'react-phone-input-material-ui';
import {makeStyles} from "@mui/styles";
import 'react-phone-input-material-ui/lib/style.css'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    field: {
        "& input": {
            padding: "12px 0 12px",
        },
    },
});

const PhoneInput = (props: {
    value: string,
    setValue: (value: SetStateAction<string>) => void,
    disabled: boolean,
    helperText?: string,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <ReactPhoneInput
            country="us"
            value={props.value}
            onChange={(value, data, event, formattedValue) => {
                props.setValue(formattedValue)
            }}
            inputClass={classes.field}
            component={TextField}
            label={t("PhoneInput.label")}
            disabled={props.disabled}
            inputProps={{
                type: 'tel',
                required: true,
                fullWidth: true,
                variant: 'standard',
            }}
        />
    )
}

export default PhoneInput;
import CPaper from "../surfaces/CPaper";
import theme from "../../theme";
import {Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography} from "@mui/material";
import PersonalDataProcessingDialog from "../dialogs/PersonalDataProcessingDialog";
import BoxFlex from "../layout/BoxFlex";
import {domain, isDev, priceFormatter} from "../../utils";
import React, {useEffect, useState} from "react";
import {useError} from "../../context/ErrorHandler";
import api from "../../api/api";
import {getFromStorage, removeFromStorage, setInStorage} from "../../localStorageUtils";
import Xmark from "../icons/Xmark";
import {Trans, useTranslation} from "react-i18next";

export default function BuyCourseBlock(props: {
    articleId: number,
    price: number,
}) {
    const { t } = useTranslation();
    const { setError } = useError();
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [disableBtn, setDisableBtn] = useState<boolean>(true)
    const [localError, setLocalError] = useState<string>('')
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleBuy = async () => {
        if (!isDev) {
            setLoading(true)
            await api.post('/order/article', {
                email: email,
                articleId: props.articleId,
                returnUrl: `${domain}/article/${props.articleId}`,
            })
                .then(res => {
                    if (res.status === 200) {
                        setInStorage('orderArticle' + res.data.orderId, String(props.articleId))
                        window.location.replace(res.data.formUrl)
                    } else {
                        setError(t("common.badRequestErr"))
                    }
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => setLoading(false))
        }
    }

    const handleCheckOrder = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const orderId = urlParams.get('orderId');

        if (orderId) {
            const storageKey = 'orderArticle' + orderId;
            const res = getFromStorage(storageKey);

            if (orderId && res === String(props.articleId)) {
                setLoading(true)
                await api.post(`/order/${orderId}`)
                    .then(res => {
                        if (
                            res.status === 200
                            && res.data.ok === true
                        ) {
                            setOpenDialog(true)
                        } else {
                            setError(t("common.badRequestErr"))
                        }
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => setLoading(false))
            }

            removeFromStorage(storageKey)
        }
    }

    const validateEmail = (email: string): boolean => {
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return re.test(email.trim());
    }

    useEffect(() => {
        if (email && !validateEmail(email)) {
            setLocalError(t("BuyCourseBlock.emailErr"))
        } else {
            setLocalError('')
        }
    }, [email])

    useEffect(() => {
        setDisableBtn(email.length === 0 || localError.length !== 0)
    }, [email, localError])

    useEffect(() => {
        handleCheckOrder()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <CPaper
                sx={{
                    background: theme.palette.gradient.main,
                    padding: "60px 97px 79px",
                    marginTop: "51px",

                    "@media (max-width: 899px)": {
                        marginTop: "32px",
                        padding: "32px 16px 40px"
                    }
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        textAlign: "center",
                        marginBottom: "48px",

                        "@media (max-width: 899px)": {
                            marginBottom: "10px",
                        }
                    }}
                >
                    <Trans i18nKey="BuyCourseBlock.title" />
                </Typography>

                <TextField
                    label="Email"
                    variant="standard"
                    type="email"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    error={localError.length !== 0}
                    helperText={localError}
                />

                <Typography
                    variant="subtitle2"
                    sx={{
                        margin: "48px 0 81px",

                        "@media (max-width: 899px)": {
                            margin: "15px 0 26px",
                        }
                    }}
                >
                    {t("common.personalDataSendMsg")} <PersonalDataProcessingDialog/>
                </Typography>

                <BoxFlex
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="h4"
                    >
                        {priceFormatter(props.price)}
                    </Typography>

                    <Button
                        size="large"
                        variant="contained"
                        onClick={handleBuy}
                        disabled={disableBtn || loading}
                    >
                        {t("common.send")}
                    </Button>
                </BoxFlex>
            </CPaper>
            <Dialog
                onClose={() => setOpenDialog(false)}
                open={openDialog}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("BuyCourseBlock.dialogTitle")}
                    </Typography>
                    <br/>
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("BuyCourseBlock.dialogSubtitle")}
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    )
}
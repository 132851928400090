import React, {useState} from "react";
import {Moment} from "moment";
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Calendar from "../icons/Calendar";
import ArrowSmallLeft from "../icons/ArrowSmallLeft";
import ArrowSmallRight from "../icons/ArrowSmallRight";
import {CalendarPickerView} from "@mui/x-date-pickers";

export default function CDatePicker(props: {
    label: string,
    value: Moment | null,
    onChange: (val: Moment | null) => void,
    shouldDisableDate?: (day: any) => boolean,
    fullWidth?: boolean,
    views?: CalendarPickerView[] | undefined,
    minDate?: Moment | null,
    maxDate?: Moment | null,
}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <DatePicker
            components={{
                OpenPickerIcon: Calendar,
                LeftArrowIcon: ArrowSmallLeft,
                RightArrowIcon: ArrowSmallRight
            }}
            views={props.views}
            label={props.label}
            value={props.value}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            onChange={props.onChange}
            renderInput={(params) => <TextField
                {...params}
                variant="standard"
                fullWidth={props.fullWidth}
                inputProps={{
                    onKeyPress: (e) => e.preventDefault(),
                    onClick: handleOpen,
                    ...params.inputProps,
                }}
            />}
            shouldDisableDate={props.shouldDisableDate}
            minDate={props.minDate}
            maxDate={props.maxDate}
        />
    );
}

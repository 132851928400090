import React, {ReactNode, useState} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton, Link, Typography} from "@mui/material";
import theme from "../../theme";
import Xmark from "../icons/Xmark";
import {useTranslation} from "react-i18next";

export default function PersonalDataProcessingDialog(props: {
    title?: ReactNode
}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false)

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Link
                href="#"
                onClick={() => setOpen(true)}
            >
                {props.title ?? t("PersonalDataProcessingDialog.title")}
            </Link>
            <Dialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4">
                        {t("PersonalDataProcessingDialog.startTitle")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.startContent")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p1")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p11")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p111")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p112")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p113")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p114")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p115")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p116")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p117")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p2")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p21")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p22")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p23")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p24")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p3")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p31")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p32")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p321")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p322")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p323")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p33")}
                        <ul style={{margin: 0}}>
                            <li>{t("PersonalDataProcessingDialog.p33l1")}</li>
                            <li>{t("PersonalDataProcessingDialog.p33l2")}</li>
                            <li>{t("PersonalDataProcessingDialog.p33l3")}</li>
                            <li>{t("PersonalDataProcessingDialog.p33l4")}</li>
                            <li>{t("PersonalDataProcessingDialog.p33l5")}</li>
                            <li>{t("PersonalDataProcessingDialog.p33l6")}</li>
                        </ul>
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p331")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p34")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p4")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p41")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p411")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p412")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p413")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p414")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p415")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p416")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p417")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p418")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p419")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p4110")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p4111")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p4112")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p5")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p51")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p52")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p53")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p54")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p55")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p56")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p6")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p61")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p62")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p7")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p71")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p711")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p712")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p72")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p721")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p722")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p723")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p724")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p8")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p81")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p82")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p821")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p822")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p823")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p9")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p91")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p92")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p93")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p94")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("PersonalDataProcessingDialog.p10")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p101")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p102")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p103")}
                    </Typography>
                    <Typography variant="body2">
                        {t("PersonalDataProcessingDialog.p104")}
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
}
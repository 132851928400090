import React, {Suspense, useEffect, useState} from "react";
import "./styles/styles.sass";
import "react-multi-carousel/lib/styles.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import routes from "./routes";
import PreloaderPage from "./pages/PreloaderPage";
import HomePage from "./pages/HomePage";
import PlatformPage from "./pages/PlatformPage";
import AboutPage from "./pages/AboutPage";
import ContactsPage from "./pages/ContactsPage";
import SpecialistsPage from "./pages/SpecialistsPage";
import TestPage from "./pages/TestPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import RequestPasswordResetPage from "./pages/RequestPasswordResetPage";
import ProfilePage from "./pages/ProfilePage";
import SpecialistPage from "./pages/SpecialistPage";
import ArticlePage from "./pages/ArticlePage";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {ErrorHandler} from "./context/ErrorHandler";
import AuthProvider from "./context/AuthProvider";
import OnlyAuthorizedRoute from "./OnlyAuthorizedRoute";
import OnlyNotAuthorizedRoute from "./OnlyNotAuthorizedRoute";
import ScrollToTop from "./components/functional/ScrollToTop";
import PasswordResetPage from "./pages/PasswordResetPage";
import {initI18n} from "./i18n/config";

function App() {
    const [authCompleted, setAuthCompleted] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    useEffect(() => {
        initI18n()
        setAuthCompleted(true)
    }, [])

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="ru"
        >
            <ErrorHandler>
                <Suspense fallback={<PreloaderPage/>}>
                    <Router>
                        <ScrollToTop />
                        <AuthProvider onChangeAuth={setIsAuthenticated}>
                            {authCompleted ? (
                                <Routes>
                                    <Route path={routes.home} element={<HomePage/>}/>
                                    <Route path={routes.platform} element={<PlatformPage/>}/>
                                    <Route path={routes.about} element={<AboutPage/>}/>
                                    <Route path={routes.contacts} element={<ContactsPage/>}/>
                                    <Route path={routes.specialists} element={<SpecialistsPage/>}/>
                                    <Route path={routes.singleSpecialist} element={<SpecialistPage/>}/>
                                    <Route path={routes.article} element={<ArticlePage/>}/>
                                    <Route path={routes.test} element={
                                        <OnlyNotAuthorizedRoute
                                            element={<TestPage/>}
                                            authenticated={isAuthenticated}
                                        />
                                    }/>
                                    <Route path={routes.login} element={
                                        <OnlyNotAuthorizedRoute
                                            element={<LoginPage/>}
                                            authenticated={isAuthenticated}
                                        />
                                    }/>
                                    <Route path={routes.requestPasswordReset} element={
                                        <OnlyNotAuthorizedRoute
                                            element={<RequestPasswordResetPage/>}
                                            authenticated={isAuthenticated}
                                        />
                                    }/>
                                    <Route path={routes.passwordReset} element={
                                        <OnlyNotAuthorizedRoute
                                            element={<PasswordResetPage/>}
                                            authenticated={isAuthenticated}
                                        />
                                    }/>
                                    <Route path={routes.profile} element={
                                        <OnlyAuthorizedRoute
                                            element={<ProfilePage/>}
                                            authenticated={isAuthenticated}
                                        />
                                    }/>
                                    <Route path='*' element={<NotFoundPage/>}/>
                                </Routes>
                            ) : (
                                <PreloaderPage />
                            )}
                        </AuthProvider>
                    </Router>
                </Suspense>
            </ErrorHandler>
        </LocalizationProvider>
    );
}

export default App;

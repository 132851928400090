import React, {useEffect, useState, useRef} from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import {Box, Container, Typography, Skeleton, Link, Button} from "@mui/material";
import {styled} from "@mui/system";
import BoxFlex from "../components/layout/BoxFlex";
import FeaturedArticlesBlock from "../components/blocks/FeaturedArticlesBlock";
import {useNavigate, useParams} from "react-router-dom";
import ArrowSmallLeft from "../components/icons/ArrowSmallLeft";
import theme from "../theme";
import {dateFormatter, isDev, sleep} from "../utils";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import api from "../api/api";
import routes from "../routes";
import CourseSpecialist from "../components/blocks/CourseSpecialist";
import BuyCourseBlock from "../components/blocks/BuyCourseBlock";
import Bolt from "../components/icons/Bolt";
import {useTranslation} from "react-i18next";

const NavigationBlock = styled(BoxFlex)({
    padding: "40px 0",
    justifyContent: "space-between",
}) as typeof BoxFlex

const NavigationInfoContainer = styled(BoxFlex)({
    alignItems: "center",
    gridGap: 8,

    "@media (max-width: 899px)": {
        alignItems: "end",
        flexDirection: "column",
        gridGap: 2,
    }
}) as typeof BoxFlex

const NavigationInfoBlock = styled(BoxFlex)(({theme}) => ({
    alignItems: "center",
    gridGap: 8,

    "&:not(:last-of-type)::after": {
        content: "''",
        width: 4,
        height: 4,
        borderRadius: "50%",
        backgroundColor: theme.palette.text.secondary,
    },

    "@media (max-width: 899px)": {
        gridGap: 2,
        "&::after": {
            display: "none",
        }
    }
})) as typeof BoxFlex

const ArticleContainer = styled(Box)({
    paddingTop: 96,

    "@media (max-width: 899px)": {
        paddingTop: 12,
    }
}) as typeof Box

const TitleContainer = styled(BoxFlex)({
    gridGap: 36,
    alignItems: "center",
    marginBottom: "2rem",

    "@media (max-width: 899px)": {
        flexDirection: "column",
    }
}) as typeof BoxFlex

const ArticlePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams()
    const anchorRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(true)
    const [title, setTitle] = useState<string>('')
    const [subtitle, setSubtitle] = useState<string>('')
    const [tsPublication, setTsPublication] = useState<number>();
    const [categories, setCategories] = useState<{ id: number, title: string }[]>([])
    const [contentTop, setContentTop] = useState<string>('')
    const [contentBottom, setContentBottom] = useState<string>('')
    const [specialistData, setSpecialistData] = useState<{
        id: number,
        name: string,
        position: string,
        avatar?: string,
        education?: string,
        placeOfWork?: string,
        specialization?: string,
        price?: number,
    }>()
    const [price, setPrice] = useState<number>()

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../mocks/articles__getSingleData.json")
            setTitle(response.title)
            setSubtitle(response.subtitle)
            setTsPublication(response.dateTs)
            setCategories(response.categories)
            setContentTop(response.contentTop)
            setContentBottom(response.contentBottom)
            setSpecialistData(response.specialist)
            setPrice(response.price)
        } else {
            await api.get(`/articles/${id}`)
                .then(res => {
                    if (res.status === 200) {
                        const data = res.data
                        setTitle(data.title)
                        setSubtitle(data.subtitle)
                        setTsPublication(data.dateTs)
                        setCategories(data.categories)
                        setContentTop(data.contentTop)
                        setContentBottom(data.contentBottom)
                        setPrice(data.price)
                        if (data.specialist) {
                            setSpecialistData(data.specialist)
                        }
                    }
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        navigate('/oops')
                    }
                })
        }
        setLoading(false)
    }

    const handleAnchorClick = (event: React.MouseEvent) => {
        event.preventDefault();

        const anchor = anchorRef.current;
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DefaultTemplate>
            <HeroBlock
                background={price ? theme.palette.physical.light : undefined}
                navigation={
                    <NavigationBlock>
                        <Link
                            href={routes.platform}
                        >
                            <BoxFlex
                                sx={{
                                    gridGap: 12,
                                    alignItems: "center",
                                }}
                            >
                                <BoxFlex
                                    sx={{
                                        padding: "10px",
                                        borderRadius: "50%",
                                        border: `1px solid ${theme.palette.border.main}`,
                                    }}
                                >
                                    <ArrowSmallLeft/>
                                </BoxFlex>
                                <Typography
                                    variant="subtitle2"
                                    color="text.primary"
                                    sx={{
                                        display: {xs: 'none', lg: 'flex'}
                                    }}
                                >
                                    {t("common.back")}
                                </Typography>
                            </BoxFlex>
                        </Link>

                        {loading
                            ? <Skeleton width="150px"/>
                            : <NavigationInfoContainer>
                                <NavigationInfoBlock
                                    order={-1}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                    >
                                        {tsPublication &&
                                            <b>{dateFormatter(tsPublication * 1000)}</b>
                                        }
                                    </Typography>
                                </NavigationInfoBlock>
                                {categories.map((val, i) => (
                                    <NavigationInfoBlock
                                        key={i}
                                        order={1}
                                    >
                                        <Link href={routes.platform + `?categoryIds=${val.id}`}>
                                            <Typography
                                                key={i}
                                                variant="subtitle2"
                                                color="text.secondary"
                                            >
                                                {val.title}
                                            </Typography>
                                        </Link>
                                    </NavigationInfoBlock>
                                ))
                                }
                            </NavigationInfoContainer>
                        }
                    </NavigationBlock>
                }
            >

                <BoxFlexColumn
                    sx={{
                        alignItems: "center",
                        gridGap: 30,
                    }}
                >
                    {price &&
                        <BoxFlex
                            sx={{
                                width: 60,
                                height: 60,
                                alignItems: "center",
                                justifyContent: "center",
                                background: theme.palette.physical.main,
                                borderRadius: "50%",
                            }}
                        >
                            <Bolt
                                color={theme.palette.white.main}
                            />
                        </BoxFlex>
                    }
                    <Typography
                        variant="h1"
                        align="center"
                    >
                        {title}
                    </Typography>
                </BoxFlexColumn>
            </HeroBlock>

            <Container
                maxWidth="md"
            >
                <TitleContainer>
                    <Typography
                        variant="h2"
                    >
                        {title || subtitle}
                    </Typography>

                    {price &&
                        <Button
                            variant="contained"
                            size="large"
                            onClick={handleAnchorClick}
                        >
                            {t("ArticlePage.registering")}
                        </Button>
                    }
                </TitleContainer>

                <div
                    className="article-content"
                    dangerouslySetInnerHTML={{ __html: contentTop }}
                />

                {specialistData !== undefined &&
                    <CourseSpecialist
                        id={specialistData.id}
                        name={specialistData.name}
                        position={specialistData.position}
                        avatar={specialistData.avatar}
                        education={specialistData.education}
                        placeOfWork={specialistData.placeOfWork}
                        specialization={specialistData.specialization}
                    />
                }

                <div
                    className="article-content"
                    dangerouslySetInnerHTML={{ __html: contentBottom }}
                />

                {price &&
                    <div
                        ref={anchorRef}
                    >
                        <BuyCourseBlock
                            articleId={Number(id)}
                            price={price}
                        />
                    </div>
                }
            </Container>

            <Box>
                <Container
                    maxWidth="xl"
                >
                    <Box
                        sx={{
                            borderBottom: `1px solid ${theme.palette.border.main}`
                        }}
                    />
                </Container>
                <ArticleContainer>
                    <FeaturedArticlesBlock/>
                </ArticleContainer>
            </Box>
        </DefaultTemplate>
    )
}

export default ArticlePage;

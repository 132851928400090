import React from "react";
import {Container, Link, Typography} from "@mui/material";
import {styled} from "@mui/system";
import ArrowSmallRight from "../icons/ArrowSmallRight";
import theme from "../../theme";
import routes from "../../routes";
import CPaper from "../surfaces/CPaper";
import BoxFlex from "../layout/BoxFlex";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import {useTranslation} from "react-i18next";

const HowToWorksContainer = styled(BoxFlex)({
    flexWrap: "wrap",
    gridGap: 20,

    "@media (max-width: 899px)": {
        gridGap: 16,
    }
}) as typeof BoxFlex;

const HowToWorksItem = styled(BoxFlexColumn)({
    justifyContent: "space-between",
}) as typeof BoxFlexColumn;

const StyledCPaper = styled(CPaper)({
    flex: "1 1 0",
    width: 0,

    "@media (max-width: 899px)": {
        width: "100%",
        flex: "initial",
    },
}) as typeof CPaper;

const HowToWorksBlock = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("common.takeTest"),
            text: t("HowToWorksBlock.step1Text"),
            linkText: t("common.takeTest"),
            url: routes.test,
        },
        {
            title: t("HowToWorksBlock.step2Title"),
            text: t("HowToWorksBlock.step2Text"),
            linkText: t("HowToWorksBlock.step2LinkText"),
            url: routes.specialists,
        },
        {
            title: t("HowToWorksBlock.step3Title"),
            text: t("HowToWorksBlock.step3Text"),
            linkText: t("HowToWorksBlock.step3LinkText"),
            url: routes.login,
        },
    ];

    return (
        <Container maxWidth="xl">
            <Typography
                variant="h2"
                className="block-title"
            >
                {t("HowToWorksBlock.title")}
            </Typography>
            <HowToWorksContainer>
                {items.map((val, i) => (
                    <StyledCPaper
                        key={i}
                    >
                        <HowToWorksItem>
                            <Typography
                                variant="h2"
                                sx={{
                                    backgroundImage: theme.palette.gradient.main,
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    MozBackgroundClip: "text",
                                    MozTextFillColor: "transparent",
                                    paddingBottom: "32px",
                                    marginBottom: "28px",
                                    borderBottom: `1px solid ${theme.palette.border.main}`,
                                }}
                            >
                                0{i + 1}
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                mb={1.5}
                            >
                                {items[i].title}
                            </Typography>
                            <Typography
                                variant="body2"
                                mb={5}
                            >
                                {items[i].text}
                            </Typography>

                            <Link
                                href={items[i].url}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    color="text.tertiary"
                                >
                                    {items[i].linkText}
                                </Typography>
                                <ArrowSmallRight
                                    color={theme.palette.text.tertiary}
                                />
                            </Link>
                        </HowToWorksItem>
                    </StyledCPaper>
                ))}
            </HowToWorksContainer>
        </Container>
    );
}

export default HowToWorksBlock;
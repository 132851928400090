import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Link, Skeleton, Typography} from "@mui/material";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import BoxFlex from "../layout/BoxFlex";
import theme from "../../theme";
import {styled} from "@mui/system";
import CPaper from "../surfaces/CPaper";
import {isDev, sleep} from "../../utils";
import CAvatar from "../data-display/CAvatar";
import {categories, getLightColorByCategory, getTitleByCategory} from "../../categories";
import PlusButton from "../inputs/PlusButton";
import routes from "../../routes";
import api from "../../api/api";
import {useTranslation} from "react-i18next";

const IndividualRowBox = styled(BoxFlex)(({theme}) => ({
    padding: "30px 0",
    borderTop: `1px solid ${theme.palette.border.main}`,
    gridGap: 20,
    justifyContent: "space-between",
})) as typeof BoxFlex

const IndividualItemPaper = styled(CPaper)(({theme}) => ({
    position: "relative",
    flexShrink: 0,
    width: 310,
    paddingLeft: "58px !important",
    background: `linear-gradient(90deg, #C4C7D0 0%, #C4C7D0 30px, ${theme.palette.white.main} 30px)`,

    "@media (max-width: 899px)": {
        width: "100%",
    },
})) as typeof CPaper

const IndividualItemWrapper = styled(BoxFlex)({
    gridGap: 20,
    flexWrap: "wrap",
}) as typeof BoxFlex

const IndividualRowHeader = styled(BoxFlexColumn)({
    gridGap: 24,
    alignItems: "start",

    "@media (max-width: 899px)": {
        flexDirection: "row",
        justifyContent: "space-between",
    },
}) as typeof BoxFlexColumn

const PercentBox = styled(Box)({
    padding: "4px 16px",
    borderRadius: 40,
    flexShrink: 0,
}) as typeof Box

const IndividualItem = (props: {
    url: string,
    title: string,
    color?: string,
}) => {
    const { t } = useTranslation();
    return (
        <Link
            href={props.url}
        >
            <IndividualItemPaper
                sx={{
                    background: props.color ? `linear-gradient(90deg, ${props.color} 0%, ${props.color} 30px, ${theme.palette.white.main} 30px)` : theme.palette.white.main,
                }}
            >
                <BoxFlexColumn
                    sx={{
                        gridGap: 36,
                    }}
                >
                    <Typography
                        variant="subtitle2"
                    >
                        {props.title}
                    </Typography>


                    <Typography
                        variant="subtitle2"
                        color="text.tertiary"
                    >
                        {t("IndividualPlanBlock.look")}
                    </Typography>
                </BoxFlexColumn>
            </IndividualItemPaper>
        </Link>
    )
}

const IndividualSpecialist = (props: {
    id: number,
    name: string,
    avatar?: string,
}) => {
    const { t } = useTranslation();
    return (
        <Link
            href={routes.singleSpecialist.replace(':id', String(props.id))}
        >
            <IndividualItemPaper>
                <BoxFlex
                    sx={{
                        gridGap: 15,
                        alignItems: "center",
                    }}
                >
                    <BoxFlexColumn
                        sx={{
                            gridGap: 12,
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                        >
                            {props.name}
                        </Typography>

                        <Typography
                            variant="subtitle2"
                            color="text.tertiary"
                        >
                            {t("common.makeAnAppointment")}
                        </Typography>
                    </BoxFlexColumn>
                    <CAvatar
                        src={props.avatar ?? undefined}
                        alt={props.name}
                    />
                </BoxFlex>
            </IndividualItemPaper>
        </Link>
    )
}

const IndividualRow = (props: {
    title: string,
    percent: number,
    color?: string,
    categoryId?: number,
    articleIds?: number[] | null,
}) => {
    const { t } = useTranslation();
    return (
        <IndividualRowBox>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    md={3}
                >
                    <IndividualRowHeader>
                        <Typography
                            variant="h4"
                        >
                            {props.title}
                        </Typography>
                        <PercentBox
                            sx={{
                                backgroundColor: props.color,
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                            >
                                {props.percent} %
                            </Typography>
                        </PercentBox>
                    </IndividualRowHeader>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={9}
                >

                    <IndividualItemWrapper>
                        {props.categoryId &&
                            <IndividualItem
                                url={routes.platform + "?categoryIds=" + props.categoryId}
                                title={t("IndividualPlanBlock.materials")}
                                color={props.color}
                            />
                        }
                        {(props.articleIds !== null && props.articleIds !== undefined) && props.articleIds.length > 0 &&
                            <>
                                {props.articleIds.map(articleId => (
                                    <IndividualItem
                                        url={routes.article.replace(':id', String(articleId))}
                                        title={t("IndividualPlanBlock.course")}
                                        color={props.color}
                                    />
                                ))}
                            </>
                        }
                    </IndividualItemWrapper>
                </Grid>
            </Grid>
        </IndividualRowBox>
    )
}

const IndividualPlanBlock = () => {
    const { t } = useTranslation();
    const [loadingPlanData, setLoadingPlanData] = useState<boolean>(true)
    const [planData, setPlanData] = useState<{
        category: keyof typeof categories,
        value: number,
        categoryId?: number,
        articleIds?: number[],
    }[]>([])
    const [loadingMySpecialists, setLoadingMySpecialists] = useState<boolean>(true)
    const [mySpecialists, setMySpecialists] = useState<{ id: number, name: string, avatar?: string }[]>([])

    const handleAddSpecialist = () => {
        // todo implement
    }

    const fetchMySpecialists = async () => {
        if (isDev) {
            await sleep(500)
            setMySpecialists(require("../../mocks/individualPlan__getMySpecialists.json"))
        } else {
            await api.get('/profile/specialists')
                .then(res => {
                    if (res.status === 200) {
                        setMySpecialists(res.data)
                    }
                })
        }
        setLoadingMySpecialists(false)
    };

    const fetchPlanData = async () => {
        if (isDev) {
            await sleep(1500)
            setPlanData(require("../../mocks/individualPlan__getPlanData.json"))
        } else {
            await api.get('/profile/individual-plan')
                .then(res => {
                    if (res.status === 200) {
                        setPlanData(res.data)
                    }
                })
        }
        setLoadingPlanData(false)
    };

    useEffect(() => {
        fetchMySpecialists()
        fetchPlanData()
    }, [])

    return (
        <Container maxWidth="xl">
            <BoxFlexColumn
                sx={{
                    gridGap: 50,

                    "@media (max-width: 899px)": {
                        gridGap: 40,
                    },
                }}
            >
                <Typography
                    variant="h2"
                >
                    {t("common.individualPlan")}
                </Typography>

                <BoxFlexColumn>
                    <IndividualRowBox>
                        {loadingMySpecialists ? (
                            <Skeleton
                                variant="rounded"
                                height="16rem"
                                width="100%"
                            />
                        ) : (
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                >
                                    <Typography
                                        variant="h4"
                                    >
                                        {t("IndividualPlanBlock.subtitle")}
                                    </Typography>

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={9}
                                >
                                    <IndividualItemWrapper>
                                        {mySpecialists.map((specialist, i) => (
                                            <IndividualSpecialist
                                                key={i}
                                                id={specialist.id}
                                                name={specialist.name}
                                                avatar={specialist.avatar}
                                            />
                                        ))}
                                        <IndividualItemPaper
                                            sx={{
                                                paddingLeft: "38px",
                                                paddingRight: "6px",
                                            }}
                                        >
                                            <BoxFlex
                                                height="100%"
                                                alignItems="center"
                                            >
                                                <PlusButton
                                                    onClick={handleAddSpecialist}
                                                    sx={{
                                                        paddingRight: "12px",
                                                    }}
                                                    href={routes.specialists}
                                                >
                                                    {t("IndividualPlanBlock.addBtn")}
                                                </PlusButton>
                                            </BoxFlex>
                                        </IndividualItemPaper>
                                    </IndividualItemWrapper>
                                </Grid>
                            </Grid>
                        )}
                    </IndividualRowBox>
                    {loadingPlanData ? (
                        <>
                            <IndividualRowBox>
                                <Skeleton
                                    variant="rounded"
                                    height="16rem"
                                    width="100%"
                                />
                            </IndividualRowBox>

                            <IndividualRowBox>
                                <Skeleton
                                    variant="rounded"
                                    height="16rem"
                                    width="100%"
                                />
                            </IndividualRowBox>

                            <IndividualRowBox>
                                <Skeleton
                                    variant="rounded"
                                    height="16rem"
                                    width="100%"
                                />
                            </IndividualRowBox>
                        </>
                    ) : (
                        <>
                            {planData.map((planDatum, i) => (
                                <IndividualRow
                                    key={i}
                                    title={getTitleByCategory(planDatum.category)}
                                    percent={Math.round(planDatum.value * 100)}
                                    color={getLightColorByCategory(planDatum.category)}
                                    categoryId={planDatum.categoryId}
                                    articleIds={planDatum.articleIds}
                                />
                            ))}
                        </>
                    )}
                </BoxFlexColumn>
            </BoxFlexColumn>
        </Container>
    );
}

export default IndividualPlanBlock;
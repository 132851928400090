import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    SelectChangeEvent,
    Skeleton,
    Table,
    TableCell,
    TableRow, TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import BoxFlex from "../layout/BoxFlex";
import CSelect from "../inputs/CSelect";
import DocumentText from "../icons/DocumentText";
import {styled} from "@mui/system";
import CPaper from "../surfaces/CPaper";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import {makeStyles} from "@mui/styles";
import LinearProgressWithLabel from "../charts/LinearProgressWithLabel";
import PieChart from "../charts/PieChart";
import PainScore from "../data-display/PainScore";
import PainProgress from "../data-display/PainProgress";
import theme from "../../theme";
import {
    dateFormatter,
    getAdditionalSymptomTitleByCode,
    getCausesAndEffectTitleByCode,
    getHelpersTitleByCode, getNatureTitleByCode,
    isDev, percentFormatter,
    sleep
} from "../../utils";
import PlusButton from "../inputs/PlusButton";
import BarChart from "../charts/BarChart";
import {gender} from "../../types/gender";
import api from "../../api/api";
import {painTypes as painTypeVariants} from "../../types/painTypes";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {useTranslation} from "react-i18next";
import {getTitleByCategory} from "../../categories";

const useStyles = makeStyles({
    parent: {
        display: "grid",
        gridGap: 20,
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRow: "repeat(5, 1fr)",

        "@media (max-width: 899px)": {
            display: "flex",
            flexDirection: "column",
        },
    },
    block1: {
        gridArea: "1 / 1 / 2 / 2",
    },
    block2: {
        gridArea: "2 / 1 / 5 / 2",
    },
    block3: {
        gridArea: "1 / 2 / 3 / 3",
    },
    block4: {
        gridArea: "1 / 3 / 3 / 4",
    },
    block5: {
        gridArea: "3 / 2 / 5 / 4",
    },
    block6: {
        gridArea: "5 / 1 / 6 / 2",
    },
    block7: {
        gridArea: "5 / 2 / 6 / 3",
    },
    block8: {
        gridArea: "5 / 3 / 6 / 4",
    },
    block9: {
        gridArea: "6 / 1 / 7 / 4",
    },
});

const TitleContainer = styled(BoxFlex)({
    justifyContent: "space-between",
    marginBottom: "50px",

    "@media (max-width: 899px)": {
        flexDirection: "column",
        gridGap: 40,
    },
    "@media(max-width: 599px)": {
        padding: "0 20px",
    }
}) as typeof BoxFlex

const NoteItem = styled(BoxFlex)({
    display: "grid",
    gridTemplateColumns: "235px 1fr",
    gridTemplateRows: "1fr",
    gridGap: "14px",
    alignItems: "baseline",

    "@media (max-width: 899px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}) as typeof BoxFlex

const PainRatioWrapper = styled(BoxFlexColumn)(({theme}) => ({
    borderRadius: 15,
    backgroundColor: theme.palette.background.default,
    padding: "18px 19px 18px 24px",
})) as typeof BoxFlexColumn

const PainRatioItem = styled(BoxFlex)(({theme}) => ({
    justifyContent: "space-between",
    padding: "18px 0",
    borderBottom: `1px solid ${theme.palette.border.main}`,

    "&:first-of-type": {
        paddingTop: 0,
    },
    "&:last-of-type": {
        paddingBottom: 0,
        borderBottom: "none",
    }
})) as typeof BoxFlex

const InfoRow = (props: {
    title: string,
    value: string | number | null,
}) => (
    <TableRow>
        <TableCell>
            <Typography
                variant="body2"
                color="text.secondary"
            >
                {props.title}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography
                variant="body2"
            >
                {props.value}
            </Typography>
        </TableCell>
    </TableRow>
)

const ProgressesBlock = (props: {
    title: string,
    data: {
        title: string,
        value: number,
    }[]
}) => {
    const { t } = useTranslation();

    return (
        <BoxFlexColumn
            sx={{
                gridGap: 12,
                height: "100%",
            }}
        >
            <Typography
                variant="subtitle1"
            >
                {props.title}
            </Typography>

            <BoxFlexColumn
                sx={{
                    ...(props.data.length === 0 && {
                        justifyContent: "center",
                        height: "100%",
                    })
                }}
            >
                {props.data.length === 0 &&
                    <Typography
                        variant="body2"
                        align="center"
                        color="text.disabled"
                    >
                        {t("common.noData")}
                    </Typography>
                }
                {props.data.map((datum, i) => (
                    <LinearProgressWithLabel
                        key={i}
                        value={datum.value}
                        title={datum.title}
                    />
                ))}
            </BoxFlexColumn>
        </BoxFlexColumn>
    )
}

const PainSyndromeBlock = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true)
    const [startTime, setStartTime] = useState<string>('')
    const [painTypes, setPainTypes] = useState<Array<keyof typeof painTypeVariants>>([])
    const [historyValue, setHistoryValue] = useState<string>('');
    const [disableBtn, setDisableBtn] = useState<boolean>(true)
    const [error, setError] = useState<string>('')

    const setResponse = (response: any) => {
        setStartTime(response.startTime)
        setPainTypes(response.types)
        setHistoryValue(response.history ?? '')
    }

    const fetchData = async () => {
        if (isDev) {
            await sleep(1100)
            setResponse(require("../../mocks/profile__getPainSyndromeData.json"))
        } else {
            await api.get('/profile/pain-syndrome')
                .then(res => {
                    if (res.status === 200) {
                        setResponse(res.data)
                    }
                })
        }
        setLoading(false)
    }

    const updateHistory = async () => {
        setDisableBtn(true)
        if (isDev) {
            await sleep(1100)
        } else {
            await api.patch('/profile/pain-syndrome', {
                value: historyValue
            })
                .then(res => {
                    if (res.status === 200) {
                        fetchData()
                    }
                })
                .catch(err => {
                    setError(err.response.data.message)
                })
        }
        setDisableBtn(false)
    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setError('')
        setDisableBtn(historyValue.length === 0)
    }, [historyValue])

    useEffect(() => {
        setDisableBtn(error.length !== 0)
    }, [error])

    return (
        <>
            {
                loading ? (
                    <Skeleton
                        variant="rounded"
                        className={classes.block3}
                        height="16rem"
                    />
                ) : (
                    <CPaper
                        className={classes.block3}
                    >
                        <BoxFlexColumn
                            height="100%"
                            justifyContent="space-between"
                        >
                            <BoxFlexColumn>
                                <Typography
                                    variant="subtitle1"
                                    marginBottom="20px"
                                >
                                    {t("ProfilePage.painSyndrome")}
                                </Typography>

                                <Grid
                                    container
                                    sx={{
                                        borderBottom: `1px solid ${theme.palette.border.main}`,
                                        paddingBottom: "11px",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <Typography
                                            variant="captionUppercase"
                                            color="text.tertiary"
                                        >
                                            {t("ProfilePage.localization")}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <Typography
                                            variant="captionUppercase"
                                            color="text.tertiary"
                                        >
                                            {t("ProfilePage.appearance")}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {painTypes.map((datum, i) => (
                                    <Grid
                                        key={i}
                                        container
                                        sx={{
                                            paddingTop: "12px",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                            >
                                                {painTypeVariants[datum]}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                            >
                                                {startTime}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}

                                <PlusButton
                                    sx={{
                                        marginTop: "33px",
                                        marginBottom: "21px",
                                    }}
                                    disabled={disableBtn}
                                    onClick={updateHistory}
                                >
                                    {t("ProfilePage.addMyPainStory")}
                                </PlusButton>
                            </BoxFlexColumn>

                            <TextField
                                placeholder={t("ProfilePage.enterYourStory")}
                                variant="outlined"
                                onChange={(e) => setHistoryValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.ctrlKey && e.keyCode === 13) {
                                        updateHistory()
                                    }
                                }}
                                value={historyValue}
                                error={error.length !== 0}
                                helperText={error}
                                multiline
                                maxRows={12}
                                sx={{
                                    height: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: theme.palette.background.default,
                                    "& .MuiInputBase-root": {
                                        padding: "11px 18px",
                                    },
                                    "& .MuiInputBase-input": {
                                        color: theme.palette.text.primary,
                                    },
                                    "& fieldset": {
                                        border: "none",
                                    }
                                }}
                            />
                        </BoxFlexColumn>
                    </CPaper>
                )
            }
        </>
    )
}

const NotesBlock = (props: {
    dateStart: string,
    dateEnd: string,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ date: string, value: string }[]>([])
    const [newNoteValue, setNewValueNote] = useState<string>('');
    const [disableBtn, setDisableBtn] = useState<boolean>(true)
    const [error, setError] = useState<string>('')

    const fetchData = async () => {
        if (isDev) {
            await sleep(1100)
            const response = require("../../mocks/profile__getNotes.json")
            setData(response)
        } else {
            await api.get('/profile/notes', {
                dateStart: props.dateStart,
                dateEnd: props.dateEnd,
            })
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    }

    const addNewNote = async () => {
        setDisableBtn(true)
        if (isDev) {
            await sleep(1100)
        } else {
            await api.post('/profile/notes', {
                value: newNoteValue
            })
                .then(res => {
                    if (res.status === 200) {
                        fetchData()
                    }
                })
                .catch(err => {
                    setError(err.response.data.message)
                })
        }
        setDisableBtn(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setError('')
        setDisableBtn(newNoteValue.length === 0)
    }, [newNoteValue])

    useEffect(() => {
        setDisableBtn(error.length !== 0)
    }, [error])

    return (
        <>
            {loading ? (
                <Skeleton
                    variant="rounded"
                    className={classes.block9}
                    height="16rem"
                />
            ) : (
                <CPaper
                    className={classes.block9}
                >
                    <BoxFlexColumn
                        sx={{
                            gridGap: 31,
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                        >
                            {t("NotesBlock.title")}
                        </Typography>

                        <BoxFlexColumn
                            sx={{
                                rowGap: "14px",
                                maxHeight: 500,
                                overflow: "auto",
                            }}
                        >
                            <NoteItem>
                                <PlusButton
                                    disabled={disableBtn}
                                    onClick={addNewNote}
                                >
                                    {t("NotesBlock.btn")}
                                </PlusButton>

                                <TextField
                                    placeholder={t("NotesBlock.input")}
                                    variant="outlined"
                                    onChange={(e) => setNewValueNote(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.ctrlKey && e.keyCode === 13) {
                                            addNewNote()
                                        }
                                    }}
                                    value={newNoteValue}
                                    error={error.length !== 0}
                                    helperText={error}
                                    multiline
                                    maxRows={6}
                                    sx={{
                                        gridArea: "1 / 2 / 2 / 3",
                                        borderRadius: "20px",
                                        backgroundColor: theme.palette.background.default,
                                        "& .MuiInputBase-root": {
                                            padding: "11px 18px",
                                        },
                                        "& .MuiInputBase-input": {
                                            color: theme.palette.text.primary,
                                        },
                                        "& fieldset": {
                                            border: "none",
                                        }
                                    }}
                                />
                            </NoteItem>
                            {data.map((datum, i) => (
                                <Box
                                    key={i}
                                    sx={{
                                        padding: "11px 18px",
                                        borderRadius: "20px",
                                        backgroundColor: theme.palette.background.default,
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {datum.date} {datum.value}
                                    </Typography>
                                </Box>
                            ))}
                        </BoxFlexColumn>
                    </BoxFlexColumn>
                </CPaper>
            )}
        </>
    )
}

const PainRatioBlock = (props: {
    values: {
        psychotherapy?: number,
        meditation?: number,
        yoga?: number,
        bos?: number,
        physical?: number,
        manual?: number,
        acupuncture?: number,
        invasive?: number,
        isTakingMedications?: boolean,
    }
}) => {
    const { t } = useTranslation();
    const [isTakingMedicationsLocal, setIsTakingMedicationsLocal] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    useEffect(() => {
        if (props.values.isTakingMedications) {
            setIsTakingMedicationsLocal(props.values.isTakingMedications)
        }
    }, [props])

    const data = [
        {
            title: t("ProfilePage.mind"),
            items: {
                psychotherapy: {
                    title: getTitleByCategory("psychotherapy"),
                    color: theme.palette.psychotherapy.main,
                },
                meditation: {
                    title: getTitleByCategory("meditation"),
                    color: theme.palette.meditation.main,
                },
                yoga: {
                    title: getTitleByCategory("yoga"),
                    color: theme.palette.yoga.main,
                },
                bos: {
                    title: getTitleByCategory("bos"),
                    color: theme.palette.bos.main,
                },
            }
        },
        {
            title: t("ProfilePage.body"),
            items: {
                physical: {
                    title: getTitleByCategory("physical"),
                    color: theme.palette.physical.main,
                },
                manual: {
                    title: getTitleByCategory("manual"),
                    color: theme.palette.manual.main,
                },
                acupuncture: {
                    title: getTitleByCategory("acupuncture"),
                    color: theme.palette.acupuncture.main,
                },
                invasive: {
                    title: getTitleByCategory("invasive"),
                    color: theme.palette.invasive.main,
                },
            }
        }
    ]

    const updateIsTakingMedications = async (bool: boolean) => {
        setDisabled(true)
        await api.patch('/profile/test-result', {
            isTakingMedications: bool
        })
            .then(res => {
                if (res.status === 200 && res.data.ok) {
                    setIsTakingMedicationsLocal(bool)
                }
            })
            .finally(() => setDisabled(false))
    }

    return (
        <BoxFlexColumn>
            <Typography
                variant="subtitle1"
                marginBottom="26px"
            >
                {t("ProfilePage.controlMethods")}
            </Typography>

            {data.map((group, i) => (
                <BoxFlexColumn
                    key={i}
                    marginBottom="24px"
                >
                    <Typography
                        variant="subtitle1"
                        marginBottom="6px"
                        paddingLeft="12px"
                    >
                        {group.title}
                    </Typography>

                    <PainRatioWrapper>
                        {Object.keys(group.items).map((groupKey, k) => (
                            <PainRatioItem
                                key={k}
                            >
                                <Typography
                                    variant="subtitle2"
                                >
                                    {group.items[groupKey as keyof typeof group.items]?.title}
                                </Typography>

                                <BoxFlex
                                    sx={{
                                        backgroundColor: group.items[groupKey as keyof typeof group.items]?.color,
                                        padding: "4px 16px",
                                        borderRadius: 40,
                                    }}
                                >
                                    {
                                        props?.values[groupKey as keyof typeof props.values]
                                            ? percentFormatter((props.values[groupKey as keyof typeof props.values] as number)) + "%"
                                            : "-"
                                    }
                                    <Typography
                                        variant="subtitle1"
                                    >
                                    </Typography>
                                </BoxFlex>
                            </PainRatioItem>
                        ))}
                    </PainRatioWrapper>
                </BoxFlexColumn>
            ))}

            <FormControlLabel
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                control={
                    <Checkbox
                        checked={isTakingMedicationsLocal}
                        disabled={disabled}
                        onChange={e => updateIsTakingMedications(e.target.checked)}
                    />
                }
                labelPlacement="start"
                label={
                    <Typography
                        variant="subtitle1"
                    >
                        {t("ProfilePage.painkillers")}
                    </Typography>
                }
            />
        </BoxFlexColumn>
    )
}

const MyMedicalRecordBlock = (props: {
    name: string | null,
    age: number | null,
    height: number | null,
    weight: number | null,
    gender: keyof typeof gender | null
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const ref = useRef(null)
    const periodVariants: { [index: string]: string } = {
        week: t("MyMedicalRecordBlock.inWeek"),
        month: t("MyMedicalRecordBlock.inMonth"),
        threeMonths: t("MyMedicalRecordBlock.inThreeMonths"),
        sixMonths: t("MyMedicalRecordBlock.inSixMonths"),
    };
    const [loadingPainRatio, setLoadingPainRatio] = useState<boolean>(true)
    const [painRatioData, setPainRatioData] = useState<{
        psychotherapy?: number,
        meditation?: number,
        yoga?: number,
        bos?: number,
        physical?: number,
        manual?: number,
        acupuncture?: number,
        invasive?: number,
        isTakingMedications?: boolean,
    }>({
        psychotherapy: undefined,
        meditation: undefined,
        yoga: undefined,
        bos: undefined,
        physical: undefined,
        manual: undefined,
        acupuncture: undefined,
        invasive: undefined,
        isTakingMedications: undefined,
    })
    const [loadingLogNature, setLoadingLogNature] = useState<boolean>(true)
    const [logNature, setLogNature] = useState<{ title: string, value: number }[]>([])
    const [loadingLogScore, setLoadingLogScore] = useState<boolean>(true)
    const [logScore, setLogScore] = useState<{ date: string, value: number }[]>([])
    const [logScoreValue, setLogScoreValue] = useState<number>();
    const [logScoreDiff, setLogScoreDiff] = useState<number>()
    const [loadingLogCausesAndEffects, setLoadingLogCausesAndEffects] = useState<boolean>(true)
    const [logCausesAndEffects, setLogCausesAndEffects] = useState<{ title: string, value: number }[]>([])
    const [loadingLogAdditionalSymptoms, setLoadingLogAdditionalSymptoms] = useState<boolean>(true)
    const [logAdditionalSymptoms, setLogAdditionalSymptoms] = useState<{ title: string, value: number }[]>([])
    const [loadingLogHelpers, setLoadingLogHelpers] = useState<boolean>(true)
    const [logHelpers, setLogHelpers] = useState<{ title: string, value: number }[]>([])
    const [loadingLogDrugs, setLoadingLogDrugs] = useState<boolean>(true)
    const [logDrugs, setLogDrugs] = useState<{ date: string, value: string, isHelped: boolean }[]>([])
    const matches = useMediaQuery(theme.breakpoints.only('xs'));
    const [period, setPeriod] = useState<keyof typeof periodVariants>('')

    const handleChangePeriod = (event: SelectChangeEvent<HTMLInputElement | unknown>): void => {
        setPeriod(event.target.value as string);
    }

    const getDateStart = () => {
        const date = moment()
        if (period === 'week') {
            date.subtract(1, 'weeks');
        }
        if (period === 'month') {
            date.subtract(1, 'months');
        }
        if (period === 'threeMonths') {
            date.subtract(3, 'months');
        }
        if (period === 'sixMonths') {
            date.subtract(6, 'months');
        }

        return date.format('YYYY-MM-DD');
    }

    const getDateEnd = () => moment().format('YYYY-MM-DD')

    const fetchPainRatioData = async () => {
        if (isDev) {
            await sleep(600)
            const response = require("../../mocks/profile__getPainRatioData.json")
            setPainRatioData(response)
        } else {
            await api.get('/profile/pain-ratio')
                .then(res => {
                    if (res.status === 200) {
                        setPainRatioData(res.data)
                    }
                })
        }
        setLoadingPainRatio(false)
    };

    const fetchLogNature = async () => {
        setLoadingLogNature(true)

        const setResponse = (response: any) => {
            setLogNature(
                Object.keys(response).map(code => ({
                    title: getNatureTitleByCode(code),
                    value: percentFormatter(response[code])
                }))
            )
        }

        if (isDev) {
            await sleep(800)
            setResponse(require("../../mocks/profile__getLogNature.json"))
        } else {
            await api.get('/profile/log-nature', {
                dateStart: getDateStart(),
                dateEnd: getDateEnd(),
            })
                .then(res => {
                    if (res.status === 200) {
                        setResponse(res.data)
                    }
                })
        }
        setLoadingLogNature(false)
    };

    const getPeriods = (dateStart: string, dateEnd: string, step: number) => {
        const startDate = moment(dateStart);
        const endDate = moment(dateEnd);

        const res = [];

        while (startDate.isBefore(endDate)) {
            const periodStart = moment(startDate)
            const periodEnd = moment(periodStart).add(step, 'days');
            res.push({
                start: periodStart,
                end: periodEnd,
            })
            startDate.add(step, 'days');
        }

        return res;
    }

    const fetchLogScore = async () => {
        setLoadingLogScore(true)

        const setResponse = (response: any) => {
            if (period === 'week') {
                setLogScore(response.data.map((el: any) => ({
                    date: moment(el.date).locale("ru").format("DD.MM"),
                    value: el.value
                })))
            } else if (
                period === 'month'
                || period === 'threeMonths'
            ) {
                const step = period === 'month' ? 4 : 14;
                const periods = getPeriods(
                    response.data[0].date,
                    response.data[response.data.length - 1].date,
                    step
                );

                const a = response.data.reduce((carry: any, current: any) => {
                    const dateMoment = moment(current.date)
                    let date;
                    for (let i = 0; i < periods.length; i++) {
                        if (dateMoment.isBetween(periods[i].start, periods[i].end)) {
                            date = `${periods[i].start.format("DD.MM")} - ${periods[i].end.format("DD.MM")}`;
                            break;
                        }
                    }
                    if (date === undefined) {
                        return carry;
                    }
                    if (date in carry) {
                        carry[date] = {
                            value: carry[date].value + current.value,
                            total: carry[date].total + 1,
                        };
                    } else {
                        carry[date] = {
                            value: current.value,
                            total: 1,
                        };
                    }

                    return carry
                }, {})
                setLogScore(Object.keys(a).map((key: any) => ({
                    date: key,
                    value: parseFloat((a[key].value / a[key].total).toFixed(2)),
                })))
            } else if (period === 'sixMonths') {
                const a = response.data.reduce((carry: any, current: any) => {
                    const date = moment(current.date).locale("ru").format("MMM");
                    if (date in carry) {
                        carry[date] = {
                            value: carry[date].value + current.value,
                            total: carry[date].total + 1,
                        };
                    } else {
                        carry[date] = {
                            value: current.value,
                            total: 1,
                        };
                    }

                    return carry
                }, {})
                setLogScore(Object.keys(a).map((key: any) => ({
                    date: key,
                    value: parseFloat((a[key].value / a[key].total).toFixed(2)),
                })))
            }
            setLogScoreValue(response.value ? parseFloat(response.value.toFixed(2)) : undefined)
            setLogScoreDiff(response.diff ? parseFloat(response.diff.toFixed(2)) : undefined)
        }

        if (isDev) {
            await sleep(1000)
            setResponse(require("../../mocks/profile__getLogScore.json"))
        } else {
            await api.get('/profile/log-scores', {
                dateStart: getDateStart(),
                dateEnd: getDateEnd()
            })
                .then(res => {
                    if (res.status === 200) {
                        setResponse(res.data)
                    }
                })
        }
        setLoadingLogScore(false)
    };

    const fetchLogCausesAndEffects = async () => {
        setLoadingLogCausesAndEffects(true)

        const setResponse = (response: any) => {
            setLogCausesAndEffects(
                Object.keys(response).map(code => ({
                    title: getCausesAndEffectTitleByCode(code),
                    value: percentFormatter(response[code])
                }))
            )
        }

        if (isDev) {
            await sleep(1000)
            setResponse(require("../../mocks/profile__getLogCausesAndEffects.json"))
        } else {
            await api.get('/profile/log-causes-and-effects', {
                dateStart: getDateStart(),
                dateEnd: getDateEnd(),
            })
                .then(res => {
                    if (res.status === 200) {
                        setResponse(res.data)
                    }
                })
        }
        setLoadingLogCausesAndEffects(false)
    }

    const fetchLogAdditionalSymptoms = async () => {
        setLoadingLogAdditionalSymptoms(true)

        const setResponse = (response: any) => {
            setLogAdditionalSymptoms(
                Object.keys(response).map(code => ({
                    title: getAdditionalSymptomTitleByCode(code),
                    value: percentFormatter(response[code])
                }))
            )
        }

        if (isDev) {
            await sleep(1000)
            setResponse(require("../../mocks/profile__getLogAdditionalSymptoms.json"))
        } else {
            await api.get('/profile/log-additional-symptoms', {
                dateStart: getDateStart(),
                dateEnd: getDateEnd(),
            })
                .then(res => {
                    if (res.status === 200) {
                        setResponse(res.data)
                    }
                })
        }

        setLoadingLogAdditionalSymptoms(false)
    }

    const fetchLogHelpers = async () => {
        setLoadingLogHelpers(true)

        const setResponse = (response: any) => {
            setLogHelpers(
                Object.keys(response).map(code => ({
                    title: getHelpersTitleByCode(code),
                    value: percentFormatter(response[code])
                }))
            )
        }

        if (isDev) {
            await sleep(1000)
            setResponse(require("../../mocks/profile__getLogHelpers.json"))
        } else {
            await api.get('/profile/log-helpers', {
                dateStart: getDateStart(),
                dateEnd: getDateEnd(),
            })
                .then(res => {
                    if (res.status === 200) {
                        setResponse(res.data)
                    }
                })
        }

        setLoadingLogHelpers(false)
    }

    const fetchLogDrugs = async () => {
        setLoadingLogDrugs(true)

        if (!isDev) {
            await api.get('/profile/log-drug-and-dosage', {
                dateStart: getDateStart(),
                dateEnd: getDateEnd(),
            })
                .then(res => {
                    if (res.status === 200) {
                        setLogDrugs(res.data)
                    }
                })
        }

        setLoadingLogDrugs(false)
    }

    const createPDF = () => {
        if (ref.current !== null) {
            const pdfWidth = (ref.current as HTMLElement).offsetWidth;
            const pdfHeight = (ref.current as HTMLElement).offsetHeight;
            html2canvas(ref.current)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF({
                        orientation: "p",
                        unit: "px",
                        format: [pdfWidth, pdfHeight]
                    });
                    // @ts-ignore
                    pdf.addImage(
                        imgData,
                        'PNG',
                        0,
                        0,
                        pdfWidth,
                        pdfHeight
                    );
                    pdf.save("my-medical-card.pdf");
                });
        }
    }

    useEffect(() => {
        fetchPainRatioData()
        setPeriod('week')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchLogNature()
        fetchLogScore()
        fetchLogAdditionalSymptoms()
        fetchLogCausesAndEffects()
        fetchLogHelpers()
        fetchLogDrugs()
    }, [period]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container
            maxWidth="xl"
            disableGutters={matches}
        >
            <TitleContainer>
                <Typography
                    variant="h2"
                >
                    {t("common.myMedCard")}
                </Typography>

                <BoxFlex
                    sx={{
                        gridGap: 24,
                    }}
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            minWidth: {
                                md: 300,
                            },
                            width: "100%",
                        }}
                    >
                        <InputLabel>
                            {t("common.period")}
                        </InputLabel>
                        <CSelect
                            value={period}
                            onChange={handleChangePeriod}
                            label={t("common.period")}
                        >
                            {Object.keys(periodVariants).map((val: string, index: number) => (
                                <MenuItem
                                    value={val}
                                    key={index}
                                >
                                    {periodVariants[val]}
                                </MenuItem>
                            ))}
                        </CSelect>
                    </FormControl>

                    <Button
                        variant="outlined"
                        size="large"
                        sx={{
                            flexShrink: 0,
                            padding: {
                                xs: "16px",
                            }
                        }}
                        onClick={createPDF}
                    >
                        <BoxFlex
                            sx={{
                                gridGap: 12,
                            }}
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: "none",
                                        md: "block",
                                    }
                                }}
                            >
                                {t("common.download")}
                            </Box>
                            <DocumentText/>
                        </BoxFlex>
                    </Button>
                </BoxFlex>
            </TitleContainer>

            <Box
                ref={ref}
                className={classes.parent}
            >
                <CPaper
                    className={classes.block1}
                >
                    <BoxFlexColumn
                        sx={{
                            gridGap: 12,
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                        >
                            {props.name}
                        </Typography>

                        <Table
                            className="pain-table"
                        >
                            <InfoRow
                                title={t("ProfilePage.age")}
                                value={props.age}
                            />
                            <InfoRow
                                title={t("common.height")}
                                value={props.height}
                            />
                            <InfoRow
                                title={t("common.weight")}
                                value={props.weight}
                            />
                            <InfoRow
                                title={t("common.gender")}
                                value={props.gender ? gender[props.gender] : null}
                            />
                        </Table>
                    </BoxFlexColumn>
                </CPaper>
                {loadingPainRatio ? (
                    <Skeleton
                        variant="rounded"
                        className={classes.block2}
                        height="16rem"
                    />
                ) : (
                    <CPaper
                        className={classes.block2}
                    >
                        <PainRatioBlock
                            values={painRatioData}
                        />
                    </CPaper>
                )}

                <PainSyndromeBlock/>

                {loadingLogNature ? (
                    <Skeleton
                        variant="rounded"
                        className={classes.block4}
                        height="32rem"
                    />
                ) : (
                    <CPaper
                        className={classes.block4}
                    >
                        <BoxFlexColumn
                            sx={{
                                gridGap: 20,
                                height: "100%",
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                            >
                                {t("ProfilePage.painNature")}
                            </Typography>

                            <PieChart
                                data={logNature}
                            />
                        </BoxFlexColumn>
                    </CPaper>
                )}

                {loadingLogScore ? (
                    <Skeleton
                        variant="rounded"
                        className={classes.block5}
                        height="32rem"
                    />
                ) : (
                    <CPaper
                        className={classes.block5}
                    >
                        <BoxFlexColumn
                            sx={{
                                gridGap: 20,
                            }}
                        >
                            <BoxFlex
                                sx={{
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                >
                                    {t("ProfilePage.painIntensity")}
                                </Typography>

                                <BoxFlex
                                    sx={{
                                        gridGap: 16,
                                    }}
                                >
                                    <PainScore
                                        value={logScoreValue}
                                    />
                                    <PainProgress
                                        value={logScoreDiff}
                                    />
                                </BoxFlex>
                            </BoxFlex>

                            <BarChart
                                title={t("ProfilePage.painIntensity")}
                                data={matches ? logScore.slice(-4) : logScore}
                            />

                            {loadingLogDrugs &&
                                <Skeleton
                                    variant="rounded"
                                    height="16rem"
                                />
                            }
                            {logDrugs &&
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                            >
                                                {t("common.date")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                            >
                                                {t("ProfilePage.drugAndDosage")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                            >
                                                {t("ProfilePage.isHelped")}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {logDrugs.map(data => {
                                        return (<TableRow>
                                                <TableCell>
                                                    <Typography
                                                        variant="body2"
                                                    >
                                                        {dateFormatter(data.date)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant="body2"
                                                    >
                                                        {data.value}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant="body2"
                                                    >
                                                        {data.isHelped ? t("common.yes") : t("common.no")}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </Table>
                            }
                        </BoxFlexColumn>
                    </CPaper>
                )}

                {loadingLogAdditionalSymptoms ? (
                    <Skeleton
                        variant="rounded"
                        className={classes.block6}
                        height="16rem"
                    />
                ) : (
                    <CPaper
                        className={classes.block6}
                    >
                        <ProgressesBlock
                            title={t("ProfilePage.associatedSymptoms")}
                            data={logAdditionalSymptoms}
                        />
                    </CPaper>
                )}

                {loadingLogCausesAndEffects ? (
                    <Skeleton
                        variant="rounded"
                        className={classes.block7}
                        height="16rem"
                    />
                ) : (
                    <CPaper
                        className={classes.block7}
                    >
                        <ProgressesBlock
                            title={t("ProfilePage.exacerbatingFactors")}
                            data={logCausesAndEffects}
                        />
                    </CPaper>
                )}

                {loadingLogHelpers ? (
                    <Skeleton
                        variant="rounded"
                        className={classes.block8}
                        height="16rem"
                    />
                ) : (
                    <CPaper
                        className={classes.block8}
                    >
                        <ProgressesBlock
                            title={t("ProfilePage.facilitatingFactors")}
                            data={logHelpers}
                        />
                    </CPaper>
                )}

                <NotesBlock
                    dateStart={getDateStart()}
                    dateEnd={getDateEnd()}
                />
            </Box>
        </Container>
    );
}

export default MyMedicalRecordBlock;
import React, {ReactNode, useState} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton, Link, Typography} from "@mui/material";
import theme from "../../theme";
import Xmark from "../icons/Xmark";
import {Trans, useTranslation} from "react-i18next";

export default function OfferDialog(props: {
    title: ReactNode
}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false)

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Link
                href="#"
                onClick={() => setOpen(true)}
            >
                {props.title}
            </Link>
            <Dialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4" align="center">
                        {t("OfferDialog.offerTitle")}
                    </Typography>
                    <Typography variant="h6">
                        {t("OfferDialog.offerP1")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.offerP2")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.offerP3")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.offerP4")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p1")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p11")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p111")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p112")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p113")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p114")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p115")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p2")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p21")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p22")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p23")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p3")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p31")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p311")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p312")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p313")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p4")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p41")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p42")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p5")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p51")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p52")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p53")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p54")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p6")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p61")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p62")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p63")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p7")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p71")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p72")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p73")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p74")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p75")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p8")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p81")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p82")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p83")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p84")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p85")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p86")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p87")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p9")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p91")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p92")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p93")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p94")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p95")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p96")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p10")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p101")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p102")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p103")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p104")}
                    </Typography>
                    <br/>

                    <Typography variant="h5">
                        {t("OfferDialog.p11_")}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {t("OfferDialog.p11_1")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p11_2")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.p11_3")}
                    </Typography>
                    <br/>

                    <Typography variant="body2">
                        {t("OfferDialog.contactsTitle")}
                    </Typography>

                    <Typography variant="body2">
                        <Trans i18nKey="OfferDialog.contacts" />
                    </Typography>
                    <br/>

                    <Typography variant="h5" align="center">
                        {t("OfferDialog.infoServiceTitle")}
                    </Typography>

                    <Typography variant="body2">
                        {t("OfferDialog.infoServiceP1")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.infoServiceP2")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.infoServiceP3")}
                    </Typography>
                    <Typography variant="body2">
                        <b>{t("OfferDialog.infoServiceQuestion1")}</b>
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.infoServiceAnswer1")}
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.infoServiceListTitle1")}
                        <ul style={{margin: 0}}>
                            <li>{t("OfferDialog.infoServiceListTitle1L1")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle1L2")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle1L3")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle1L4")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle1L5")}</li>
                        </ul>
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.infoServiceListTitle2")}
                        <ul style={{margin: 0}}>
                            <li>{t("OfferDialog.infoServiceListTitle2L1")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle2L2")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle2L3")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle2L4")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle2L5")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle2L6")}</li>
                        </ul>
                    </Typography>
                    <br/>

                    <Typography variant="body2">
                        <b>{t("OfferDialog.infoServiceQuestion2")}</b>
                    </Typography>
                    <Typography variant="body2">
                        {t("OfferDialog.infoServiceListTitle3")}

                        <ol style={{margin: 0}}>
                            <li>{t("OfferDialog.infoServiceListTitle3L1")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L2")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L3")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L4")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L5")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L6")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L7")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L8")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L9")}</li>
                            <li>{t("OfferDialog.infoServiceListTitle3L10")}</li>
                        </ol>
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
}
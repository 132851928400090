import React, {useState} from "react";
import {styled} from "@mui/system";
import BoxFlex from "../layout/BoxFlex";
import {Button, Dialog, DialogContent, Skeleton, Typography, typographyClasses} from "@mui/material";
import CChip from "../data-display/CChip";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import MobileOnly from "../layout/MobileOnly";
import FilterIcon from "../icons/Filter";
import {useTranslation} from "react-i18next";

const FilterContainer = styled(BoxFlex)({
    marginBottom: 50,
    "@media (max-width: 899px)": {
        display: "none",
    },
}) as typeof BoxFlex

const FilterItem = styled(BoxFlexColumn)({
    gridGap: 20,
    width: "100%",

    "& .MuiBox-root": {
        padding: "0 14px 0 14px",
    },
    "&:first-of-type": {
        "& > div": {
            paddingLeft: 0,
        },
        [`& .${typographyClasses.root}`]: {
            borderRadius: "30px 0 0 0",
        },
    },
    "&:last-of-type": {
        "& > div": {
            paddingRight: 0,
        },
        [`& .${typographyClasses.root}`]: {
            borderRadius: "0 30px 0 0",
        },
    },
    [`&:not(:last-of-type) .${typographyClasses.root}`]: {
        borderRight: "none",
    },
}) as typeof BoxFlex

const FilterTitle = styled(Typography)(({theme}) => ({
    padding: "20px 20px 0 20px",
    width: "100%",
    border: `1px solid ${theme.palette.border.main}`,
    borderBottom: "none",
})) as typeof Typography

const Filter = (props: {
    categoryTypes: {
        title: string,
        categories: {
            id: number,
            title: string,
        }[]
    }[],
    activeCategoryIds: number[],
    onClick: (val: number) => void,
    loading: boolean,
}) => {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const handleOpen = () => setOpenDialog(true)
    const handleClose = () => setOpenDialog(false)

    return (
        <>
            {props.loading ? (
                <Skeleton height="12rem" />
            ) : (
                <>
                    <MobileOnly>
                        <Button
                            variant="outlined"
                            onClick={handleOpen}
                            fullWidth
                            endIcon={
                                <FilterIcon />
                            }
                            sx={{
                                marginBottom: "32px",
                            }}
                        >
                            {t("common.filter")}
                        </Button>
                        <Dialog
                            onClose={handleClose}
                            open={openDialog}
                        >
                            <DialogContent>
                                <BoxFlexColumn
                                    sx={{
                                        gridGap: 20,
                                    }}
                                >
                                    {props.categoryTypes.map((categoryType, i) => (
                                        <BoxFlexColumn
                                            key={i}
                                            sx={{
                                                alignItems: "center",
                                                gridGap: 16,
                                            }}
                                        >
                                            <Typography
                                                variant="captionUppercase"
                                                color="text.tertiary"
                                            >
                                                {categoryType.title}
                                            </Typography>

                                            <BoxFlexColumn
                                                sx={{
                                                    gridGap: 12,
                                                    width: "100%",
                                                }}
                                            >
                                                {categoryType.categories.map((category, k) => (
                                                    <CChip
                                                        key={k}
                                                        label={category.title}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            props.onClick(category.id)
                                                            handleClose()
                                                        }}
                                                        active={props.activeCategoryIds.includes(category.id)}
                                                    />
                                                ))}
                                            </BoxFlexColumn>
                                        </BoxFlexColumn>
                                    ))}
                                </BoxFlexColumn>
                            </DialogContent>
                        </Dialog>
                    </MobileOnly>

                    <FilterContainer>
                        {props.categoryTypes.map((filterGroup, i) => (
                            <FilterItem
                                key={i}
                            >
                                <FilterTitle
                                    variant="captionUppercase"
                                    color="text.tertiary"
                                >
                                    {filterGroup.title}
                                </FilterTitle>

                                <BoxFlex
                                    sx={{
                                        gridGap: 5,
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {filterGroup.categories.map((category, k) => (
                                        <CChip
                                            key={k}
                                            label={category.title}
                                            variant="outlined"
                                            onClick={() => props.onClick(category.id)}
                                            active={props.activeCategoryIds.includes(category.id)}
                                        />
                                    ))}
                                </BoxFlex>
                            </FilterItem>
                        ))}
                    </FilterContainer>
                </>
            )}
        </>
    );
}

export default Filter;
import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Container, Dialog, DialogContent, DialogTitle,
    FormControl,
    FormControlLabel,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    SelectChangeEvent, Skeleton,
    TextField,
    Typography, useMediaQuery
} from "@mui/material";
import CPaper from "../surfaces/CPaper";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import BoxFlex from "../layout/BoxFlex";
import CSelect from "../inputs/CSelect";
import {styled} from "@mui/system";
import {gender as genderVariants} from "../../types/gender";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import PhoneInput from "../inputs/PhoneInput";
import PassInput from "../inputs/PassInput";
import {useError} from "../../context/ErrorHandler";
import Xmark from "../icons/Xmark";
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import {Moment} from "moment";
import CDatePicker from "../inputs/CDatePicker";
import moment from "moment/moment";

const ButtonWrapper = styled(BoxFlex)({
    justifyContent: "end",
    gridGap: 20,
    margin: "42px 0",

    "@media (max-width: 899px)": {
        flexDirection: "column",
        marginTop: 12,
    },
}) as typeof BoxFlex

const SettingsBlock = () => {
    const { t } = useTranslation();
    const { setError } = useError();
    const [phone, setPhone] = useState<string>("")
    const [email, setEmail] = useState<string>('')
    const [disableEmail, setDisableEmail] = useState<boolean>(true)
    const [whatsAppNotification, setWhatsAppNotification] = useState<boolean>(false)
    const [telegramNotification, setTelegramNotification] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [surname, setSurname] = useState<string>('')
    const [patronymic, setPatronymic] = useState<string>('')
    const [dateOfBirth, setDateOfBirth] = useState<Moment | null>(null)
    const [gender, setGender] = useState<keyof typeof genderVariants>('')
    const [height, setHeight] = useState<string>('')
    const [weight, setWeight] = useState<string>('')
    const [disabledBtn, setDisabledBtn] = useState<boolean>(true)
    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [newPasswordError, setNewPasswordError] = useState<string>('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('')
    const [newPasswordConfirmError, setNewPasswordConfirmError] = useState<string>('')
    const [openPasswordDialogSuccess, setOpenPasswordDialogSuccess] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const handleChangeGender = (event: SelectChangeEvent<HTMLInputElement | unknown>): void => {
        setGender(event.target.value as string);
    }

    const handleChangeHeight = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const regex = /^[0-9]*[.,]?[0-9]*$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setHeight(e.target.value);
        }
    }

    const handleChangeWeight = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const regex = /^[0-9]*[.,]?[0-9]*$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setWeight(e.target.value);
        }
    }

    const fetchData = async () => {
        setLoading(true)
        setDisabledBtn(true)
        if (isDev) {
            await sleep(500)
            const response = require("../../mocks/profile__getPersonalData.json")
            setPhone(response.phone)
            setEmail(response.email)
            setWhatsAppNotification(response.whatsAppNotification)
            setTelegramNotification(response.telegramNotification)
            setName(response.name)
            setSurname(response.surname ?? '')
            setPatronymic(response.patronymic ?? '')
            setDateOfBirth(response.dateOfBirth)
            setWeight(response.weight ?? '')
            setHeight(response.height ?? '')
            setGender(response.gender)
        } else {
            await api.get(`/profile/personal-data`)
                .then(res => {
                    if (res.status === 200) {
                        setPhone(res.data.phone)
                        setEmail(res.data.email)
                        setDisableEmail(res.data.email && res.data.email.length > 0)
                        setWhatsAppNotification(res.data.whatsAppNotification)
                        setTelegramNotification(res.data.telegramNotification)
                        setName(res.data.name)
                        setSurname(res.data.surname ?? '')
                        setPatronymic(res.data.patronymic ?? '')
                        setDateOfBirth(res.data.dateOfBirth)
                        setWeight(res.data.weight ?? '')
                        setHeight(res.data.height ?? '')
                        setGender(res.data.gender)
                    }
                })
        }
        setLoading(false)
        setDisabledBtn(false)
    }

    const submit = () => {
        setDisabledBtn(true)

        api.patch('/profile/personal-data', {
            email,
            whatsAppNotification,
            telegramNotification,
            name,
            surname,
            patronymic,
            dateOfBirth: dateOfBirth ? dateOfBirth.format('Y-MM-DD') : null,
            weight,
            height,
            gender
        })
            .then(res => {
                if (res.status === 200) {
                    fetchData()
                }
            })
            .catch(err => setError(err.response.data.message))
            .finally(() => setDisabledBtn(false))
    }

    const changePasswordSubmit = () => {
        setDisabledBtn(true)

        api.patch('/profile/change-password', {
            currentPassword: currentPassword,
            newPassword: newPassword,
        })
            .then(res => {
                if (res.status === 200) {
                    setOpenPasswordDialogSuccess(true)
                    setCurrentPassword('')
                    setNewPassword('')
                    setNewPasswordConfirm('')
                }
            })
            .catch(err => setError(err.response.data.message))
            .finally(() => setDisabledBtn(false))
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setNewPasswordError(newPassword && (newPassword.length < 5 || newPassword.length > 255)  ? t("pwd.passwordLengthErr") : '')
    }, [newPassword])

    useEffect(() => {
        setNewPasswordConfirmError(newPasswordConfirm && newPasswordConfirm !== newPassword ? t("pwd.passwordMismatchErr") : '')
    }, [newPassword, newPasswordConfirm])

    return (
        <Container maxWidth="xl">
            <BoxFlexColumn
                sx={{
                    maxWidth: 640,
                    gridGap: 28,
                    margin: "0 auto",
                }}
            >
                {useMediaQuery('(max-width:899px)') &&
                    <Typography
                        variant="h2"
                    >
                        {t("common.settings")}
                    </Typography>
                }
                {loading ? (
                    <>
                        <Skeleton height="350px"/>
                        <Skeleton height="350px"/>
                    </>
                ) : (
                    <>
                        <CPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 28,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("SettingsBlock.contacts")}
                                </Typography>

                                <PhoneInput
                                    disabled={true}
                                    value={phone}
                                    setValue={setPhone}
                                />

                                <TextField
                                    label="Email"
                                    variant="standard"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                    fullWidth
                                    InputProps={{
                                        readOnly: disableEmail
                                    }}
                                    disabled={disableEmail}
                                />

                                <BoxFlexColumn>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={whatsAppNotification}
                                                onChange={e => setWhatsAppNotification(e.target.checked)}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                            >
                                                {t("SettingsBlock.whatsappNotification")}
                                            </Typography>
                                        }
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={telegramNotification}
                                                onChange={e => setTelegramNotification(e.target.checked)}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                            >
                                                {t("SettingsBlock.tgNotification")}
                                            </Typography>
                                        }
                                    />
                                </BoxFlexColumn>
                            </BoxFlexColumn>
                        </CPaper>

                        <CPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 34,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("SettingsBlock.personalData")}
                                </Typography>

                                <BoxFlexColumn
                                    sx={{
                                        gridGap: 20,
                                    }}
                                >
                                    <TextField
                                        label={t("common.surname")}
                                        variant="standard"
                                        onChange={(e) => setSurname(e.target.value)}
                                        value={surname}
                                        required
                                    />

                                    <TextField
                                        label={t("common.name")}
                                        variant="standard"
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        required
                                    />

                                    <TextField
                                        label={t("common.patronymic")}
                                        variant="standard"
                                        onChange={(e) => setPatronymic(e.target.value)}
                                        value={patronymic}
                                        required
                                    />

                                    <Grid
                                        container
                                        spacing={"20px"}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <CDatePicker
                                                label={t("common.dateOfBirth")}
                                                onChange={setDateOfBirth}
                                                value={dateOfBirth}
                                                maxDate={moment()}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                            >
                                                <InputLabel>
                                                    {t("common.gender")}
                                                </InputLabel>
                                                <CSelect
                                                    value={gender}
                                                    onChange={handleChangeGender}
                                                    label={t("common.gender")}
                                                >
                                                    {Object.keys(genderVariants).map((val: string, i: number) => (
                                                        <MenuItem
                                                            value={val}
                                                            key={i}
                                                        >
                                                            {genderVariants[val]}
                                                        </MenuItem>
                                                    ))}
                                                </CSelect>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={"20px"}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <TextField
                                                label={t("common.height")}
                                                variant="standard"
                                                onChange={handleChangeHeight}
                                                value={height}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <TextField
                                                label={t("common.weight")}
                                                variant="standard"
                                                onChange={handleChangeWeight}
                                                value={weight}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                </BoxFlexColumn>
                            </BoxFlexColumn>
                        </CPaper>

                        <ButtonWrapper>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={fetchData}
                                disabled={disabledBtn}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{
                                    order: {
                                        xs: -1,
                                        md: 0,
                                    }
                                }}
                                disabled={disabledBtn}
                                onClick={submit}
                            >
                                {t("common.save")}
                            </Button>
                        </ButtonWrapper>
                    </>
                )}


                <BoxFlexColumn>
                    <CPaper>
                        <BoxFlexColumn
                            sx={{
                                gridGap: 34,
                            }}
                        >
                            <Typography
                                variant="h3"
                            >
                                {t("pwd.pwd")}
                            </Typography>

                            <BoxFlexColumn
                                sx={{
                                    gridGap: 20,
                                }}
                            >
                                <PassInput
                                    label={t("pwd.oldPwd")}
                                    value={currentPassword}
                                    setValue={setCurrentPassword}
                                    disabled={loading}
                                />
                                <PassInput
                                    label={t("pwd.newPwd")}
                                    value={newPassword}
                                    setValue={setNewPassword}
                                    disabled={loading}
                                    errorText={newPasswordError}
                                />
                                <PassInput
                                    label={t("pwd.confirmNewPwd")}
                                    value={newPasswordConfirm}
                                    setValue={setNewPasswordConfirm}
                                    disabled={loading}
                                    errorText={newPasswordConfirmError}
                                />
                            </BoxFlexColumn>
                        </BoxFlexColumn>
                    </CPaper>

                    <ButtonWrapper>
                        <Button
                            variant="contained"
                            size="large"
                            disabled={disabledBtn || newPasswordError.length !== 0 || newPasswordConfirmError.length !== 0}
                            onClick={changePasswordSubmit}
                        >
                            {t("common.save")}
                        </Button>
                    </ButtonWrapper>
                </BoxFlexColumn>

            </BoxFlexColumn>

            <Dialog
                onClose={() => setOpenPasswordDialogSuccess(false)}
                open={openPasswordDialogSuccess}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenPasswordDialogSuccess(false)}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("SettingsBlock.successUpdatePwdMsg")}
                    </Typography>
                </DialogContent>
            </Dialog>
        </Container>
    );
}

export default SettingsBlock;
import axios from "axios";
import {baseURL} from "../utils";
import {getFromStorage} from "../localStorageUtils";

const getToken = () => getFromStorage("authToken");

const defaultOptions = {
    baseURL,
    reqErrorFunc: (error: any) => {
        return Promise.reject(error);
    },
    reqHandleFunc: (config: any) => {
        const token = getToken();
        token && (config.headers.Authorization = `Bearer ${token}`);

        return config;
    },
    resHandleFunc: (response: any) => response,
    resErrorFunc: (error: any) => {
        return Promise.reject(error);
    },
};

const initOptions = {
    ...defaultOptions,
};

const service = axios.create(initOptions);

service.interceptors.request.use(
    (config) => initOptions.reqHandleFunc(config),
    (error) => initOptions.reqErrorFunc(error)
);

service.interceptors.response.use(
    (response: any) => initOptions.resHandleFunc(response),
    (error: any) => initOptions.resErrorFunc(error)
);

const action = (opt: object) => {
    return service(opt);
};

const api = {
    get: (url: string, data?: object, options = {}) =>
        action({
            ...options,
            ...{
                method: "get",
                url,
                params: data,
            },
        }),
    post: (url: string, data?: object, options = {}) =>
        action({
            ...options,
            ...{
                method: "post",
                url,
                data,
            },
        }),
    patch: (url: string, data?: object, options = {}) =>
        action({
            ...options,
            ...{
                method: "patch",
                url,
                data,
            },
        }),
    delete: (url: string, data?: object, options = {}) =>
        action({
            ...options,
            ...{
                method: "delete",
                url,
                data,
            },
        }),
};

export default api;
import React from "react";
import {styled} from "@mui/system";
import BoxFlex from "../layout/BoxFlex";
import MyCardImage from "../../img/poster-about-my-card.svg";
import IndividualPlanImage from "../../img/poster-about-individual-plan.svg";
import iPhoneImage from "../../img/iPhone.svg";
import {Button, Container, Typography} from "@mui/material";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import ArrowUpRight from "../icons/ArrowUpRight";
import {tgBot} from "../../utils";
import {Trans, useTranslation} from "react-i18next";

const PosterBox = styled(BoxFlex)(({theme}) => ({
    backgroundPosition: "center",
    background: theme.palette.gradient.main,
    borderRadius: 30,
    maxHeight: 728,
    alignItems: "center",
    padding: "40px 45px",

    "@media (max-width: 899px)": {
        padding: "15px 20px",
        maxHeight: "unset",
    },
})) as typeof BoxFlex;

const Wrapper = styled(BoxFlex)({
    height: "100%",
    gridGap: "3rem",
    "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    "@media (max-width: 899px)": {
        flexDirection: "column",
    },
}) as typeof BoxFlex;

const ItemWrapper = styled(BoxFlexColumn)({
    textAlign: "center",
}) as typeof BoxFlexColumn

const PosterAboutBlock = () => {
    const { t } = useTranslation();
    const handleClick = () => {
        window.open(tgBot, '_blank');
    }

    return (
        <Container maxWidth="xl">
            <PosterBox>
                <Wrapper>
                    <ItemWrapper>
                        <Typography
                            variant="h4"
                            sx={{
                                marginBottom: "2rem",
                            }}
                        >
                            {t("common.myMedCard")}
                        </Typography>

                        <img
                            src={MyCardImage}
                            alt={t("common.myMedCard")}
                        />
                    </ItemWrapper>
                    <ItemWrapper
                        sx={{
                            width: "calc(33% - 6rem)",
                            flexShrink: 0,
                            alignItems: 'center',
                            marginTop: '4rem',
                            maxHeight: '100%',
                            gridGap: '2rem',

                            "@media (max-width: 899px)": {
                                width: "100%",
                                marginTop: "unset",
                                order: 1,
                            },
                        }}
                    >
                        <img
                            style={{
                                maxWidth: "58%",
                            }}
                            src={iPhoneImage}
                            alt="iPhone"
                        />

                        <Typography
                            variant="h4"
                        >
                            <Trans i18nKey="common.painDiaryTitle" />
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            <BoxFlex
                                alignItems="center"
                                sx={{
                                    gridGap: 8,
                                }}
                            >
                                {t("common.tgBot")}

                                <ArrowUpRight/>
                            </BoxFlex>
                        </Button>
                    </ItemWrapper>
                    <ItemWrapper>
                        <Typography
                            variant="h4"
                            sx={{
                                marginBottom: "2rem",
                            }}
                        >
                            {t("common.individualPlan")}
                        </Typography>

                        <img
                            src={IndividualPlanImage}
                            alt={t("common.individualPlan")}
                        />
                    </ItemWrapper>
                </Wrapper>
            </PosterBox>
        </Container>
    );
}

export default PosterAboutBlock;
import {Link, Typography} from "@mui/material";
import CPaper from "../surfaces/CPaper";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import CAvatar from "../data-display/CAvatar";
import React from "react";
import {styled} from "@mui/system";
import routes from "../../routes";
import {useTranslation} from "react-i18next";

const CourseLeaderInfoBox = styled(BoxFlexColumn)(({theme}) => ({
    padding: "24px 0 32px",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    gridGap: "32px",

    "&:last-of-type": {
        border: "none",
    }
})) as typeof BoxFlexColumn

export default function CourseSpecialist(props: {
    id: number,
    name: string,
    position: string,
    avatar?: string,
    education?: string,
    placeOfWork?: string,
    specialization?: string,
}) {
    const { t } = useTranslation();
    return (
        <BoxFlexColumn
            sx={{
                marginBottom: "50px",

                "@media (max-width: 899px)": {
                    marginBottom: "39px",
                }
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    marginBottom: "51px",

                    "@media (max-width: 899px)": {
                        marginBottom: "28px",
                    }
                }}
            >
                {t("CourseSpecialist.courseLeader")}
            </Typography>

            <CPaper>
                <BoxFlexColumn
                    sx={{
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <Link
                        href={routes.singleSpecialist.replace(':id', String(props.id))}
                    >
                        <BoxFlexColumn
                            sx={{
                                alignItems: "center",
                                gridGap: 28,

                                "@media (max-width: 899px)": {
                                    width: 24,
                                }
                            }}
                        >
                            <CAvatar
                                src={props.avatar ?? undefined}
                                alt={props.name}
                                size="xl"
                            />

                            <Typography
                                variant="h4"
                            >
                                {props.name}
                            </Typography>
                            <Typography
                                variant="captionUppercase"
                                color="primary"
                            >
                                {props.position}
                            </Typography>
                        </BoxFlexColumn>
                    </Link>

                    {props.education &&
                        <CourseLeaderInfoBox>
                            <Typography
                                variant="subtitle1"
                            >
                                {t("CourseSpecialist.education")}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                dangerouslySetInnerHTML={{__html: props.education}}
                            />
                        </CourseLeaderInfoBox>
                    }

                    {props.placeOfWork &&
                        <CourseLeaderInfoBox>
                            <Typography
                                variant="subtitle1"
                            >
                                {t("CourseSpecialist.placeOfWork")}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                dangerouslySetInnerHTML={{__html: props.placeOfWork}}
                            />
                        </CourseLeaderInfoBox>
                    }

                    {props.specialization &&
                        <CourseLeaderInfoBox>
                            <Typography
                                variant="subtitle1"
                            >
                                {t("CourseSpecialist.specialization")}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                dangerouslySetInnerHTML={{__html: props.specialization}}
                            />
                        </CourseLeaderInfoBox>
                    }
                </BoxFlexColumn>
            </CPaper>
        </BoxFlexColumn>
    )
}

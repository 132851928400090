import React, {useContext} from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import bg from "../img/home-hero-bg.png";
import {Box, Button, Link, Typography} from "@mui/material";
import YourPainBlock from "../components/blocks/YourPainBlock";
import HowToWorksBlock from "../components/blocks/HowToWorksBlock";
import OurPartnersBlock from "../components/blocks/OurPartnersBlock";
import SpecialistsBlock from "../components/blocks/SpecialistsBlock";
import ControlPainBlock from "../components/blocks/ControlPainBlock";
import theme from "../theme";
import routes from "../routes";
import PosterBlock from "../components/blocks/PosterBlock";
import FAQBlock from "../components/blocks/FAQBlock";
import ReviewsBlock from "../components/blocks/ReviewsBlock";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import {AuthContext} from "../context/AuthProvider";
import {styled} from "@mui/system";
import BoxFlex from "../components/layout/BoxFlex";
import MyCardImage from "../img/poster-about-my-card.svg";
import iPhoneImage from "../img/iPhone.svg";
import ArrowUpRight from "../components/icons/ArrowUpRight";
import IndividualPlanImage from "../img/poster-about-individual-plan.svg";
import {tgBot} from "../utils";
import {Trans, useTranslation} from "react-i18next";

const Wrapper = styled(BoxFlex)({
    height: "100%",
    gridGap: "3rem",
    "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    "@media (max-width: 899px)": {
        flexDirection: "column",
    },
}) as typeof BoxFlex;

const ItemWrapper = styled(BoxFlexColumn)({
    textAlign: "center",
    width: "calc(33%)",
    "@media (max-width: 899px)": {
        width: "100%",
    },
}) as typeof BoxFlexColumn


const HomePage = () => {
    const {isAuthenticated} = useContext(AuthContext);
    const { t } = useTranslation();

    return (
        <DefaultTemplate>
            <HeroBlock
                title={<Trans i18nKey="HomePage.title" />}
                background={`
                    url("${bg}") top center no-repeat,
                    ${theme.palette.gradient.main} center top no-repeat
                `}
                containerMaxWidth={false}
            >
                <BoxFlexColumn>
                    <Box
                        sx={{
                            mt: "40px",
                        }}
                    >
                        <Typography
                            variant="body1"
                            color="text.primary"
                        >
                            <Trans i18nKey="HomePage.subtitle" />
                        </Typography>
                        {!isAuthenticated &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{
                                    mt: "60px",
                                    mb: {
                                        md: "9px",
                                        xs: "31px",
                                    },
                                }}
                                component={Link}
                                href={routes.test}
                            >
                                {t("common.takeTest")}
                            </Button>
                        }
                    </Box>

                    <Wrapper>
                        <ItemWrapper>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginBottom: "2rem",
                                }}
                            >
                                {t("common.myMedCard")}
                            </Typography>

                            <img
                                src={MyCardImage}
                                alt={t("common.myMedCard")}
                            />
                        </ItemWrapper>
                        <ItemWrapper
                            sx={{
                                width: "calc(33% - 6rem)",
                                flexShrink: 0,
                                alignItems: 'center',
                                marginTop: '4rem',
                                maxHeight: '100%',
                                gridGap: '2rem',

                                "@media (max-width: 899px)": {
                                    width: "100%",
                                    marginTop: "unset",
                                    order: 1,
                                },
                            }}
                        >
                            <img
                                style={{
                                    maxWidth: "58%",
                                }}
                                src={iPhoneImage}
                                alt="iPhone"
                            />

                            <Typography
                                variant="h3"
                            >
                                <Trans i18nKey="common.painDiaryTitle" />
                            </Typography>

                        </ItemWrapper>
                        <ItemWrapper>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginBottom: "2rem",
                                }}
                            >
                                {t("common.individualPlan")}
                            </Typography>

                            <img
                                src={IndividualPlanImage}
                                alt={t("common.individualPlan")}
                            />
                        </ItemWrapper>
                    </Wrapper>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => window.open(tgBot, '_blank')}
                        sx={{
                            margin: "35px auto 0",
                        }}
                    >
                        <BoxFlex
                            alignItems="center"
                            sx={{
                                gridGap: 8,
                            }}
                        >
                            {t("common.tgBot")}
                            <ArrowUpRight/>
                        </BoxFlex>
                    </Button>
                </BoxFlexColumn>
            </HeroBlock>

            <HowToWorksBlock/>

            <ControlPainBlock/>

            <YourPainBlock/>

            <SpecialistsBlock/>

            <ReviewsBlock />

            <PosterBlock
                background={`
                    url("${bg}") center no-repeat,
                    ${theme.palette.gradient.main} center no-repeat
                `}
            >
                <BoxFlexColumn
                    sx={{
                        alignItems: "center",
                        margin: "0 auto",
                    }}
                >
                    <Typography
                        variant="h2"
                        align="center"
                    >
                        <Trans i18nKey="HomePage.text" />
                    </Typography>
                    {!isAuthenticated &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{
                                mt: "48px",
                            }}
                            component={Link}
                            href={routes.test}
                        >
                            {t("common.takeTest")}
                        </Button>
                    }
                </BoxFlexColumn>
            </PosterBlock>

            <FAQBlock />

            <OurPartnersBlock/>
        </DefaultTemplate>
    );
};

export default HomePage;

import React, {useEffect, useState} from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import {
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import LinkList from "../components/data-display/LinkList";
import ArrowUpRight from "../components/icons/ArrowUpRight";
import theme from "../theme";
import CPaper from "../components/surfaces/CPaper";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import BoxFlex from "../components/layout/BoxFlex";
import {styled} from "@mui/system";
import {getSocialLinkNameByKey, isDev, sleep} from "../utils";
import api from "../api/api";
import Xmark from "../components/icons/Xmark";
import PersonalDataProcessingDialog from "../components/dialogs/PersonalDataProcessingDialog";
import {useTranslation} from "react-i18next";

const BottomContent = styled(BoxFlex)({
    alignItems: "center",
    justifyContent: "space-between",
    gridGap: 25,

    "@media (max-width: 899px)": {
        flexDirection: "column",

        "& button": {
            order: -1,
            width: "100%",
        },
    },
}) as typeof BoxFlex

const ContactsPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [message, setMessage] = useState('');
    const [messageErr, setMessageErr] = useState('');
    const [socialLinks, setSocialLinks] = useState<{ title: string, url: string }[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [dialogContent, setDialogContent] = useState<string>('')

    const fetchSocialLinks = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../mocks/socialLinks__getData.json")

            Object.keys(response).forEach(key => {
                setSocialLinks(prevState => {
                    prevState.push({
                        title: getSocialLinkNameByKey(key),
                        url: response[key]
                    });
                    return prevState;
                })
            })
        } else {
            await api.get(`/social-links`)
                .then(res => {
                    if (res.status === 200) {
                        Object.keys(res.data).forEach(key => {
                            setSocialLinks(prevState => {
                                prevState.push({
                                    title: getSocialLinkNameByKey(key),
                                    url: res.data[key]
                                });
                                return prevState;
                            })
                        })
                    }
                })
        }
        setLoading(false)
    }

    const handleChangeEmail = (val: string) => {
        setEmailErr('')
        setEmail(val)
    }

    const handleChangeMessage = (val: string) => {
        setMessageErr('')
        setMessage(val)
    }

    const submitMessage = () => {
        setDisabledBtn(true)
        let noError = true
        if (message.length === 0) {
            noError = false
            setMessageErr(t("ContactsPage.msgEmptyErr"))
        }

        if (!(/\S+@\S+\.\S+/.test(email))) {
            noError = false
            setEmailErr(t("ContactsPage.emailErr"))
        }

        if (noError) {
            api.post(`/feedback`, {
                email,
                message,
            })
                .then(res => {
                    if (res.status === 200) {
                        setDialogContent(t("ContactsPage.successMsg"))
                        setOpenDialog(true)
                    }
                })
                .catch(err => {
                    setDialogContent(err.response.data.message)
                    setOpenDialog(true)
                })
                .finally(() => setDisabledBtn(false))
        }
    }

    useEffect(() => {
        fetchSocialLinks()
    }, [])

    useEffect(() => {
        setDisabledBtn(messageErr.length > 0 || emailErr.length > 0)
    }, [messageErr, emailErr])

    return (
        <DefaultTemplate>
            <HeroBlock
                title={t("ContactsPage.title")}
            />

            <Container
                maxWidth="xl"
            >
                <Grid
                    container
                    spacing={16.5}
                >
                    <Grid
                        item
                        xs={12}
                        md={5.5}
                    >
                        {loading
                            ? (
                                <>
                                    <Skeleton height="5rem"/>
                                    <Skeleton height="5rem"/>
                                    <Skeleton height="5rem"/>
                                </>
                            )
                            : <LinkList
                                title={t("ContactsPage.socialLinkTitle")}
                                items={socialLinks}
                                icon={<ArrowUpRight
                                    color={theme.palette.text.tertiary}
                                />}
                                isHorizontal={true}
                                isExternal={true}
                            />
                        }

                        <BoxFlexColumn
                            sx={{
                                maxWidth: "350px",
                                marginTop: "72px",
                                "@media (max-width: 899px)": {
                                    marginTop: "48px",
                                },
                            }}
                        >
                            <Typography
                                color="text.primary"
                                variant="body2"
                            >
                                Pain Point LLC
                            </Typography>
                            <Typography
                                color="text.primary"
                                variant="body2"
                            >
                                E-mail: <a href="mailto:painpoint.pp@gmail.com">painpoint.pp@gmail.com</a>
                            </Typography>
                        </BoxFlexColumn>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6.5}
                    >
                        <CPaper>
                            <BoxFlexColumn>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        marginBottom: "36px",
                                    }}
                                >
                                    {t("ContactsPage.writeToUs")}
                                </Typography>

                                <TextField
                                    label="Email"
                                    variant="standard"
                                    onChange={(e) => handleChangeEmail(e.target.value)}
                                    value={email}
                                    type="email"
                                    required
                                    error={emailErr.length !== 0}
                                    helperText={emailErr}
                                    sx={{
                                        marginBottom: "40px",
                                    }}
                                />

                                <TextField
                                    label={t("ContactsPage.msg")}
                                    multiline
                                    rows={4}
                                    variant="standard"
                                    onChange={(e) => handleChangeMessage(e.target.value)}
                                    value={message}
                                    required
                                    error={messageErr.length !== 0}
                                    helperText={messageErr}
                                    sx={{
                                        marginBottom: "40px",
                                    }}
                                />

                                <BottomContent>
                                    <Typography
                                        variant="body3"
                                    >
                                        {t("common.personalDataSendMsg")} <PersonalDataProcessingDialog/>
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{
                                            flexShrink: 0,
                                        }}
                                        onClick={submitMessage}
                                        disabled={disabledBtn}
                                    >
                                        {t("common.send")}
                                    </Button>
                                </BottomContent>
                            </BoxFlexColumn>
                        </CPaper>
                    </Grid>
                </Grid>
            </Container>

            <Dialog
                onClose={() => setOpenDialog(false)}
                open={openDialog}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
            </Dialog>
        </DefaultTemplate>
    );
};

export default ContactsPage;

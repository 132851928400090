import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Link,
    Typography
} from "@mui/material";
import theme from "../../theme";
import Xmark from "../icons/Xmark";
import BoxList from "../layout/BoxList";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import OfferDialog from "./OfferDialog";
import {useTranslation} from "react-i18next";

const DoctorOpinionDialog = (props: {
    openExternal: boolean,
    handleChangeOpenExternal: (open: boolean) => void,
    btnCallback?: () => void,
}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false)
    const [isAgree, setIsAgree] = useState<boolean>(false)

    useEffect(() => {
        setOpen(props.openExternal)
    }, [props.openExternal])

    const handleClose = () => {
        setOpen(false);
        props.handleChangeOpenExternal(false);
    };
    return (
        <Dialog
            onClose={handleClose}
            open={open}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 6,
                        top: 6,
                    }}
                >
                    <Xmark
                        color={theme.palette.text.tertiary}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    paddingBottom: 0,
                }}
            >
                <BoxFlexColumn>
                    <Typography
                        variant="h4"
                    >
                        {t("DoctorOpinionDialog.title")}
                    </Typography>

                    <BoxList>
                        <li>
                            <Typography
                                variant="body2"
                            >
                                {t("DoctorOpinionDialog.p1")}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body2"
                            >
                                {t("DoctorOpinionDialog.p2")}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body2"
                            >
                                {t("DoctorOpinionDialog.p3")}
                            </Typography>
                        </li>
                    </BoxList>

                    <Typography
                        variant="subtitle2"
                    >
                        {t("DoctorOpinionDialog.subtitle")}
                    </Typography>

                    <BoxList>
                        <li>
                            <Typography
                                variant="body2"
                            >
                                {t("DoctorOpinionDialog.p4")}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body2"
                            >
                                {t("DoctorOpinionDialog.p5")}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body2"
                            >
                                {t("DoctorOpinionDialog.p6")}
                            </Typography>
                        </li>
                    </BoxList>
                </BoxFlexColumn>
            </DialogContent>

            <DialogActions
                disableSpacing={true}
                sx={{
                    padding: "0 24px 38px"
                }}
            >
                {props.btnCallback &&
                    <BoxFlexColumn
                        alignItems="center"
                        sx={{
                            gridGap: 7,
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAgree}
                                    onChange={e => setIsAgree(e.target.checked)}
                                />
                            }
                            sx={{
                                margin: 0,
                            }}
                            labelPlacement="start"
                            label={
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    «Doctor’s opinion» is not a medical service <br/>
                                    By clicking on the button, I agree that I have read <OfferDialog
                                        title={<Link href="#">the offer</Link>}
                                    />
                                </Typography>
                            }
                        />

                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={props.btnCallback}
                            disabled={!isAgree}
                        >
                            {t("common.continue")}
                        </Button>
                    </BoxFlexColumn>
                }
            </DialogActions>
        </Dialog>
    );
}

export default DoctorOpinionDialog;
import React, {useContext, useEffect, useState} from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import {styled} from "@mui/system";
import {Box, Button, Container, Skeleton, Typography, useMediaQuery} from "@mui/material";
import Identification from "../components/icons/Identification";
import UserGroup from "../components/icons/UserGroup";
import Gear from "../components/icons/Gear";
import Grid2 from "../components/icons/Grid2";
import BoxFlex from "../components/layout/BoxFlex";
import theme from "../theme";
import classNames from "classnames";
import SettingsBlock from "../components/blocks/SettingsBlock";
import MyMedicalRecordBlock from "../components/blocks/MyMedicalRecordBlock";
import RecordingsBlock from "../components/blocks/RecordingsBlock";
import IndividualPlanBlock from "../components/blocks/IndividualPlanBlock";
import ArrowRightOnRectangle from "../components/icons/ArrowRightOnRectangle";
import CIconButton from "../components/inputs/CIconButton";
import ArrowSmallLeft from "../components/icons/ArrowSmallLeft";
import {isDev, sleep} from "../utils";
import api from "../api/api";
import {gender as genderVariants} from "../types/gender";
import {AuthContext} from "../context/AuthProvider";
import {useError} from "../context/ErrorHandler";
import {useTranslation} from "react-i18next";

const NavigationsContainer = styled(BoxFlex)({
    justifyContent: "space-between",

    "@media (max-width: 899px)": {
        flexDirection: "column",
        marginTop: 20,
    },
}) as typeof BoxFlex

const NavigationBlockBox = styled(BoxFlex)({

    "@media (max-width: 899px)": {
        flexDirection: "column",

        "&:last-of-type a:last-of-type": {
            border: "none",
        },
    },
}) as typeof BoxFlex

const TabBox = styled(BoxFlex)(({theme}) => ({
    alignItems: "center",
    gridGap: 12,
    padding: "30px 0",

    "&.active": {
        color: theme.palette.text.primary,
        "& path": {
            stroke: theme.palette.primary.main,
        }
    },

    "@media (max-width: 899px)": {
        padding: "18px 0",
    },
})) as typeof BoxFlex

const TabButton = styled(Button)(({theme}) => ({

    "@media (max-width: 899px)": {
        justifyContent: "start",
        padding: 0,
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
})) as typeof Button

const ProfilePage = () => {
    const { t } = useTranslation();
    const tabValues = {
        medicalCard: "medicalCard",
        individualPlan: "individualPlan",
        recordings: "recordings",
        settings: "settings",
    }
    const tabNavigation = {
        [tabValues.medicalCard]: {
            title: t("common.myMedCard"),
            icon: <Identification
                color={theme.palette.text.tertiary}
            />
        },
        [tabValues.individualPlan]: {
            title: t("common.individualPlan"),
            icon: <Grid2
                color={theme.palette.text.tertiary}
            />
        },
        [tabValues.recordings]: {
            title: t("common.appointments"),
            icon: <UserGroup
                color={theme.palette.text.tertiary}
            />
        },
    };

    const { setError } = useError();
    const {logout} = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true)
    const matches = useMediaQuery('(min-width:900px)');
    const [currentTab, setCurrentTab] = useState<string | null>(null)
    const [name, setName] = useState<string>('')
    const [age, setAge] = useState<number | null>(null)
    const [weight, setWeight] = useState<number | null>(null)
    const [height, setHeight] = useState<number | null>(null)
    const [gender, setGender] = useState<keyof typeof genderVariants | null>(null)

    const setResponse = (response: any) => {
        setName(response.name)
        setAge(response.age)
        setWeight(response.weight)
        setHeight(response.height)
        setGender(response.gender)
    }

    const handleLogout = async () => {
        if (!isDev) {
            setLoading(true)
            await api.get('/auth/sign-out').then(res => {
                if (res.status === 200) {
                    logout()
                }
            })
                .catch(err => setError(err.response.data.message))
                .finally(() => setLoading(false))
        }
    }


    const fetchMainInfo = async () => {
        if (isDev) {
            await sleep(500)
            setResponse(require("../mocks/profile__getMainInfo.json"))
        } else {
            await api.get('/profile')
                .then(res => {
                    if (res.status === 200) {
                        setResponse(res.data)
                    }
                })
                .catch(err => setError(err.response.data.message))
        }
        setLoading(false)
    }

    const handleClickTab = (tabValue: string): void => {
        setCurrentTab(tabValue)
    }

    useEffect(() => {
        fetchMainInfo()
        if (matches) {
            setCurrentTab(tabValues.medicalCard)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DefaultTemplate>
            {(matches || (!matches && currentTab === null)) &&
                <HeroBlock
                    title={name}
                    navigation={
                        <NavigationsContainer>
                            <NavigationBlockBox>
                                {Object.keys(tabNavigation).map((tabValue, k) => (
                                    <TabButton
                                        key={k}
                                        variant="text"
                                        onClick={() => handleClickTab(tabValue)}
                                    >
                                        <TabBox
                                            className={
                                                classNames({
                                                    "active": currentTab === tabValue,
                                                })
                                            }
                                        >
                                            {tabNavigation[tabValue].icon}
                                            <Typography
                                                variant="subtitle2"
                                                color={currentTab === tabValue ? "text.primary" : "text.secondary"}
                                            >
                                                {tabNavigation[tabValue].title}
                                            </Typography>
                                        </TabBox>
                                    </TabButton>
                                ))}
                            </NavigationBlockBox>
                            <NavigationBlockBox>
                                <TabButton
                                    variant="text"
                                    onClick={() => handleClickTab(tabValues.settings)}
                                >
                                    <TabBox
                                        className={
                                            classNames({
                                                "active": currentTab === tabValues.settings,
                                            })
                                        }
                                    >
                                        <Gear
                                            color={theme.palette.text.tertiary}
                                        />
                                        <Typography
                                            variant="subtitle2"
                                            color={currentTab === tabValues.settings ? "text.primary" : "text.secondary"}
                                        >
                                            {t("common.settings")}
                                        </Typography>
                                    </TabBox>
                                </TabButton>
                                <TabButton
                                    variant="text"
                                    onClick={handleLogout}
                                >
                                    <TabBox>
                                        <ArrowRightOnRectangle
                                            color={theme.palette.text.tertiary}
                                        />
                                        <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                        >
                                            {t("common.logout")}
                                        </Typography>
                                    </TabBox>
                                </TabButton>
                            </NavigationBlockBox>
                        </NavigationsContainer>
                    }
                />
            }

            {!matches && currentTab !== null &&
                <Container
                    maxWidth="xl"
                    disableGutters
                >
                    <Box
                        sx={{
                            margin: "88px 0 20px",
                            padding: "0 20px",
                        }}
                    >
                        <CIconButton
                            onClick={() => setCurrentTab(null)}
                            variant="outlined"
                            size="small"
                        >
                            <ArrowSmallLeft/>
                        </CIconButton>
                    </Box>
                </Container>
            }

            {currentTab === tabValues.medicalCard &&
                <>
                    {loading
                        ? <Skeleton
                            height="400px"
                        />
                        : <MyMedicalRecordBlock
                            name={name}
                            age={age}
                            height={height}
                            weight={weight}
                            gender={gender}
                        />
                    }
                </>
            }

            {currentTab === tabValues.individualPlan &&
                <IndividualPlanBlock/>
            }

            {currentTab === tabValues.recordings &&
                <RecordingsBlock/>
            }

            {currentTab === tabValues.settings &&
                <SettingsBlock/>
            }
        </DefaultTemplate>
    );
};

export default ProfilePage;

import React, {useEffect, useState} from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import YourPainBlock from "../components/blocks/YourPainBlock";
import ArticlesBlock from "../components/blocks/ArticlesBlock";
import PosterBlock from "../components/blocks/PosterBlock";
import {Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography} from "@mui/material";
import theme from "../theme";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import BoxFlex from "../components/layout/BoxFlex";
import PersonalDataProcessingDialog from "../components/dialogs/PersonalDataProcessingDialog";
import {isDev} from "../utils";
import api from "../api/api";
import {useError} from "../context/ErrorHandler";
import Xmark from "../components/icons/Xmark";
import {Trans, useTranslation} from "react-i18next";

const PlatformPage = () => {
    const { t } = useTranslation();
    const { setError } = useError();
    const [email, setEmail] = useState('');
    const [disableBtn, setDisableBtn] = useState<boolean>(true)
    const [localError, setLocalError] = useState<string>('')
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const validateEmail = (email: string): boolean => {
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return re.test(email.trim());
    }

    const handleClick = async () => {
        if (!isDev) {
            setDisableBtn(true)
            await api.get(`/about/subscription`, {
                email
            })
                .then(res => {
                    if (res.status === 200) {
                        setOpenDialog(true)
                    }
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => setDisableBtn(false))
        }

    }

    useEffect(() => {
        if (email && !validateEmail(email)) {
            setLocalError(t("PlatformPage.emailErr"))
        } else {
            setLocalError('')
        }
    }, [email])

    useEffect(() => {
        setDisableBtn(email.length === 0 || localError.length !== 0)
    }, [email, localError])

    return (
        <DefaultTemplate>
            <HeroBlock
                title={t("PlatformPage.title")}
            />

            <YourPainBlock/>

            <ArticlesBlock/>

            <PosterBlock
                background={theme.palette.surface.main}
            >
                <BoxFlexColumn
                    sx={{
                        alignItems: "center",
                        textAlign: "center",
                        margin: "0 auto",
                    }}
                >
                    <Typography
                        variant="h2"
                    >
                        <Trans i18nKey="PlatformPage.subscriptionTitle" />
                    </Typography>

                    <BoxFlex
                        sx={{
                            margin: "82px 0 40px",
                            alignItems: "center",
                            gridGap: 20,
                            width: "100%",
                        }}
                    >
                        <TextField
                            label="Email"
                            variant="standard"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            error={localError.length !== 0}
                            helperText={localError}
                            fullWidth
                        />

                        <Button
                            disabled={email.length === 0 || disableBtn}
                            variant="contained"
                            onClick={handleClick}
                        >
                            {t("PlatformPage.subscribe")}
                        </Button>
                    </BoxFlex>

                    <Typography
                        variant="body3"
                    >
                        {t("PlatformPage.personalDataLabel")} <PersonalDataProcessingDialog/>
                    </Typography>
                </BoxFlexColumn>
            </PosterBlock>
            <Dialog
                onClose={() => setOpenDialog(false)}
                open={openDialog}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("PlatformPage.successMsg")}
                    </Typography>
                </DialogContent>
            </Dialog>
        </DefaultTemplate>
    );
};

export default PlatformPage;

import React, {useEffect, useState} from "react";
import {Container, Divider, Grid, Link, Skeleton, Typography} from "@mui/material";
import CAccordion from "../surfaces/CAccordion";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import routes from "../../routes";
import {Trans, useTranslation} from "react-i18next";

const FAQBlock = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ title: string, text: string }[]>([])

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../../mocks/faq__getData.json")
            setData(response)
        } else {
            await api.get('/faq')
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {(loading || (!loading && data.length > 0)) &&
                <Container
                    maxWidth="xl"
                >
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <Typography
                                variant="h2"
                                mb="40px"
                            >
                                <Trans i18nKey="FAQ.title" />
                            </Typography>
                            <Typography
                                variant="subtitle1"
                            >
                                {t("FAQ.subtitle")}
                            </Typography>
                            <Link href={routes.contacts}>
                                <Typography
                                    variant="subtitle1"
                                    color="primary"
                                >
                                    {t("FAQ.linkText")}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={8}
                        >
                            {loading ? (
                                <>
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                    />
                                    <br/>
                                    <Divider/>
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                    />
                                    <br/>
                                    <Divider/>
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                    />
                                    <br/>
                                    <Divider/>
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                    />
                                </>
                            ) : (
                                <CAccordion
                                    items={data}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            }
        </>
    );
}

export default FAQBlock;
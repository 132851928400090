import React, {useState} from "react";
import SingleElementTemplate from "../templates/SingleElementTemplate";
import {Button, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography} from "@mui/material";
import {styled} from "@mui/system";
import BoxFlex from "../components/layout/BoxFlex";
import CPaper from "../components/surfaces/CPaper";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import routes from "../routes";
import PhoneInput from "../components/inputs/PhoneInput";
import {isDev} from "../utils";
import api from "../api/api";
import Xmark from "../components/icons/Xmark";
import theme from "../theme";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Content = styled(BoxFlexColumn)({
    width: 584,
    maxWidth: "100%",
    gridGap: 32,

    "@media (max-width: 899px)": {
        width: "auto",
    },
}) as typeof BoxFlexColumn

const ButtonContainer = styled(BoxFlex)({
    alignItems: "center",
    justifyContent: "space-between",
    gridGap: 20,

    "@media (max-width: 899px)": {
        flexDirection: "column",

        "& button": {
            order: "-1",
        }
    },
}) as typeof BoxFlex

const PasswordResetPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [resetToken, setResetToken] = useState('')
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const handleReset = async () => {
        setLoading(true)

        if (!isDev) {
            await api.get('/auth/password-reset', {
                phone: phoneNumber,
                resetToken: resetToken,
            })
                .then(res => {
                    if (res.status === 200) {
                        setOpenDialog(true)
                    }
                })
        }

        setLoading(false)
    }

    const handleCloseDialog = () => {
        navigate(routes.login)
    };

    return (
        <SingleElementTemplate>
            <CPaper
                sx={{
                    gridGap: 32,
                }}
            >
                <Content>

                    <Typography
                        variant="h2"
                    >
                        {t("common.pwdRecovery")}
                    </Typography>

                    <PhoneInput
                        disabled={loading}
                        value={phoneNumber}
                        setValue={setPhoneNumber}
                        helperText={t("PasswordResetPage.inputHint")}
                    />

                    <TextField
                        label={t("PasswordResetPage.resetTokenLabel")}
                        disabled={loading}
                        variant="standard"
                        onChange={(e) => setResetToken(e.target.value)}
                        fullWidth
                        required
                        helperText={t("PasswordResetPage.resetTokenHint")}
                    />
                    <ButtonContainer>
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            href={routes.login}
                            fullWidth
                        >
                            {t("common.login")}
                        </Button>

                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={handleReset}
                            disabled={loading}
                        >
                            {t("common.send")}
                        </Button>
                    </ButtonContainer>

                </Content>
            </CPaper>

            <Dialog
                onClose={handleCloseDialog}
                open={openDialog}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("PasswordResetPage.dialogText")}
                    </Typography>
                </DialogContent>
            </Dialog>
        </SingleElementTemplate>
    );
};

export default PasswordResetPage;

import React, {createContext, ReactNode, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {getFromStorage, removeFromStorage, setInStorage} from "../localStorageUtils";
import routes from "../routes";

interface AuthContextProps {
    isAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
    login: () => {},
    logout: () => {},
});

interface AuthProviderProps {
    children: ReactNode;
    onChangeAuth: (isAuthenticated: boolean) => void;
}

interface AuthProviderState {
    isAuthenticated: boolean;
}

function AuthProvider({ children, onChangeAuth }: AuthProviderProps) {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = getFromStorage("authToken");
        if (token) {
            // проверка токена на валидность
            setIsAuthenticated(true)
        }
    }, []);

    useEffect(() => {
        onChangeAuth(isAuthenticated)
    }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

    function login(token: string) {
        setInStorage("authToken", token)
        setIsAuthenticated(true);
    }

    function logout() {
        removeFromStorage("authToken")
        setIsAuthenticated(false);
        navigate(routes.login);
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
import React from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import SpecialistsByCategoryBlock from "../components/blocks/SpecialistsByCategoryBlock";
import {useTranslation} from "react-i18next";

const SpecialistsPage = () => {
    const { t } = useTranslation();
    return (
        <DefaultTemplate>
            <HeroBlock
                title={t("common.makeAnAppointment")}
            />

            <SpecialistsByCategoryBlock />
        </DefaultTemplate>
    );
};

export default SpecialistsPage;

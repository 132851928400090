import * as React from 'react';
import {Chart, Legend, PieSeries} from '@devexpress/dx-react-chart-material-ui';
import {Animation} from '@devexpress/dx-react-chart';
import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        height: "100%",
        justifyContent: "center",
        "& > .Target-root": {
            padding: "0 !important",
        },
    }
});

const PieChart = (props: {
    data: {
        title: string,
        value: number,
    }[],
}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const data = props.data;
    const labelComponent = (props: Legend.LabelProps) => {
        const item = data.find(item => item.title === props.text);

        return <Legend.Label {...props} text={`${props.text} ${item?.value}%`}/>;
    };

    return (
        <BoxFlexColumn
            className={classes.root}
        >
            {props.data.length === 0 ? (
                <Typography
                    variant="body2"
                    align="center"
                    color="text.disabled"
                >
                    {t("common.noData")}
                </Typography>
            ) : (
                <Chart
                    data={props.data}
                >
                    <PieSeries
                        valueField="value"
                        argumentField="title"
                    />
                    <Legend
                        position="bottom"
                        labelComponent={labelComponent}
                    />

                    <Animation/>
                </Chart>
            )}
        </BoxFlexColumn>
    )
}

export default PieChart;
import React, {useContext, useEffect, useState} from "react";
import {styled} from "@mui/system";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel, Grid,
    InputLabel,
    Link,
    MenuItem,
    SelectChangeEvent,
    TextField,
    Typography, useMediaQuery
} from "@mui/material";
import testStep1_1 from "../img/test-step-1.1.png";
import testStep1_mob from "../img/test-step-1-mob.png";
import testStep2_mob from "../img/test-step-2-mob.png";
import testStep3_mob from "../img/test-step-3-mob.png";
import testStep4_mob from "../img/test-step-4-mob.png";
import testStep5_mob from "../img/test-step-5-mob.png";
import testStep6_mob from "../img/test-step-6-mob.png";
import testStep7_mob from "../img/test-step-7-mob.png";
import testStep8_mob from "../img/test-step-8-mob.png";
import testStep9_mob from "../img/test-step-9-mob.png";
import testStep10_mob from "../img/test-step-10-mob.png";
import testStep_mob from "../img/test-step-mob.png";
import testStep1_2 from "../img/test-step-1.2.png";
import testStep1_3 from "../img/test-step-1.3.png";
import testStep2_1 from "../img/test-step-2.1.png";
import testStep2_2 from "../img/test-step-2.2.png";
import testStep2_3 from "../img/test-step-2.3.png";
import testStep3_1 from "../img/test-step-3.1.png";
import testStep3_2 from "../img/test-step-3.2.png";
import testStep3_3 from "../img/test-step-3.3.png";
import testStep4_1 from "../img/test-step-4.1.png";
import testStep4_2 from "../img/test-step-4.2.png";
import testStep4_3 from "../img/test-step-4.3.png";
import testStep5_1 from "../img/test-step-5.1.png";
import testStep5_2 from "../img/test-step-5.2.png";
import testStep5_3 from "../img/test-step-5.3.png";
import testStep6_1 from "../img/test-step-6.1.png";
import testStep6_2 from "../img/test-step-6.2.png";
import testStep6_3 from "../img/test-step-6.3.png";
import testStep7_1 from "../img/test-step-7.1.png";
import testStep7_2 from "../img/test-step-7.2.png";
import testStep7_3 from "../img/test-step-7.3.png";
import testStep8_1 from "../img/test-step-8.1.png";
import testStep8_2 from "../img/test-step-8.2.png";
import testStep9_1 from "../img/test-step-9.1.png";
import testStep9_2 from "../img/test-step-9.2.png";
import testStep9_3 from "../img/test-step-9.3.png";
import testStep10_1 from "../img/test-step-10.1.png";
import testStep10_2 from "../img/test-step-10.2.png";
import testStep10_3 from "../img/test-step-10.3.png";
import CIconButton from "../components/inputs/CIconButton";
import Xmark from "../components/icons/Xmark";
import routes from "../routes";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import ArrowSmallRight from "../components/icons/ArrowSmallRight";
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import theme from "../theme";
import BoxFlex from "../components/layout/BoxFlex";
import CPaper from "../components/surfaces/CPaper";
import CSelect from "../components/inputs/CSelect";
import Emoji from "../components/functional/Emoji";
import ChipCheckbox from "../components/data-display/ChipCheckbox";
import StackChip from "../components/layout/StackChip";
import PainSlider from "../components/inputs/PainSlider";
import {gender as genderVariants} from "../types/gender";
import {painTypes as painTypeVariants} from "../types/painTypes";
import PersonalDataProcessingDialog from "../components/dialogs/PersonalDataProcessingDialog";
import {isDev} from "../utils";
import api from "../api/api";
import {useError} from "../context/ErrorHandler";
import {AuthContext} from "../context/AuthProvider";
import {useNavigate} from "react-router-dom";
import PhoneInput from "../components/inputs/PhoneInput";
import PassInput from "../components/inputs/PassInput";
import {useTranslation} from "react-i18next";
import CDatePicker from "../components/inputs/CDatePicker";
import {Moment} from "moment/moment";
import moment from "moment";

const Background = styled(BoxFlex)(({theme}) => ({
    alignItems: "stretch",
    backgroundColor: theme.palette.surface.main,
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
})) as typeof BoxFlex;

const Content = styled(BoxFlexColumn)({
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto",
    width: 860,
    gridGap: 30,

    "@media (max-width: 899px)": {
        gridGap: 0,
        width: "100%",
    },
}) as typeof BoxFlexColumn;

const CloseButton = styled(CIconButton)({
    position: "absolute",
    top: 40,
    right: 40,

    "@media (max-width: 899px)": {
        top: 20,
        right: 20,
    },
}) as typeof Button;

const ProgressbarContainer = styled(Box)({
    width: 160,
    height: 160,
    marginBottom: 80,

    "@media (max-width: 899px)": {
        marginBottom: 40,
    },
}) as typeof Box;

const NextButton = styled(CIconButton)({
    width: "120px !important",
    height: "120px !important",
}) as typeof Button

const StyledCPaper = styled(CPaper)(({theme}) => ({
    width: 860,
    backgroundColor: theme.palette.background.default,

    "@media (max-width: 899px)": {
        width: "auto",
        backgroundColor: "transparent",
        padding: "100px 20px 20px",
    },
})) as typeof CPaper;

const BoxFlexMedia = styled(BoxFlex)({
    "@media (max-width: 899px)": {
        flexDirection: "column",
    },
}) as typeof BoxFlex

const TestPage = () => {
    const {t} = useTranslation();
    const matches = useMediaQuery('(min-width:900px)');
    const {setError} = useError();
    const {login} = useContext(AuthContext);
    const navigate = useNavigate();
    const progressInfo = {
        1: {
            percent: 10,
            backgroundImage: matches ? `url(${testStep1_1}), url(${testStep1_2}), url(${testStep1_3})` : `url(${testStep1_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "top left, right, 4% 86%" : "20px 20px, top left",
        },
        2: {
            percent: 20,
            backgroundImage: matches ? `url(${testStep2_1}), url(${testStep2_2}), url(${testStep2_3})` : `url(${testStep2_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "76% 0,left, 95% 86%" : "20px 20px, top left",
        },
        3: {
            percent: 30,
            backgroundImage: matches ? `url(${testStep3_1}), url(${testStep3_2}), url(${testStep3_3})` : `url(${testStep3_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "10.5% 0, right, left" : "20px 20px, top left",
        },
        4: {
            percent: 40,
            backgroundImage: matches ? `url(${testStep4_1}), url(${testStep4_2}), url(${testStep4_3})` : `url(${testStep4_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "bottom left, right, left top" : "20px 20px, top left",
        },
        5: {
            percent: 50,
            backgroundImage: matches ? `url(${testStep5_1}), url(${testStep5_2}), url(${testStep5_3})` : `url(${testStep5_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "100% 80%, 4% 86%, 34% 0%" : "20px 20px, top left",
        },
        6: {
            percent: 60,
            backgroundImage: matches ? `url(${testStep6_1}), url(${testStep6_2}), url(${testStep6_3})` : `url(${testStep6_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "15% 0, right, 6% 90%" : "20px 20px, top left",
        },
        7: {
            percent: 70,
            backgroundImage: matches ? `url(${testStep7_1}), url(${testStep7_2}), url(${testStep7_3})` : `url(${testStep7_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "92% 38%, 18% 7%, 5% 68%" : "20px 20px, top left",
        },
        8: {
            percent: 80,
            backgroundImage: matches ? `url(${testStep8_1}), url(${testStep8_2})` : `url(${testStep8_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "top left, 93% 82%" : "20px 20px, top left",
        },
        9: {
            percent: 90,
            backgroundImage: matches ? `url(${testStep9_1}), url(${testStep9_2}), url(${testStep9_3})` : `url(${testStep9_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "90% 0, bottom left, 92% 59%" : "20px 20px, top left",
        },
        10: {
            percent: 100,
            backgroundImage: matches ? `url(${testStep10_1}), url(${testStep10_2}), url(${testStep10_3})` : `url(${testStep10_mob}), url(${testStep_mob})`,
            backgroundPosition: matches ? "top center, 80% 15%, 20% 8%" : "20px 20px, top left",
        },
    };
    const painStartVariants: { [index: string]: string } = {
        "1": t("TestPage.painStart1"),
        "2": t("TestPage.painStart2"),
        "3": t("TestPage.painStart3"),
    };
    const painFrequencyVariants: { [index: string]: string } = {
        "1": t("TestPage.painFrequency1"),
        "2": t("TestPage.painFrequency2"),
        "3": t("TestPage.painFrequency3"),
        "4": t("TestPage.painFrequency4"),
        "5": t("TestPage.painFrequency5"),
        "6": t("TestPage.painFrequency6"),
    };
    const painNatureVariants: { [index: string]: Array<string | React.ReactElement> } = {
        shooting: [t("Natures.shooting").toLowerCase() + " ", <Emoji symbol="⚡" label="High Voltage"/>],
        cutting: [t("Natures.cutting").toLowerCase() + " ", <Emoji symbol="🔪" label="Kitchen Knife"/>],
        aching: [t("Natures.aching").toLowerCase() + " ", <Emoji symbol="🌪" label="Tornado"/>],
        sharp: [t("Natures.sharp").toLowerCase() + " ", <Emoji symbol="💥" label="Collision"/>],
        throbbing: [t("Natures.throbbing").toLowerCase() + " ", <Emoji symbol="🎯" label="Bullseye"/>],
        pressing: [t("Natures.pressing").toLowerCase() + " ", <Emoji symbol="🧱" label="Brick"/>],
        dull: [t("Natures.dull").toLowerCase() + " ", <Emoji symbol="🪵" label="Wood"/>],
        tingling: [t("Natures.tingling").toLowerCase() + " ", <Emoji symbol="✨" label="Sparkles"/>],
        burning: [t("Natures.burning").toLowerCase() + " ", <Emoji symbol="🔥" label="Fire"/>],
    };
    const additionalSymptomVariants: { [index: string]: Array<string | React.ReactElement> } = {
        lethargy: [t("AdditionalSymptoms.lethargy").toLowerCase() + " ", <Emoji symbol="😴" label="Sleeping Face"/>],
        depression: [t("AdditionalSymptoms.depression").toLowerCase() + " ", <Emoji symbol="😔" label="Pensive Face"/>],
        dizziness: [t("AdditionalSymptoms.dizziness").toLowerCase() + " ",
            <Emoji symbol="🤕" label="Face with Head-Bandage"/>],
        nausea: [t("AdditionalSymptoms.nausea").toLowerCase() + " ", <Emoji symbol="🤢" label="Nauseated Face"/>],
        difficultyBreathing: [t("AdditionalSymptoms.difficultyBreathing").toLowerCase() + " ",
            <Emoji symbol="😮‍💨" label="Face Exhaling"/>],
        anxiety: [t("AdditionalSymptoms.anxiety").toLowerCase() + " ", <Emoji symbol="😨" label="Fearful Face"/>],
    };
    const painCausesAndEffectVariants: { [index: string]: string } = {
        insomnia: t("CausesAndEffects.insomnia").toLowerCase(),
        stress: t("CausesAndEffects.stress").toLowerCase(),
        weather: t("CausesAndEffects.weather").toLowerCase(),
        standing: t("CausesAndEffects.standing").toLowerCase(),
        sitting: t("CausesAndEffects.sitting").toLowerCase(),
        walking: t("CausesAndEffects.walking").toLowerCase(),
        training: t("CausesAndEffects.training").toLowerCase(),
        food: t("CausesAndEffects.food").toLowerCase(),
        toilet: t("CausesAndEffects.toilet").toLowerCase(),
    };
    const helperVariants: { [index: string]: Array<string | React.ReactElement> } = {
        conversation: [t("Helpers.conversation").toLowerCase() + " ", <Emoji symbol="🗣" label="Speaking Head"/>],
        exerciseStress: [t("Helpers.exerciseStress").toLowerCase() + " ",
            <Emoji symbol="🏊‍♀️" label="Woman Swimming"/>],
        coldWarm: [t("Helpers.cold").toLowerCase() + " ",
            <Emoji symbol="❄" label="Snowflake"/>, "\n\r️/ " + t("Helpers.warm").toLowerCase() + " ",
            <Emoji symbol="🔥" label="Fire"/>],
        rest: [t("Helpers.rest").toLowerCase() + " ", <Emoji symbol="🏖" label="Beach with Umbrella"/>],
        meditationYoga: [t("Helpers.meditationYoga").toLowerCase() + " ",
            <Emoji symbol="🧘🏻‍♀️" label="Woman in Lotus Position"/>],
        massage: [t("Helpers.massage").toLowerCase() + " ", <Emoji symbol="💆🏻‍♀️" label="Woman Getting Massage"/>],
        acupuncture: [t("Helpers.acupuncture").toLowerCase() + " ", <Emoji symbol="📍" label="Round Pushpin"/>],
        alcohol: [t("Helpers.alcohol").toLowerCase() + " ", <Emoji symbol="🍷" label="Wine Glass"/>],
        music: [t("Helpers.music").toLowerCase() + " ", <Emoji symbol="🎵" label="Musical Note"/>],
    };
    const [step, setStep] = useState<keyof typeof progressInfo>(1)
    const [dateOfBirth, setDateOfBirth] = useState<Moment | null>(null)
    const [gender, setGender] = useState<keyof typeof genderVariants>('')
    const [painTypes, setPainTypes] = useState<Array<keyof typeof painTypeVariants>>([])
    const [painScore, setPainScore] = useState<number>(4)
    const [painStart, setPainStart] = useState<keyof typeof painStartVariants>('')
    const [painFrequency, setPainFrequency] = useState<keyof typeof painFrequencyVariants>('')
    const [painNatures, setPainNatures] = useState<Array<keyof typeof painNatureVariants>>([])
    const [additionalSymptoms, setAdditionalSymptoms] = useState<Array<keyof typeof additionalSymptomVariants>>([])
    const [isNoAdditionalSymptom, setIsNoAdditionalSymptom] = useState<boolean>(false)
    const [painCausesAndEffects, setPainCausesAndEffects] = useState<Array<keyof typeof painCausesAndEffectVariants>>([])
    const [isTakingMedications, setIsTakingMedications] = useState<null | boolean>(null)
    const [helpers, setHelpers] = useState<Array<keyof typeof helperVariants>>([])
    const [firstName, setFirstName] = useState<string>('')
    const [surname, setSurname] = useState<string>('')
    const [patronymic, setPatronymic] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = useState<string>('')
    const [passwordConfirmError, setPasswordConfirmError] = useState<string>('')
    const [isAgree, setIsAgree] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    function validatePhone(phone: string): boolean {
        const re = /^\+\d* (\(|)\d{2,3}(\)|) \d{2,3}(-|\s*)\d{2,3}(-|\s*)\d{2,3}$/;
        return re.test(phone.trim());
    }

    function validateEmail(email: string): boolean {
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return re.test(email.trim());
    }

    function validatePassword(password: string, passwordConfirm: string): boolean {
        return password.length > 0
            && passwordError.length === 0
            && passwordConfirmError.length === 0
            && password === passwordConfirm
    }

    const validateStep = () => {
        if (step === 1) {
            if (
                dateOfBirth === null
                || gender === ''
            ) {
                return false
            }
        }
        if (step === 2) {
            if (painTypes.length === 0) {
                return false
            }
        }
        if (step === 3) {
            if (painStart === '') {
                return false
            }
        }
        if (step === 4) {
            if (painFrequency === '') {
                return false
            }
        }
        if (step === 5) {
            if (painNatures.length === 0) {
                return false
            }
        }
        if (step === 6) {
            if (
                additionalSymptoms.length === 0
                && !isNoAdditionalSymptom
            ) {
                return false
            }
        }
        if (step === 7) {
            if (painCausesAndEffects.length === 0) {
                return false
            }
        }
        if (step === 8) {
            if (isTakingMedications === null) {
                return false
            }
        }
        if (step === 9) {
            if (helpers.length === 0) {
                return false
            }
        }
        if (step === 10) {
            if (
                firstName.length < 3
                || !validatePhone(phone)
                || !isAgree
                || (email.length > 0 && !validateEmail(email))
                || !validatePassword(password, passwordConfirm)
            ) {
                return false
            }
        }

        return true
    }

    const handleNextStep = async () => {
        if (step === 10) {
            setLoading(true)
            const res = {
                dateOfBirth: dateOfBirth ? dateOfBirth.format('Y-MM-DD') : null,
                gender: gender,
                painTypes: painTypes,
                painScore: painScore,
                painStart: painStart,
                painFrequency: painFrequency,
                painNatures: painNatures,
                additionalSymptoms: additionalSymptoms,
                isNoAdditionalSymptom: isNoAdditionalSymptom,
                painCausesAndEffects: painCausesAndEffects,
                isTakingMedications: isTakingMedications,
                helpers: helpers,
                firstName: firstName,
                surname: surname,
                patronymic: patronymic,
                phone: phone,
                email: email,
                isAgree: isAgree,
                password: password,
            };
            if (isDev) {
                alert(JSON.stringify(res))
            } else {
                await api.post('/auth/sign-up', res)
                    .then(res => {
                        if (res.status === 200) {
                            login(res.data.token)
                            navigate(routes.profile)
                        }
                    })
                    .catch(err => setError(err.response.data.message))
            }
            setLoading(false)
        }
        setStep(prevState => {
            if (prevState < 10) {
                prevState++;
            }
            return prevState;
        })
    }

    const handleChangeGender = (event: SelectChangeEvent<HTMLInputElement | unknown>): void => {
        setGender(event.target.value as string);
    }

    const handleChangePainScore = (event: Event, newValue: number | number[]): void => {
        setPainScore(newValue as number)
    }

    const handleChangePainTypes = (newPainType: keyof typeof painTypeVariants): void => {
        setPainTypes(prevState => {
            return prevState.includes(newPainType)
                ? prevState.filter(i => i !== newPainType)
                : [...prevState, newPainType]
        })
    }

    const handleChangePainNatures = (newPainNature: keyof typeof painNatureVariants): void => {
        setPainNatures(prevState => {
            return prevState.includes(newPainNature)
                ? prevState.filter(i => i !== newPainNature)
                : [...prevState, newPainNature]
        })
    }

    const handleChangeAdditionalSymptoms = (newAdditionalSymptom: keyof typeof additionalSymptomVariants): void => {
        setAdditionalSymptoms(prevState => {
            return prevState.includes(newAdditionalSymptom)
                ? prevState.filter(i => i !== newAdditionalSymptom)
                : [...prevState, newAdditionalSymptom]
        })
    }

    const handleChangePainCausesAndEffects = (newPainCauseOrEffect: keyof typeof painCausesAndEffectVariants): void => {
        setPainCausesAndEffects(prevState => {
            return prevState.includes(newPainCauseOrEffect)
                ? prevState.filter(i => i !== newPainCauseOrEffect)
                : [...prevState, newPainCauseOrEffect]
        })
    }

    const handleChangeHelpers = (newHelper: keyof typeof helperVariants): void => {
        setHelpers(prevState => {
            return prevState.includes(newHelper)
                ? prevState.filter(i => i !== newHelper)
                : [...prevState, newHelper]
        })
    }

    const handleChangeIsNoAdditionalSymptom = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setIsNoAdditionalSymptom(event.target.checked)
    }

    const handleChangeIsAgree = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setIsAgree(event.target.checked)
    }

    const handleChangeIsTakingMedications = (newValue: boolean): void => {
        setIsTakingMedications(newValue)
    }

    const handleChangePainFrequency = (newValue: keyof typeof painFrequencyVariants): void => {
        setPainFrequency(newValue)
    }

    const handleChangePainStart = (newValue: keyof typeof painStartVariants): void => {
        setPainStart(newValue)
    }

    useEffect(() => {
        setPasswordError(password && (password.length < 5 || password.length > 255) ? t("pwd.passwordLengthErr") : '')
    }, [password])

    useEffect(() => {
        setPasswordConfirmError(passwordConfirm && passwordConfirm !== password ? t("pwd.passwordMismatchErr") : '')
    }, [password, passwordConfirm])

    return (
        <Background
            sx={{
                backgroundImage: progressInfo[step].backgroundImage,
                backgroundPosition: progressInfo[step].backgroundPosition,
            }}
        >
            <CloseButton
                variant="outlined"
                component={Link}
                href={routes.home}
            >
                <Xmark/>
            </CloseButton>
            <Content>
                <BoxFlex
                    alignItems="center"
                    height="100%"
                >
                    {step === 1 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 40,
                                }}
                            >
                                <Typography
                                    variant="h2"
                                >
                                    {t("TestPage.step1")}
                                </Typography>

                                <BoxFlexMedia
                                    sx={{
                                        gridGap: 20,
                                    }}
                                >
                                    <FormControl
                                        variant="standard"
                                        sx={{
                                            minWidth: 310,
                                            width: "50%",
                                        }}
                                    >
                                        <CDatePicker
                                            label={t("common.dateOfBirth")}
                                            onChange={setDateOfBirth}
                                            value={dateOfBirth}
                                            maxDate={moment()}
                                            fullWidth
                                        />
                                    </FormControl>

                                    <FormControl
                                        variant="standard"
                                        sx={{
                                            minWidth: 310,
                                            width: "50%",
                                        }}
                                    >
                                        <InputLabel>
                                            {t("common.gender")}
                                        </InputLabel>
                                        <CSelect
                                            value={gender}
                                            onChange={handleChangeGender}
                                            label={t("common.gender")}
                                        >
                                            <MenuItem value="">
                                                {t("common.noSelect")}
                                            </MenuItem>
                                            {Object.keys(genderVariants).map((val: string, i) => (
                                                <MenuItem
                                                    key={i}
                                                    value={val}
                                                >
                                                    {genderVariants[val]}
                                                </MenuItem>
                                            ))}
                                        </CSelect>
                                    </FormControl>
                                </BoxFlexMedia>
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 2 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 40,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("TestPage.step2")}
                                </Typography>

                                <StackChip>
                                    {Object.keys(painTypeVariants).map((val: string, i: number) => (
                                        <ChipCheckbox
                                            key={i}
                                            label={painTypeVariants[val]}
                                            checked={painTypes.includes(val)}
                                            onClick={() => handleChangePainTypes(val)}
                                        />
                                    ))}
                                </StackChip>
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 3 &&
                        <BoxFlexColumn
                            sx={{
                                gridGap: 17,
                            }}
                        >
                            <StyledCPaper>
                                <BoxFlexColumn
                                    sx={{
                                        gridGap: 56,
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                    >
                                        {t("TestPage.step3")}
                                    </Typography>

                                    <PainSlider
                                        aria-label={t("TestPage.step3Hint")}
                                        value={painScore}
                                        onChange={handleChangePainScore}
                                        step={1}
                                        min={1}
                                        max={10}
                                    />
                                </BoxFlexColumn>


                            </StyledCPaper>
                            <StyledCPaper>
                                <BoxFlexColumn
                                    sx={{
                                        gridGap: 35,
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                    >
                                        {t("TestPage.step3subtitle")}
                                    </Typography>

                                    <BoxFlex
                                        sx={{
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        {Object.keys(painStartVariants).map((val: string, i: number) => (
                                            <Button
                                                key={i}
                                                variant={painStart === val ? "contained" : "outlined"}
                                                size={matches ? "large" : "medium"}
                                                onClick={() => handleChangePainStart(val)}
                                            >
                                                {painStartVariants[val]}
                                            </Button>
                                        ))}
                                    </BoxFlex>
                                </BoxFlexColumn>
                            </StyledCPaper>
                        </BoxFlexColumn>
                    }

                    {step === 4 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 53,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("TestPage.step4")}
                                </Typography>

                                <BoxFlex
                                    sx={{
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                        columnGap: "20px",
                                        rowGap: "53px",

                                        "@media (max-width: 899px)": {
                                            rowGap: "20px",
                                        },
                                    }}
                                >
                                    {Object.keys(painFrequencyVariants).map((val: string, i: number) => (
                                        <Button
                                            key={i}
                                            variant={painFrequency === val ? "contained" : "outlined"}
                                            size={matches ? "large" : "medium"}
                                            onClick={() => handleChangePainFrequency(val)}
                                        >
                                            {painFrequencyVariants[val]}
                                        </Button>
                                    ))}
                                </BoxFlex>
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 5 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 21,
                                }}
                            >
                                <div>
                                    <Typography
                                        variant="h3"
                                        mb="10px"
                                    >
                                        {t("TestPage.step5")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.disabled"
                                    >
                                        {t("TestPage.step5subtitle")}
                                    </Typography>
                                </div>

                                <StackChip>
                                    {Object.keys(painNatureVariants).map((val: string, i: number) => (
                                        <ChipCheckbox
                                            key={i}
                                            label={painNatureVariants[val]}
                                            checked={painNatures.includes(val)}
                                            onClick={() => handleChangePainNatures(val)}
                                        />
                                    ))}
                                </StackChip>
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 6 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 46,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("TestPage.step6")}
                                </Typography>

                                <StackChip>
                                    {Object.keys(additionalSymptomVariants).map((val: string, i: number) => (
                                        <ChipCheckbox
                                            key={i}
                                            label={additionalSymptomVariants[val]}
                                            checked={additionalSymptoms.includes(val)}
                                            onClick={() => handleChangeAdditionalSymptoms(val)}
                                        />
                                    ))}
                                </StackChip>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isNoAdditionalSymptom}
                                            onChange={handleChangeIsNoAdditionalSymptom}
                                        />
                                    }
                                    label={t("common.nothing")}
                                />
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 7 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 47,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("TestPage.step7")}
                                </Typography>

                                <StackChip>
                                    {Object.keys(painCausesAndEffectVariants).map((val: string, i: number) => (
                                        <ChipCheckbox
                                            key={i}
                                            label={painCausesAndEffectVariants[val]}
                                            checked={painCausesAndEffects.includes(val)}
                                            onClick={() => handleChangePainCausesAndEffects(val)}
                                        />
                                    ))}
                                </StackChip>
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 8 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 42,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("TestPage.step8")}
                                </Typography>

                                <BoxFlex
                                    sx={{
                                        gridGap: 43,
                                    }}
                                >
                                    <Button
                                        variant={isTakingMedications === true ? "contained" : "outlined"}
                                        size={matches ? "large" : "medium"}
                                        onClick={() => handleChangeIsTakingMedications(true)}
                                    >
                                        {t("common.yes")}
                                    </Button>
                                    <Button
                                        variant={isTakingMedications === false ? "contained" : "outlined"}
                                        size={matches ? "large" : "medium"}
                                        onClick={() => handleChangeIsTakingMedications(false)}
                                    >
                                        {t("common.no")}
                                    </Button>
                                </BoxFlex>
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 9 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 35,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("TestPage.step9")}
                                </Typography>

                                <StackChip>
                                    {Object.keys(helperVariants).map((val: string, i: number) => (
                                        <ChipCheckbox
                                            key={i}
                                            label={helperVariants[val]}
                                            checked={helpers.includes(val)}
                                            onClick={() => handleChangeHelpers(val)}
                                        />
                                    ))}
                                </StackChip>
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }

                    {step === 10 &&
                        <StyledCPaper>
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 32,
                                }}
                            >
                                <Typography
                                    variant="h3"
                                >
                                    {t("TestPage.step10")}
                                </Typography>

                                <BoxFlexColumn
                                    sx={{
                                        gridGap: 20,
                                    }}
                                >
                                    <TextField
                                        label={t("common.name")}
                                        variant="standard"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        value={firstName}
                                        disabled={loading}
                                        required
                                    />

                                    <TextField
                                        label={t("common.surname")}
                                        variant="standard"
                                        onChange={(e) => setSurname(e.target.value)}
                                        value={surname}
                                        disabled={loading}
                                    />

                                    <TextField
                                        label={t("common.patronymic")}
                                        variant="standard"
                                        onChange={(e) => setPatronymic(e.target.value)}
                                        value={patronymic}
                                        disabled={loading}
                                    />

                                    <Grid
                                        container
                                        spacing={"20px"}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <PhoneInput
                                                disabled={loading}
                                                value={phone}
                                                setValue={setPhone}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <TextField
                                                label="Email"
                                                variant="standard"
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                value={email}
                                                disabled={loading}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={"20px"}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <PassInput
                                                label={t("pwd.pwd")}
                                                value={password}
                                                setValue={setPassword}
                                                disabled={loading}
                                                errorText={passwordError}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >

                                            <PassInput
                                                label={t("pwd.confirmPwd")}
                                                value={passwordConfirm}
                                                setValue={setPasswordConfirm}
                                                disabled={loading}
                                                errorText={passwordConfirmError}
                                            />
                                        </Grid>
                                    </Grid>
                                </BoxFlexColumn>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAgree}
                                            onChange={handleChangeIsAgree}
                                            disabled={loading}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body3"
                                        >
                                            {t("TestPage.agree")} <PersonalDataProcessingDialog/>
                                        </Typography>
                                    }
                                />
                            </BoxFlexColumn>
                        </StyledCPaper>
                    }
                </BoxFlex>

                <ProgressbarContainer>
                    <CircularProgressbarWithChildren
                        value={progressInfo[step].percent}
                        strokeWidth={1}
                        styles={buildStyles({
                            pathColor: theme.palette.primary.main,
                            trailColor: theme.palette.border.main,
                        })}
                    >
                        <NextButton
                            variant="contained"
                            onClick={handleNextStep}
                            disabled={!validateStep() || loading}
                        >
                            <ArrowSmallRight
                                width={40}
                                height={40}
                            />
                        </NextButton>
                    </CircularProgressbarWithChildren>
                </ProgressbarContainer>
            </Content>
        </Background>
    );
};

export default TestPage;

import React, {useState, useContext} from "react";
import SingleElementTemplate from "../templates/SingleElementTemplate";
import {Button, Link, Typography, useMediaQuery} from "@mui/material";
import {styled} from "@mui/system";
import BoxFlex from "../components/layout/BoxFlex";
import CPaper from "../components/surfaces/CPaper";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import routes from "../routes";
import {isDev} from "../utils";
import api from "../api/api";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../context/AuthProvider";
import PhoneInput from "../components/inputs/PhoneInput";
import PassInput from "../components/inputs/PassInput";
import {useTranslation} from "react-i18next";

const Content = styled(BoxFlexColumn)({
    width: 584,
    maxWidth: "100%",
    gridGap: 32,

    "@media (max-width: 899px)": {
        width: "auto",
    },
}) as typeof BoxFlexColumn

const LoginPage = () => {
    const { t } = useTranslation();
    const {login} = useContext(AuthContext);
    const navigate = useNavigate();
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const matches = useMediaQuery('(min-width:900px)');

    const handleLogin = async () => {
        if (!isDev) {
            setError('')
            setLoading(true)
            await api.get('/auth/sign-in', {
                phone: phone,
                password: password,
            }).then(res => {
                if (res.status === 200) {
                    login(res.data.token)
                    navigate(routes.profile)
                }
            })
                .catch(err => setError(err.response.data.message))
                .finally(() => setLoading(false))
        }
    }

    return (
        <SingleElementTemplate>
            <CPaper
                sx={{
                    gridGap: 32,
                }}
            >
                <Content>

                    <Typography
                        variant="h2"
                    >
                        {t("common.login")}
                    </Typography>


                    <PhoneInput
                        disabled={loading}
                        value={phone}
                        setValue={setPhone}
                    />

                    <PassInput
                        label={t("pwd.pwd")}
                        value={password}
                        setValue={setPassword}
                        disabled={loading}
                    />

                    {error &&
                        <Typography
                            variant="caption"
                            color="error.main"
                        >
                            {error}
                        </Typography>
                    }
                    <BoxFlex
                        sx={{
                            alignItems: "center",
                            justifyContent: "space-between",
                            gridGap: 20,
                        }}
                    >
                        <Link href={routes.requestPasswordReset}>
                            <Typography
                                variant="body2"
                                color="text.tertiary"
                            >
                                {t("pwd.forgotPwd")}
                            </Typography>
                        </Link>

                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                width: matches ? 200 : "auto",
                            }}
                            onClick={handleLogin}
                            disabled={loading}
                        >
                            {t("common.login")}
                        </Button>
                    </BoxFlex>

                </Content>
            </CPaper>

            <Typography
                variant="body2"
                mt="32px"
                textAlign="center"
            >
                If you don't have an account yet, <Link href={routes.test}>take the test
                to register.</Link>
            </Typography>
        </SingleElementTemplate>
    );
};

export default LoginPage;

import React from "react";
import {Box, Link, Typography} from "@mui/material";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import {makeStyles} from "@mui/styles";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
    link: {
        padding: "24px 0",
        borderBottom: `1px solid ${theme.palette.border.main}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:first-of-type": {
            borderTop: `1px solid ${theme.palette.border.main}`,
        }
    },
}));

const LinkList = (props: {
    title?: string,
    items: {
        title: string,
        url: string,
    }[],
    icon?: React.ReactElement,
    isExternal?: boolean,
    isHorizontal?: boolean,
}) => {
    const classes = useStyles();
    return (
        <BoxFlexColumn>
            {props.title &&
                <Typography
                    variant="h3"
                    sx={{
                        mb: {
                            xs: "28px",
                            md: "40px",
                        },
                    }}
                >
                    {props.title}
                </Typography>
            }
            <Box
                sx={{
                    ...(props.isHorizontal && {
                        display: 'flex',

                        "@media (max-width: 899px)": {
                            flexDirection: "column",
                        },
                        "@media (min-width: 900px)": {
                            borderTop: `1px solid ${theme.palette.border.main}`,
                            borderBottom: `1px solid ${theme.palette.border.main}`,
                            justifyContent: "space-between",
                            "& > a": {
                                border: "none !important",
                            }
                        },
                    })
                }}
            >
                {Object.keys(props.items).map((val, i) => {
                    const content = () => (
                        <>
                            <Typography
                                variant="body2"
                                color="text.primary"
                            >
                                {props.items[i].title}
                            </Typography>

                            {
                                props.icon &&
                                <>
                                    {props.icon}
                                </>
                            }
                        </>
                    );
                    return <React.Fragment key={i}>
                        {props.isExternal ? (
                            <a
                                className={classes.link}
                                href={props.items[i].url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {content()}
                            </a>
                        ) : (
                            <Link
                                className={classes.link}
                                href={props.items[i].url}
                            >
                                {content()}
                            </Link>
                        )}
                    </React.Fragment>
                })}
            </Box>
        </BoxFlexColumn>
    );
}

export default LinkList;
import React, {useEffect, useState} from "react";
import Carousel from "react-multi-carousel";
import {Container, Skeleton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import CIconButton from "../inputs/CIconButton";
import ArrowSmallLeft from "../icons/ArrowSmallLeft";
import ArrowSmallRight from "../icons/ArrowSmallRight";
import CAvatar from "../data-display/CAvatar";
import theme from "../../theme";
import CPaper from "../surfaces/CPaper";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import BoxFlex from "../layout/BoxFlex";
import {dateFormatter, isDev, sleep} from "../../utils";
import api from "../../api/api";
import {useTranslation} from "react-i18next";

const ReviewItemBox = styled(BoxFlexColumn)({
    justifyContent: "space-between",
    height: 420,
}) as typeof BoxFlexColumn;

const ReviewInfoBox = styled(BoxFlex)({
    alignItems: "center",
    gridGap: 16,
}) as typeof BoxFlex;

const ReviewInfoTextBox = styled(BoxFlexColumn)({
    gridGap: 2,
}) as typeof BoxFlexColumn;

const ReviewItem = (props: {
    name: string,
    content: string,
    date?: string,
    image?: string,
}) => {

    return (
        <CPaper
            sx={{
                backgroundColor: theme.palette.surface.main,
                marginRight: "20px",
            }}
        >
            <ReviewItemBox>
                <Typography
                    variant="body2"
                >
                    {props.content}
                </Typography>

                <ReviewInfoBox>
                    <CAvatar
                        alt={props.name}
                        src={props.image}
                        size="md"
                    />
                    <ReviewInfoTextBox>
                        <Typography
                            variant="subtitle2"
                        >
                            {props.name}
                        </Typography>
                        {props.date &&
                            <Typography
                                variant="body2"
                                color="text.tertiary"
                            >
                                {dateFormatter(props.date)}
                            </Typography>
                        }
                    </ReviewInfoTextBox>
                </ReviewInfoBox>
            </ReviewItemBox>
        </CPaper>
    );
}

const ButtonGroup = (props: {
    next?: any,
    previous?: any,
}) => {
    const { t } = useTranslation();
    return (
        <Typography
            variant="h2"
            className="block-title"
            sx={{
                order: -1,
            }}
        >
            <BoxFlex
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {t("Reviews.title")}

                <BoxFlex
                    sx={{
                        gridGap: "16px",
                    }}
                >
                    <CIconButton
                        variant="outlined"
                        onClick={() => props.previous()}
                    >
                        <ArrowSmallLeft/>
                    </CIconButton>
                    <CIconButton
                        variant="outlined"
                        onClick={() => props.next()}
                    >
                        <ArrowSmallRight/>
                    </CIconButton>
                </BoxFlex>
            </BoxFlex>
        </Typography>
    )
}

const ReviewsBlock = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string, content: string, image?: string, date?: string }[]>([])

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../../mocks/reviews__getAll.json")
            setData(response)
        } else {
            await api.get('/reviews')
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Container maxWidth="xl">
            {(loading || (!loading && data.length > 0)) &&
                <BoxFlexColumn>
                    <Carousel
                        responsive={{
                            tablet: {
                                breakpoint: {max: 999999, min: 464},
                                items: 2
                            },
                            mobile: {
                                breakpoint: {max: 600, min: 0},
                                items: 1
                            },
                        }}
                        arrows={false}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup/>}
                        infinite={true}
                    >
                        {loading &&
                            <Skeleton sx={{
                                height: 250,
                                marginRight: "20px",
                            }}/>
                        }
                        {loading && <Skeleton height="250px"/>}
                        {data.map((datum, i) => (
                            <ReviewItem
                                key={i}
                                name={datum.name}
                                date={datum.date}
                                content={datum.content}
                                image={datum.image}
                            />
                        ))}
                    </Carousel>
                </BoxFlexColumn>
            }
        </Container>
    );
}

export default ReviewsBlock;
import theme from "./theme";
import {t} from "i18next"

export const categories = {
    psychotherapy: t("Categories.psychotherapy"),
    meditation: t("Categories.meditation"),
    yoga: t("Categories.yoga"),
    bos: t("Categories.biofeedback"),
    physical: t("Categories.physical"),
    manual: t("Categories.manual"),
    acupuncture: t("Categories.acupuncture"),
    invasive: t("Categories.invasive"),
};

export const getTitleByCategory = (code: string) => {
    const valueMap = {
        psychotherapy: t("Categories.psychotherapy"),
        meditation: t("Categories.meditation"),
        yoga: t("Categories.yoga"),
        bos: t("Categories.biofeedback"),
        physical: t("Categories.physical"),
        manual: t("Categories.manual"),
        acupuncture: t("Categories.acupuncture"),
        invasive: t("Categories.invasive"),
    };

    return code in valueMap ? valueMap[code as keyof typeof valueMap] : '';
}

export const getLightColorByCategory = (category: keyof typeof categories) => theme.palette[category].light

export const getMainColorByCategory = (category: keyof typeof categories) => theme.palette[category].main

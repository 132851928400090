import React, {useEffect, useState} from "react";
import {Container, Skeleton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import CIconButton from "../inputs/CIconButton";
import ArrowSmallRight from "../icons/ArrowSmallRight";
import theme from "../../theme";
import CPaper from "../surfaces/CPaper";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import BoxFlex from "../layout/BoxFlex";
import {isDev, sleep} from "../../utils";
import routes from "../../routes";
import api from "../../api/api";
import {useTranslation} from "react-i18next";

const PainContainer = styled(BoxFlex)({
    gridGap: 12,
    justifyContent: "space-between",

    "@media (max-width: 899px)": {
        justifyContent: "start",
        flexWrap: "wrap",
        gridGap: 16,
    },
}) as typeof BoxFlex;

const PainItem = styled(BoxFlexColumn)({
    justifyContent: "space-between",
    height: 148,
}) as typeof BoxFlexColumn;

const StyledCPaper = styled(CPaper)({
    padding: "16px 20px",
    width: "100%",
    "@media (max-width: 899px)": {
        width: 152,
    },
}) as typeof CPaper;

const YourPainBlock = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{
        yourPainHead?: string,
        yourPainBack?: string,
        yourPainKnee?: string,
        yourPainJoints?: string,
        yourPainChest?: string,
        yourPainWomensPain?: string,
        yourPainMusclePain?: string,
        yourPainOncology?: string
    }>({})

    const titles = {
        yourPainHead: t("YourPainBlock.head"),
        yourPainBack: t("YourPainBlock.back"),
        yourPainKnee: t("YourPainBlock.knee"),
        yourPainJoints: t("YourPainBlock.joints"),
        yourPainChest: t("YourPainBlock.chest"),
        yourPainWomensPain: t("YourPainBlock.women's"),
        yourPainMusclePain: t("YourPainBlock.muscle"),
        yourPainOncology: t("YourPainBlock.oncology")
    };

    const fetchData = async () => {
        if (isDev) {
            await sleep(900)
            setData(require("../../mocks/articles__getYourPain.json"))
        } else {
            await api.get(`/articles/your-pain`)
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="xl">
            <Typography
                variant="h2"
                className="block-title"
            >
                {t("YourPainBlock.title")}
            </Typography>
            <PainContainer>
                {loading ? (
                    <Skeleton
                        variant="rounded"
                        width="100%"
                        height="148px"
                    />
                ) : (
                    <>
                        {Object.keys(data).map((datum: string, i) => (
                            <StyledCPaper
                                key={i}
                            >
                                <PainItem>
                                    <Typography
                                        variant="subtitle2"
                                    >
                                        {titles[datum as keyof typeof titles]}
                                    </Typography>
                                    <CIconButton
                                        sx={{
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                        href={routes.article.replace(":id", data[datum as keyof typeof data] ?? '')}
                                    >
                                        <ArrowSmallRight
                                            color={theme.palette.text.primary}
                                        />
                                    </CIconButton>
                                </PainItem>
                            </StyledCPaper>
                        ))}
                    </>
                )}
            </PainContainer>
        </Container>
    );
}

export default YourPainBlock;
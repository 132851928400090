import React from "react";
import {Button, Link, Typography} from "@mui/material";
import {styled} from "@mui/system";
import CAvatar from "../data-display/CAvatar";
import ArrowSmallRight from "../icons/ArrowSmallRight";
import theme from "../../theme";
import routes from "../../routes";
import CPaper from "./CPaper";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import BoxFlex from "../layout/BoxFlex";
import {priceFormatter} from "../../utils";

const Content = styled(BoxFlexColumn)({
    alignItems: "center",
    textAlign: "center",
    paddingTop: "18px",
}) as typeof BoxFlexColumn;

const SpecialistCard = (props: {
    id: number,
    avatar?: string,
    name: string,
    position: string,
    shortDescription?: string,
    price: number,
    alternatePriceText?: string,
}) => {
    return (
        <CPaper
            sx={{
                maxWidth: 420,
            }}
        >
            <Content>
                <CAvatar
                    src={props.avatar ?? undefined}
                    alt={props.name}
                    size="xl"
                />

                <Typography
                    variant="h4"
                    sx={{
                        margin: "52px 0 16px",
                    }}
                >
                    {props.name}
                </Typography>

                <Typography
                    variant="captionUppercase"
                    sx={{
                        marginBottom: "32px",
                    }}
                    color="primary"
                >
                    {props.position}
                </Typography>

                {props.shortDescription &&
                    <Typography
                        variant="body2"
                        sx={{
                            marginBottom: "36px",
                        }}
                        color="text.secondary"
                    >
                        {props.shortDescription}
                    </Typography>
                }

                <Button
                    variant="outlined"
                    component={Link}
                    href={routes.singleSpecialist.replace(':id', String(props.id))}
                    fullWidth
                >
                    <BoxFlex
                        sx={{
                            alignItems: "center",
                            gridGap: 8,
                        }}
                    >
                        {props.price ? priceFormatter(props.price) : props.alternatePriceText}

                        <ArrowSmallRight
                            color={theme.palette.text.primary}
                        />
                    </BoxFlex>
                </Button>
            </Content>
        </CPaper>
    );
}

export default SpecialistCard;
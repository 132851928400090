import React from 'react';
import {Navigate} from 'react-router-dom';
import routes from "./routes";

export default function OnlyNotAuthorizedRoute(props: {
    element: JSX.Element,
    authenticated: boolean
}) {
    if (props.authenticated) {
        return <Navigate to={routes.profile} />;
    }

    return props.element;
}
import React, {useState, createContext, useContext, useEffect} from 'react';
import {Snackbar, Alert, IconButton} from '@mui/material';
import Xmark from "../components/icons/Xmark";
import theme from "../theme";

interface IErrorContext {
    errorMessage: string;
    setError: (message: string) => void;
}

const ErrorContext = createContext<IErrorContext>({
    errorMessage: '',
    setError: () => {},
});

export const useError = () => {
    return useContext(ErrorContext);
};

interface ErrorHandlerProps {
    children: React.ReactNode;
}

export const ErrorHandler = ({children}: ErrorHandlerProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false)

    const handleError = (message: string) => {
        setErrorMessage(message);
    };

    useEffect(() => {
        if (errorMessage) {
            setOpen(true)
        }
    }, [errorMessage])

    useEffect(() => {
        if (!open) {
            setErrorMessage('')
        }
    }, [open])

    return (
        <ErrorContext.Provider value={{errorMessage, setError: handleError}}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setOpen(false)}
                        >
                            <Xmark
                                color={theme.palette.white.main}
                            />
                        </IconButton>

                    }
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </ErrorContext.Provider>
    );
};
import React, {useContext, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import TextField from '@mui/material/TextField';
import {Grid, Skeleton} from "@mui/material";
import CDatePicker from "./CDatePicker";
import {TimePicker} from "@mui/x-date-pickers";
import Clock from "../icons/Clock";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import {AuthContext} from "../../context/AuthProvider";
import {useNavigate} from "react-router-dom";
import routes from "../../routes";
import {useTranslation} from "react-i18next";

const AppointmentInputs = (props: {
    specialistId: number,
    onChangeDate: (date: Moment | null) => void,
    onChangeTime: (time: Moment | null) => void,
}) => {
    const { t } = useTranslation();
    const {isAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true)
    const [schedule, setSchedule] = useState<{ weekDay: number, startTime: string, endTime: string }[]>([])
    const [date, setDate] = useState<Moment | null>(null);
    const [minTime, setMinTime] = useState<Moment | undefined>(undefined);
    const [maxTime, setMaxTime] = useState<Moment | undefined>(undefined);
    const [workDays, setWorkDays] = useState<number[]>([])
    const [disabledDates, setDisabledDates] = useState<string[]>([])
    const [disabledTime, setDisabledTime] = useState<{ hour: number, minutes: { start: number, end: number } }[]>([])
    const [time, setTime] = useState<Moment | null>(null)
    const [openTimePicker, setOpenTimePicker] = useState(false)
    const handleOpenTimePicker = () => setOpenTimePicker(true);
    const handleCloseTimePicker = () => setOpenTimePicker(false);

    const fetchSchedule = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../../mocks/specialists__getSchedule.json")
            setSchedule(response.schedule)
            setDisabledDates(response.disabledDates)
        } else {
            await api.get(`/specialists/${props.specialistId}/schedule`)
                .then(res => {
                    if (res.status === 200) {
                        setSchedule(res.data.schedule)
                        setDisabledDates(res.data.disabledDates)
                    }
                })
        }
        setLoading(false)
    }

    const fetchScheduleDate = async () => {
        setLoading(true)
        if (isDev) {
            await sleep(500)
            setDisabledTime(require("../../mocks/specialists__getScheduleDate.json"))
        } else {
            if (date) {
                await api.get(`/specialists/${props.specialistId}/schedule/${date?.format('Y-MM-DD')}`)
                    .then(res => {
                        if (res.status === 200) {
                            setDisabledTime(res.data)
                        }
                    })
            }
        }

        setLoading(false)
    }

    useEffect(() => {
        setTime(null)

        if (date === null) {
            setMinTime(undefined)
            setMaxTime(undefined)
        } else {
            schedule.forEach(val => {
                if (val.weekDay === date.isoWeekday()) {
                    if (
                        date.format('Y-MM-DD') === moment().format('Y-MM-DD')
                        && moment(val.startTime, 'h:mm:ss').isBefore(moment())
                    ) {
                        setMinTime(moment())
                    } else {
                        setMinTime(moment(val.startTime, 'h:mm:ss'))
                    }
                    setMaxTime(moment(val.endTime, 'h:mm:ss'))
                }
            })
        }

        fetchScheduleDate()
        props.onChangeDate(date)
    }, [date]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setWorkDays(schedule.map(scheduleItem => scheduleItem.weekDay))
    }, [schedule])

    useEffect(() => {
        fetchSchedule()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (time && !isAuthenticated) {
            navigate(routes.login)
        }
        props.onChangeTime(time)
    }, [time]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
                md={6}
            >
                {loading ? (
                    <Skeleton height="4rem"/>
                ) : (
                    <CDatePicker
                        label={t("common.date")}
                        views={['day']}
                        onChange={setDate}
                        value={date}
                        shouldDisableDate={(day) => {
                            if (!workDays.includes(day.isoWeekday())) {
                                return true;
                            }
                            if (disabledDates.includes(day.format('YYYY-MM-DD'))) {
                                return true;
                            }

                            return false
                        }}
                        fullWidth
                        minDate={moment()}
                        maxDate={moment().add(3, "month")}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
            >
                {loading ? (
                    <Skeleton height="4rem"/>
                ) : (
                    <TimePicker
                        renderInput={(params) => {
                            return <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                                inputProps={{
                                    onKeyPress: (e) => e.preventDefault(),
                                    onClick: handleOpenTimePicker,
                                    ...params.inputProps,
                                }}
                            />
                        }}
                        components={{
                            OpenPickerIcon: Clock,
                        }}
                        label={t("common.time")}
                        value={time}
                        onChange={setTime}
                        open={openTimePicker}
                        onOpen={handleOpenTimePicker}
                        onClose={handleCloseTimePicker}
                        disabled={date === null}
                        shouldDisableTime={(timeValue, clockType) => {
                            if (clockType === "hours") {
                                for (let i = 0; i < disabledTime.length; i++) {
                                    if (
                                        disabledTime[i].hour === timeValue
                                        && disabledTime[i].minutes.start === 0
                                        && disabledTime[i].minutes.end === 59
                                    ) {
                                        return true;
                                    }
                                }
                            }
                            if (clockType === "minutes") {
                                for (let i = 0; i < disabledTime.length; i++) {
                                    if (disabledTime[i].hour === time?.hour()) {
                                        return timeValue >= disabledTime[i].minutes.start && timeValue <= disabledTime[i].minutes.end;
                                    }
                                }
                            }

                            return false;
                        }}
                        minTime={minTime}
                        maxTime={maxTime}
                        minutesStep={30}
                        onError={() => setTime(null)}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default AppointmentInputs;

import React, {useEffect, useState} from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import CAvatar from "../components/data-display/CAvatar";
import {Button, Container, Dialog, DialogContent, Grid, Skeleton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import BoxFlex from "../components/layout/BoxFlex";
import theme from "../theme";
import QuestionMarkCircle from "../components/icons/QuestionMarkCircle";
import Telegram from "../components/icons/Telegram";
import WhatsApp from "../components/icons/WhatsApp";
import Zoom from "../components/icons/Zoom";
import {domain, isDev, priceFormatter, sleep} from "../utils";
import DoctorOpinionDialog from "../components/dialogs/DoctorOpinionDialog";
import {Moment} from "moment/moment";
import api from "../api/api";
import {useNavigate, useParams} from "react-router-dom";
import {getFromStorage, hasInStorage, removeFromStorage, setInStorage} from "../localStorageUtils";
import {communicationMethods} from "../types/communicationMethod";
import AppointmentInputs from "../components/inputs/AppointmentInputs";
import { useError } from '../context/ErrorHandler';
import routes from "../routes";
import {useTranslation} from "react-i18next";

const TitleBlock = styled(BoxFlexColumn)({
    alignItems: "center",
    textAlign: "center",
    width: 360,
    gridGap: 35,
}) as typeof BoxFlexColumn

const InfoBlock = styled(BoxFlexColumn)(({theme}) => ({
    padding: "32px 0",
    gridGap: 16,
    borderBottom: `1px solid ${theme.palette.border.main}`,

    "&:last-of-type": {
        borderBottom: "none",
    }
})) as typeof BoxFlexColumn

const FormBlock = styled(BoxFlexColumn)(({theme}) => ({
    background: theme.palette.white.main,
    maxWidth: 530,
    borderRadius: 30,
})) as typeof BoxFlexColumn

const FormContent = styled(BoxFlexColumn)({
    padding: 32,
    gridGap: 24,

    "@media (max-width: 899px)": {
        paddingRight: 16,
        paddingLeft: 16,
    },
}) as typeof BoxFlexColumn

const FormContentBottom = styled(BoxFlex)({
    justifyContent: "space-between",
    alignItems: "center",
    gridGap: 20,

    "@media (max-width: 899px)": {
        flexDirection: "column",
        alignItems: "stretch",
        textAlign: "center",
    },
}) as typeof BoxFlex

const FormBottom = styled(BoxFlex)({
    justifyContent: "center",
    borderTop: `1px dashed ${theme.palette.border.main}`,
    padding: "20px 32px 28px 32px",

    "@media (max-width: 899px)": {
        padding: "20px 20px 28px 20px",
    },
}) as typeof BoxFlex

const CommunicationMethodsContainer = styled(BoxFlex)({
    gridGap: 11,
}) as typeof BoxFlex

const CommunicationMethodButton = styled(Button)({
    borderRadius: 15,
    flex: "1 1 0",
    width: 0,
    padding: 16,

    "@media (max-width: 899px)": {
        padding: "8px 8px 12px 8px",
    },
}) as typeof Button

const InfoBlockComponent = (props: {
    title: string,
    text: string,
}) => {
    return (
        <InfoBlock>
            <Typography
                variant="subtitle1"
            >
                {props.title}
            </Typography>
            <Typography
                variant="body1"
                color="text.secondary"
                dangerouslySetInnerHTML={{__html: props.text}}
            />
        </InfoBlock>
    )
}

const SpecialistPage = () => {
    const detailedCommunicationMethods: {
        [index: string]: {
            title: string,
            icon: React.ReactElement,
            value: number
        }
    } = {
        telegram: {
            title: "Telegram",
            icon: <Telegram/>,
            value: communicationMethods.telegram,
        },
        whatsapp: {
            title: "Whatsapp",
            icon: <WhatsApp/>,
            value: communicationMethods.whatsApp,
        },
        zoom: {
            title: "Zoom",
            icon: <Zoom/>,
            value: communicationMethods.zoom,
        },
    };
    const { t } = useTranslation();
    const {id} = useParams()
    const navigate = useNavigate()
    const { setError } = useError();
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(true)
    const [date, setDate] = useState<Moment | null>(null);
    const [time, setTime] = useState<Moment | null>(null)
    const [selectedCommunicationMethod, setSelectedCommunicationMethod] = useState<keyof typeof detailedCommunicationMethods>('telegram')
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [openDialogSuccessful, setOpenDialogSuccessful] = useState<boolean>(false)
    const [disabledBtn, setDisabledBtn] = useState<boolean>(true)
    const [showPopupBtn, setShowPopupBtn] = useState<boolean>(false)

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../mocks/specialists__getSingleData.json")
            setData(response)
        } else {
            await api.get(`/specialists/${id}`)
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        navigate('/oops')
                    }
                })
        }
        setLoading(false)
    }

    const handleClickBtn = () => {
        if (hasInStorage('specialist-popup-open2')) {
            handleMakeAppointment()
        } else {
            setShowPopupBtn(true)
            setOpenDialog(true)
            setInStorage('specialist-popup-open2', "1")
        }
    }

    const handleMakeAppointment = async () => {
        setOpenDialog(false)
        if (isDev) {
            await sleep(500)
        } else {
            await api.post(`/appointments`, {
                time: time?.format('HH:mm'),
                date: date?.format('Y-MM-DD'),
                specialistId: id,
                communicationMethod: detailedCommunicationMethods[selectedCommunicationMethod].value,
                returnUrl: `${domain}/specialists/${id}`,
            })
                .then(res => {
                    if (res.status === 200) {
                        if (res.status === 200) {
                            setInStorage('order' + res.data.orderId, String(id))
                            window.location.replace(res.data.formUrl)
                        } else {
                            setError(t("common.badRequestErr"))
                        }
                    }
                })
                .catch(err => setError(err.response.data.message))
        }
        setDisabledBtn(false)
        setShowPopupBtn(false)
    }

    const handleCheckOrder = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const orderId = urlParams.get('orderId');

        if (orderId) {
            const storageKey = 'order' + orderId;
            const res = getFromStorage(storageKey);

            if (orderId && res === String(id)) {
                setLoading(true)
                await api.post(`/order/${orderId}`)
                    .then(res => {
                        if (
                            res.status === 200
                            && res.data.ok === true
                        ) {
                            setOpenDialogSuccessful(true)
                        } else {
                            setError(t("common.badRequestErr"))
                        }
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => setLoading(false))
            }

            removeFromStorage(storageKey)
        }
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleChangeCommunicationMethod = (newValue: keyof typeof detailedCommunicationMethods): void => {
        setSelectedCommunicationMethod(newValue)
    }

    const handleCloseDialogSuccessful = () => {
        setOpenDialogSuccessful(false)
        navigate(routes.profile)
    }

    useEffect(() => {
        fetchData()
        handleCheckOrder()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDisabledBtn(date === null || !time)
    }, [date, time])

    return (
        <DefaultTemplate>
            <HeroBlock>
                <TitleBlock>
                    {loading ? (
                        <Skeleton
                            width="100%"
                            height="100px"
                        />
                    ) : (
                        <>
                            <CAvatar
                                size="xl"
                                src={data.avatar ?? undefined}
                            />

                            <Typography
                                variant="h2"
                            >
                                {data.name}
                            </Typography>
                            <Typography
                                variant="captionUppercase"
                            >
                                {data.position}
                            </Typography>
                        </>
                    )
                    }
                </TitleBlock>
            </HeroBlock>

            <Container
                maxWidth="xl"
            >
                <Grid
                    container
                    spacing={{
                        sx: 2,
                        md: 16,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        {loading ? (
                            <>
                                <Skeleton
                                    height="100px"
                                />
                                <Skeleton
                                    height="100px"
                                />
                                <Skeleton
                                    height="100px"
                                />
                            </>
                        ) : (
                            <>
                                {data.education &&
                                    <InfoBlockComponent
                                        title={t("CourseSpecialist.education")}
                                        text={data.education}
                                    />
                                }
                                {data.placeOfWork &&
                                    <InfoBlockComponent
                                        title={t("CourseSpecialist.placeOfWork")}
                                        text={data.placeOfWork}
                                    />
                                }
                                {data.specialization &&
                                    <InfoBlockComponent
                                        title={t("CourseSpecialist.specialization")}
                                        text={data.specialization}
                                    />
                                }
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <FormBlock>
                            <FormContent>
                                <Typography
                                    variant="h4"
                                >
                                    {t("common.makeAnAppointment")}
                                </Typography>

                                {loading ? (
                                    <Skeleton/>
                                ) : (
                                    <AppointmentInputs
                                        specialistId={Number(id)}
                                        onChangeDate={setDate}
                                        onChangeTime={setTime}
                                    />
                                )}
                                <BoxFlexColumn
                                    sx={{
                                        gridGap: 16,
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        {t("Specialists.communicationMethod")}
                                    </Typography>

                                    <CommunicationMethodsContainer>
                                        {Object.keys(detailedCommunicationMethods).map((method, i) => (
                                            <CommunicationMethodButton
                                                key={i}
                                                variant={selectedCommunicationMethod === method ? "contained" : "outlined"}
                                                onClick={() => handleChangeCommunicationMethod(method as keyof typeof detailedCommunicationMethods)}
                                            >
                                                <BoxFlexColumn
                                                    sx={{
                                                        width: "100%",
                                                        justifyContent: "start",
                                                        gridGap: 19,
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {detailedCommunicationMethods[method as keyof typeof detailedCommunicationMethods].icon}

                                                    <Typography
                                                        variant="body3"
                                                    >
                                                        {detailedCommunicationMethods[method as keyof typeof detailedCommunicationMethods].title}
                                                    </Typography>
                                                </BoxFlexColumn>
                                            </CommunicationMethodButton>
                                        ))}
                                    </CommunicationMethodsContainer>
                                </BoxFlexColumn>
                                <FormContentBottom>
                                    {loading
                                        ? (
                                            <Skeleton
                                                width="100%"
                                            />
                                        ) : (
                                            <>
                                                <Typography
                                                    variant="h4"
                                                >
                                                    {data.price ? priceFormatter(data.price) : data.alternatePriceText}
                                                </Typography>

                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    disabled={disabledBtn}
                                                    onClick={handleClickBtn}
                                                >
                                                    {t("common.pay")}
                                                </Button>
                                            </>
                                        )}
                                </FormContentBottom>
                            </FormContent>
                            <FormBottom>
                                <Button
                                    variant="text"
                                    endIcon={
                                        <QuestionMarkCircle
                                            color={theme.palette.text.tertiary}
                                        />
                                    }
                                    onClick={handleClickOpen}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color="text.tertiary"
                                    >
                                        {t("Specialists.doctorOpinionService")}
                                    </Typography>
                                </Button>
                            </FormBottom>
                        </FormBlock>
                    </Grid>
                </Grid>
            </Container>

            <DoctorOpinionDialog
                openExternal={openDialog}
                handleChangeOpenExternal={(open) => setOpenDialog(open)}
                btnCallback={showPopupBtn ? handleMakeAppointment : undefined}
            />
            <Dialog
                onClose={handleCloseDialogSuccessful}
                open={openDialogSuccessful}
            >
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("Specialists.successTitle")}
                    </Typography>
                    <br/>
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("Specialists.successMsg")}
                    </Typography>
                </DialogContent>
            </Dialog>
        </DefaultTemplate>
    );
};

export default SpecialistPage;

export default {
    home: "/",
    platform: "/platform",
    about: "/about",
    contacts: "/contacts",
    specialists: "/specialists",
    singleSpecialist: "/specialists/:id",
    article: "/article/:id",
    test: "/test",
    login: "/login",
    requestPasswordReset: "/request-password-reset",
    passwordReset: "/password-reset",
    profile: "/profile",
}
import React, {useEffect, useState} from "react";
import {Button, Container, Skeleton} from "@mui/material";
import BoxFlex from "../layout/BoxFlex";
import ArticleCard from "../surfaces/ArticleCard";
import routes from "../../routes";
import Filter from "../inputs/Filter";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const ArticlesBlock = () => {
    const { t } = useTranslation();
    const [init, setInit] = useState<boolean>(false)
    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])
    const [categories, setCategories] = useState([])
    const [loadingCategories, setLoadingCategories] = useState<boolean>(true)
    const [articles, setArticles] = useState<{ id: number, title: string, categories: { id: number, title: string }, ts: number, isPaid: boolean, previewImage?: string }[]>([])
    const [isExistsNextPage, setIsExistsNextPage] = useState<boolean>(false)
    const [loadingArticles, setLoadingArticles] = useState<boolean>(true)
    const [page, setPage] = useState<number>(1)
    const [disabledMoreBtn, setDisabledMoreBtn] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams();

    const fetchCategories = async () => {
        if (isDev) {
            await sleep(500)

            setCategories(require("../../mocks/articles__getCategories.json"))
        } else {
            await api.get(`/articles/categories`)
                .then(res => {
                    if (res.status === 200) {
                        setCategories(res.data)
                    }
                })
        }
        setLoadingCategories(false)
    }

    const fetchArticles = async (page: number) => {
        setDisabledMoreBtn(true)
        setLoadingArticles(true)
        if (isDev) {
            await sleep(500)

            const response = require("../../mocks/articles__getArticles.json")
            setArticles(response.articles)
            setIsExistsNextPage(response.isExistsNextPage)
        } else {
            await api.get(`/articles`, {
                page: page,
                categoryIds: selectedCategoryIds,
            })
                .then(res => {
                    if (res.status === 200) {
                        setArticles(prevState => [...prevState, ...res.data.articles])
                        setIsExistsNextPage(res.data.isExistsNextPage)
                    }
                })
        }
        setDisabledMoreBtn(false)
        setLoadingArticles(false)
    }

    const handleShowMore = async () => {
        setPage(prevState => {
            prevState++
            fetchArticles(prevState)
            return prevState
        })
    }

    const handleSelectCategories = (newCategory: number): void => {
        setSelectedCategoryIds(prevState => {
            return prevState.includes(newCategory)
                ? prevState.filter(i => i !== newCategory)
                : [...prevState, newCategory]
        })
    }

    useEffect(() => {
        fetchCategories()
        const categoryIds = searchParams.get("categoryIds");
        if (categoryIds) {
            setSelectedCategoryIds(categoryIds.split(",").map((id) => parseInt(id)));
        }
        setInit(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (init) {
            setSearchParams({ categoryIds: selectedCategoryIds.join(",") });
            setArticles([])
            setPage(1)
            fetchArticles(1)
        }
    }, [init, selectedCategoryIds]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container
            maxWidth="xl"
        >
            <Filter
                categoryTypes={categories}
                onClick={handleSelectCategories}
                activeCategoryIds={selectedCategoryIds}
                loading={loadingCategories}
            />

            <div
                className="articles-wrapper"
            >
                {loadingArticles ? (
                    <>
                        <Skeleton height="25rem"/>
                        <Skeleton height="25rem"/>
                        <Skeleton height="25rem"/>
                    </>
                ) : (
                  <>
                      {articles.map((val, i) => (
                          <ArticleCard
                              key={i}
                              img={articles[i].previewImage}
                              title={articles[i].title}
                              description={
                                  articles[i].categories
                                      ? Object.values(articles[i].categories).reduce((acc, curr) => {
                                      // @ts-ignore
                                      acc.push(curr.title)
                                      return acc
                                  }, []).join(', ')
                                      : ''
                              }
                              ts={articles[i].ts * 1000}
                              isPaid={articles[i].isPaid}
                              link={routes.article.replace(':id', String(articles[i].id))}
                          />
                      ))}
                  </>
                )}
            </div>

            {isExistsNextPage &&
                <BoxFlex
                    justifyContent="center"
                    mt="80px"
                >
                    <Button
                        variant="outlined"
                        size="large"
                        disabled={disabledMoreBtn}
                        onClick={handleShowMore}
                    >
                        {t("common.showMore")}
                    </Button>
                </BoxFlex>
            }
        </Container>
    );
}

export default ArticlesBlock;
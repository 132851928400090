import React, {useEffect, useState} from "react";
import {Container, Typography} from "@mui/material";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import ArticleCard from "../surfaces/ArticleCard";
import routes from "../../routes";
import {useParams} from "react-router-dom";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import {useTranslation} from "react-i18next";

const FeaturedArticlesBlock = () => {
    const { t } = useTranslation();
    const {id} = useParams()
    const [loading, setLoading] = useState<boolean>(true)
    const [articles, setArticles] = useState<{ id: number, title: string, categories: { id: number, title: string }, ts: number, isPaid: boolean, previewImage?: string }[]>([])

    const fetchArticles = async () => {
        setLoading(true)
        if (isDev) {
            await sleep(500)

            const response = require("../../mocks/articles__getArticles.json")
            setArticles(response.articles)
        } else {
            await api.get(`/articles/${id}/featured`)
                .then(res => {
                    if (res.status === 200) {
                        setArticles(res.data)
                    }
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchArticles()
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container
            maxWidth="xl"
        >
            {(articles.length > 0 && !loading) &&
                <>
                    <BoxFlexColumn
                        sx={{
                            gridGap: 50,
                        }}
                    >
                        <Typography
                            variant="h2"
                        >
                            {t("ArticlePage.recommendedArticles")}
                        </Typography>

                        <div
                            className="articles-wrapper"
                        >
                            {articles.map((val, i) => (
                                <ArticleCard
                                    key={i}
                                    img={articles[i].previewImage}
                                    title={articles[i].title}
                                    description={
                                        Object.values(articles[i].categories).reduce((acc, curr) => {
                                            // @ts-ignore
                                            acc.push(curr.title)
                                            return acc
                                        }, []).join(', ')
                                    }
                                    ts={articles[i].ts * 1000}
                                    isPaid={articles[i].isPaid}
                                    link={routes.article.replace(':id', String(articles[i].id))}
                                />
                            ))}
                        </div>
                    </BoxFlexColumn>
                </>
            }
        </Container>
    );
}

export default FeaturedArticlesBlock;
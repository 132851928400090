import React, {useEffect, useState} from "react";
import {Box, Container, Skeleton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import SpecialistCard from "../surfaces/SpecialistCard";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import {useTranslation} from "react-i18next";

const SpecialistsWrapper = styled(Box)({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 20,
    alignItems: "center",

    "@media (max-width: 899px)": {
        gridTemplateColumns: "1fr 1fr",
    },
    "@media(max-width: 599px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }
}) as typeof Box;

const SpecialistsByCategoryBlock = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{
        id: number,
        name: string,
        position: string,
        price: number,
        alternatePriceText?: string,
        shortDescription?: string,
        avatar?: string,
    }[]>([])

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../../mocks/specialists__getAll.json")
            setData(response)
        } else {
            await api.get(`/specialists`)
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container
            maxWidth="xl"
        >
            <BoxFlexColumn
                sx={{
                    gridGap: 50,
                }}
            >
                <Typography
                    variant="h2"
                >
                    {t("Specialists.leadingTitle")}
                </Typography>

                <SpecialistsWrapper>
                    {loading && <>
                        <Skeleton height="250px"/>
                        <Skeleton height="250px"/>
                        <Skeleton height="250px"/>
                    </>
                    }
                    {data.map((datum, i) => (
                        <SpecialistCard
                            key={i}
                            id={datum.id}
                            name={datum.name}
                            position={datum.position}
                            shortDescription={datum.shortDescription}
                            price={datum.price}
                            alternatePriceText={datum.alternatePriceText}
                            avatar={datum.avatar}
                        />
                    ))}
                </SpecialistsWrapper>
            </BoxFlexColumn>
        </Container>
    );
}

export default SpecialistsByCategoryBlock;
import React from "react";
import CCard, {ICCardProps} from "./CCard";
import {Link} from "@mui/material";
import Bolt from "../icons/Bolt";
import theme from "../../theme";

const ArticleCard = (props: ICCardProps & {
    link: string,
    isPaid: boolean,
}) => {
    const { link, isPaid, ...other } = props;
    const colors = [
        theme.palette.acupuncture.main,
        theme.palette.yoga.main,
    ];

    return (
        <Link href={props.link}>
            <CCard
                {...other}
                backgroundColor={isPaid ? colors[Math.floor(Math.random() * colors.length)] : undefined}
                icon={isPaid ? <Bolt color={theme.palette.white.main} /> : undefined}
                textWhite={isPaid}
            />
        </Link>
    );
}

export default ArticleCard;
import React, {useContext, useEffect, useState} from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import HeroBlock from "../components/blocks/HeroBlock";
import {Button, Container, Link, Skeleton, Typography} from "@mui/material";
import routes from "../routes";
import FAQBlock from "../components/blocks/FAQBlock";
import OurPartnersBlock from "../components/blocks/OurPartnersBlock";
import BoxFlexColumn from "../components/layout/BoxFlexColumn";
import SpecialistsCarouselBlock from "../components/blocks/SpecialistsCarouselBlock";
import {styled} from "@mui/system";
import BoxFlex from "../components/layout/BoxFlex";
import {isDev, sleep} from "../utils";
import api from "../api/api";
import {AuthContext} from "../context/AuthProvider";
import PosterAboutBlock from "../components/blocks/PosterAboutBlock";
import {Trans, useTranslation} from "react-i18next";

const TextBlock = styled(BoxFlex)(({theme}) => ({
    gridGap: 240,
    padding: "140px 0",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    "& > *": {
        width: "50%",
    },

    "@media (max-width: 899px)": {
        padding: "50px 0",
        gridGap: 20,
        flexDirection: "column",
        "& > *": {
            width: "100%",
        },
    },
})) as typeof BoxFlex

const AboutPage = () => {
    const {isAuthenticated} = useContext(AuthContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true)
    const [title, setTitle] = useState<string>("")
    const [text, setText] = useState<string>("")

    const fetchAboutInfo = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../mocks/about__getData.json")
            setTitle(response.title)
            setText(response.text)
        } else {
            await api.get('/about')
                .then(res => {
                    if (res.status === 200) {
                        setTitle(res.data.title)
                        setText(res.data.text)
                    }
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchAboutInfo()
    }, [])

    return (
        <DefaultTemplate>
            <HeroBlock
                title={t("AboutPage.title")}
            />

            <BoxFlexColumn
                sx={{
                    alignItems: "center",
                    margin: "0 auto",
                }}
            >
                <Typography
                    variant="h2"
                    align="center"
                >
                    <Trans i18nKey="AboutPage.subtitle" />
                </Typography>
                {!isAuthenticated &&
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{
                            mt: "48px",
                        }}
                        component={Link}
                        href={routes.test}
                    >
                        {t("common.takeTest")}
                    </Button>
                }
            </BoxFlexColumn>

            <Container
                maxWidth="xl"
            >
                <PosterAboutBlock />

                {loading ? (
                    <Skeleton height="250px"/>
                ) : (
                    <TextBlock>
                        <Typography
                            variant="h3"
                        >
                            <div dangerouslySetInnerHTML={{__html: title}}/>
                        </Typography>

                        <Typography
                            variant="body1"
                        >
                            <div dangerouslySetInnerHTML={{__html: text}}/>
                        </Typography>
                    </TextBlock>
                )}
            </Container>

            <SpecialistsCarouselBlock/>

            <FAQBlock/>

            <OurPartnersBlock/>

        </DefaultTemplate>
    )
};

export default AboutPage;

import React, {useEffect, useState} from "react";
import {Button, Container, Link, Skeleton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import CAvatar from "../data-display/CAvatar";
import BoxFlex from "../layout/BoxFlex";
import ArrowDownTray from "../icons/ArrowDownTray";
import RecordingCard from "../surfaces/RecordingCard";
import {dateFormatter, isDev, sleep} from "../../utils";
import InfoCard from "../surfaces/InfoCard";
import DoctorOpinionDialog from "../dialogs/DoctorOpinionDialog";
import {styled} from "@mui/system";
import api from "../../api/api";
import routes from "../../routes";
import {communicationMethods} from "../../types/communicationMethod";
import {useTranslation} from "react-i18next";

const RecordingMainContent = styled(BoxFlex)({
    gridGap: 130,

    "@media (max-width: 899px)": {
        flexDirection: "column",
        gridGap: 40,
    }
}) as typeof BoxFlex

const Content = styled(BoxFlexColumn)({
    gridGap: 100,

    "@media (max-width: 899px)": {
        gridGap: 50,
    }
}) as typeof BoxFlexColumn

const ArchiveBlock = (props: {
    data: {
        specialistId: number,
        specialistName: string,
        specialistPosition: string,
        specialistAvatar?: string,
        dateTs: number,
        meetingFile?: string,
    }[]
}) => {
    const { t } = useTranslation();
    return (
        <BoxFlexColumn
            sx={{
                gridGap: 50,
            }}
        >
            <Typography
                variant="h2"
            >
                {t("RecordingsBlock.archiveTitle")}
            </Typography>

            {
                props.data.length === 0 &&
                <Typography
                    variant="body2"
                >
                    {t("RecordingsBlock.archiveSubtitle")}
                </Typography>
            }
            <Table>
                <TableBody>
                    {props.data.map((datum, i) => (
                        <React.Fragment
                            key={i}
                        >
                            <TableRow
                                sx={{
                                    display: {
                                        xs: "none",
                                        md: "table-row",
                                    }
                                }}
                            >
                                <TableCell>
                                    <Link href={routes.singleSpecialist.replace(':id', String(datum.specialistId))}>
                                        <BoxFlex
                                            sx={{
                                                gridGap: 20,
                                                alignItems: "center",
                                            }}
                                        >
                                            <CAvatar
                                                alt={datum.specialistName}
                                                src={datum.specialistAvatar ?? undefined}
                                                size="sm"
                                            />
                                            <Typography
                                                variant="subtitle2"
                                                color="text.primary"
                                            >
                                                {datum.specialistName}
                                            </Typography>
                                        </BoxFlex>
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="body2"
                                    >
                                        {datum.specialistPosition}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {dateFormatter(datum.dateTs * 1000)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {datum.meetingFile &&
                                        <Button
                                            variant="outlined"
                                            endIcon={<ArrowDownTray/>}
                                            component={"a"}
                                            href={datum.meetingFile}
                                            target="_blank"
                                        >
                                            {t("RecordingsBlock.pdfBtn")}
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow
                                sx={{
                                    display: {
                                        xs: "table-row",
                                        md: "none",
                                    }
                                }}
                            >
                                <TableCell>
                                    <BoxFlexColumn
                                        sx={{
                                            gridGap: 20,
                                        }}
                                    >
                                        <Link href={routes.singleSpecialist.replace(':id', String(datum.specialistId))}>
                                            <BoxFlex
                                                sx={{
                                                    alignItems: "center",
                                                    gridGap: 13,
                                                }}
                                            >
                                                <CAvatar
                                                    alt={datum.specialistName}
                                                    src={datum.specialistAvatar ?? undefined}
                                                    size="md"
                                                />

                                                <BoxFlexColumn
                                                    sx={{
                                                        gridGap: 4,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="text.primary"
                                                    >
                                                        {datum.specialistName}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {datum.specialistPosition}
                                                    </Typography>
                                                </BoxFlexColumn>
                                            </BoxFlex>
                                        </Link>

                                        <BoxFlex
                                            sx={{
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {dateFormatter(datum.dateTs * 1000)}
                                            </Typography>

                                            {datum.meetingFile &&
                                                <Button
                                                    variant="outlined"
                                                    endIcon={<ArrowDownTray/>}
                                                    size="small"
                                                    component={"a"}
                                                    href={datum.meetingFile}
                                                    target="_blank"
                                                >
                                                    {t("RecordingsBlock.pdfBtn")}
                                                </Button>
                                            }
                                        </BoxFlex>
                                    </BoxFlexColumn>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </BoxFlexColumn>
    );
}

const RecordingsBlock = () => {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [actualData, setActualData] = useState<{
        id: number,
        dateTs: number,
        time: string,
        specialistId: number,
        specialistName: string,
        specialistPosition: string,
        specialistPrice: number,
        specialistAlternatePriceText?: string,
        communicationMethod: keyof typeof communicationMethods,
        isPossibleCancel: boolean,
        specialistPhone?: string,
        meetingLink?: string,
        specialistAvatar?: string,
        description?: string,
        paymentLink?: string,
    }[]>([])
    const [archiveData, setArchiveData] = useState<{
        specialistId: number,
        specialistName: string,
        specialistPosition: string,
        specialistAvatar?: string,
        dateTs: number,
        meetingFile?: string,
    }[]>([])

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)

            const response = require("../../mocks/appointments__getData.json")
            setActualData(response.actual)
            setArchiveData(response.archive)
        } else {

            await api.get(`/appointments`)
                .then(res => {
                    if (res.status === 200) {
                        setActualData(res.data.actual)
                        setArchiveData(res.data.archive)
                    }
                })
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const infoBlocksData = [
        {
            text: t("RecordingsBlock.intoBlockText1"),
            onClick: handleClickOpen,
        },
        {
            text: t("RecordingsBlock.intoBlockText2"),
            onClick: undefined,
        }
    ];

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <Content>
                    <BoxFlexColumn
                        sx={{
                            gridGap: 50,
                        }}
                    >
                        <Typography
                            variant="h2"
                        >
                            {t("RecordingsBlock.title")}
                        </Typography>

                        <RecordingMainContent>
                            {
                                loading
                                    ? <Skeleton width="100%"/>
                                    : <BoxFlexColumn
                                        sx={{
                                            gridGap: 20,
                                        }}
                                    >
                                        {actualData.length === 0 &&
                                            <Typography
                                                variant="body2"
                                            >
                                                {t("RecordingsBlock.noAppointmentsMsg")}
                                            </Typography>
                                        }
                                        {actualData.map((datum, i) => (
                                            <RecordingCard
                                                key={i}
                                                id={datum.id}
                                                dateTs={datum.dateTs}
                                                time={datum.time}
                                                specialistId={datum.specialistId}
                                                specialistName={datum.specialistName}
                                                specialistPosition={datum.specialistPosition}
                                                specialistPrice={datum.specialistPrice}
                                                specialistAlternatePriceText={datum.specialistAlternatePriceText}
                                                specialistPhone={datum.specialistPhone}
                                                communicationMethod={datum.communicationMethod}
                                                isPossibleCancel={datum.isPossibleCancel}
                                                meetingLink={datum.meetingLink}
                                                specialistAvatar={datum.specialistAvatar}
                                                description={datum.description}
                                                paymentLink={datum.paymentLink}
                                                fetchDataCb={fetchData}
                                            />
                                        ))}
                                    </BoxFlexColumn>
                            }
                            <BoxFlexColumn
                                sx={{
                                    gridGap: 20,
                                    order: {
                                        xs: -1,
                                        md: 0,
                                    }
                                }}
                            >
                                {infoBlocksData.map((datum, i) => (
                                    <InfoCard
                                        key={i}
                                        text={datum.text}
                                        onClick={datum.onClick}
                                    />
                                ))}
                            </BoxFlexColumn>
                        </RecordingMainContent>
                    </BoxFlexColumn>

                    {loading
                        ? <Skeleton
                            height="30rem"
                        />
                        : <ArchiveBlock
                            data={archiveData}
                        />

                    }
                </Content>
            </Container>
            <DoctorOpinionDialog
                openExternal={openDialog}
                handleChangeOpenExternal={(open) => setOpenDialog(open)}
            />
        </React.Fragment>
    );
}

export default RecordingsBlock;